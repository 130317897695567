import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import { packages, enablePackages } from "./../store/slices/packages";
import { Link } from "react-router-dom";
import Navbarside from "../component/Navbarside";
import Pagination from "@material-ui/lab/Pagination";
import Footer from "./footer";

function TravelPackage() {
  const [expertises, setexpertise] = useState([]);
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(packages())
      .unwrap()
      .then((data) => {
        setexpertise(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  async function enableSpecial(id) {
    let isSpecial = true;
    let item = { set: { isSpecial } };
    localStorage.setItem("item", JSON.stringify(item));
    localStorage.setItem("id", id);

    dispatch(enablePackages())
      .unwrap()
      .then(() => {
        // alert("Uploaded succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
    console.log(item);
    console.log(id);
  }

  async function disableSpecial(id) {
    let isSpecial = false;
    let item = { set: { isSpecial } };
    localStorage.setItem("item", JSON.stringify(item));
    localStorage.setItem("id", id);

    dispatch(enablePackages())
      .unwrap()
      .then(() => {
        // alert("Uploaded succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
    console.log(item);
    console.log(id);
  }

  async function enableApprove(id) {
    let isApproved = true;
    let item = { set: { isApproved } };
    localStorage.setItem("item", JSON.stringify(item));
    localStorage.setItem("id", id);

    dispatch(enablePackages())
      .unwrap()
      .then(() => {
        // alert("Uploaded succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
    console.log(item);
    console.log(id);
  }

  async function disableApprove(id) {
    let isApproved = false;
    let item = { set: { isApproved } };
    localStorage.setItem("item", JSON.stringify(item));
    localStorage.setItem("id", id);

    dispatch(enablePackages())
      .unwrap()
      .then(() => {
        // alert("Uploaded succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
    console.log(item);
    console.log(id);
  }

  const paginationChange = (event, value) => {
    console.log(value);
  };

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Travel Packages &nbsp; &nbsp;
                          <Link to="/UploadPackage" className="btn btn-primary">
                            Add Package
                          </Link>
                        </h4>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Package Name</th>
                              <th>Duration</th>
                              <th>Pricing</th>
                              <th>Is Approved</th>
                              <th>Is Travelonify Special</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          {loading ? (
                            <Loader active inline />
                          ) : (
                            <>
                              <tbody>
                                {expertises.map((categoryVal, index) => (
                                  <tr key={index}>
                                    <td>
                                      <Link
                                        to={`/PackageDetails/${
                                          categoryVal._id
                                            ? categoryVal._id
                                            : null
                                        }`}
                                      >
                                        {categoryVal.packageName}
                                      </Link>
                                    </td>
                                    <td>{categoryVal.duration}</td>
                                    <td>{categoryVal.twinShareRate / 2}</td>
                                    <td align="center">
                                      {categoryVal.isApproved === false && (
                                        <button
                                          className="btn btn-danger"
                                          style={{
                                            height: "25px",
                                            padding: "0 10px",
                                          }}
                                          onClick={() =>
                                            enableApprove(categoryVal._id)
                                          }
                                        >
                                          No
                                        </button>
                                      )}
                                      {categoryVal.isApproved === true && (
                                        <button
                                          className="btn btn-success"
                                          style={{
                                            height: "25px",
                                            padding: "0 10px",
                                          }}
                                          onClick={() =>
                                            disableApprove(categoryVal._id)
                                          }
                                        >
                                          Yes
                                        </button>
                                      )}
                                    </td>
                                    <td align="center">
                                      {categoryVal.isSpecial === false && (
                                        <button
                                          className="btn btn-danger"
                                          style={{
                                            height: "25px",
                                            padding: "0 10px",
                                          }}
                                          onClick={() =>
                                            enableSpecial(categoryVal._id)
                                          }
                                        >
                                          No
                                        </button>
                                      )}
                                      {categoryVal.isSpecial === true && (
                                        <button
                                          className="btn btn-danger"
                                          style={{
                                            height: "25px",
                                            padding: "0 10px",
                                          }}
                                          onClick={() =>
                                            disableSpecial(categoryVal._id)
                                          }
                                        >
                                          Yes
                                        </button>
                                      )}
                                    </td>

                                    <td>
                                      <Link
                                        className="btn btn-primary"
                                        style={{
                                          height: "25px",
                                          padding: "0 10px",
                                        }}
                                        to={`/Editpackage/${
                                          categoryVal._id
                                            ? categoryVal._id
                                            : null
                                        }`}
                                      >
                                        Edit
                                      </Link>
                                      &nbsp;
                                      <Link
                                        style={{
                                          height: "25px",
                                          padding: "0 10px",
                                        }}
                                        className="btn btn-success"
                                        to={`/Copypackage/${
                                          categoryVal._id
                                            ? categoryVal._id
                                            : null
                                        }`}
                                      >
                                        Copy
                                      </Link>
                                      {/* &nbsp;
                                      <Link
                                        style={{
                                          height: "25px",
                                          padding: "0 10px",
                                        }}
                                        className="btn btn-warning"
                                        to={`/AddRoomGroupTravel/${
                                          categoryVal._id
                                            ? categoryVal._id
                                            : null
                                        }`}
                                        // to="/AddRoomGroupTravel"
                                      >
                                        Add Room
                                      </Link> */}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TravelPackage;
