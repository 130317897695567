export const API_BASE_URL =
    process.env.API_BASE_URL || "https://api.healthonify.com/";
    
export const API_BASE_URL_WM =
process.env.API_BASE_URL || "https://api-wm.healthonify.com/";

export const API_BASE_URL_HC =
process.env.API_BASE_URL || "https://heal-hc.saketherp.in/";

export const API_PATHS = {
    //authentication
    login: "/login",
    register: "/register",
    verifyOtp: "/verifyOtp",
    getuser:"/get/user",
    putuser:"/put/user",
   

    //category
    getCategory: "/get/travelCategory",
    uploadCategory: "/post/travelCategory",
    uploadCity: "/post/city",
    editCategory: "/put/travelCategory",
 

    gethotels:"/get/hotel",
    updateHoteltax:"/update/taxDetails",
    posthotels:"/post/hotel",
    getRooms:"/get/roomGroup",
    getProperty:"/get/propertyType",
    postProperty:"/post/propertyType",
    approveHotel:"put/hotel",

    //packages
    uploadPackages: "/post/travelPackage",
    editPackages: "/put/travelPackage",
    uploadImage: API_BASE_URL + "/upload",
    getTravelPackage: "/get/travelPackage",
    multiUploadImage: API_BASE_URL + "/multiUpload",

    //booking
     travelBooking: "/get/travelBooking",

    //revenue
    travelRevenue: "/get/travelRevenue",

    //agency
    addAgency:"/post/Agency",
    getAgency:"/get/agency",
    putAgency:"/put/agency",
    approveAgency:"/admin/approveRequest",
    disableAgency:"/admin/agency/disable",
   
    //users
    travelAgents: "/get/user?roles=626b892e098cf1505394b05b",
    hotelAdmins:"/get/user?roles=62976f1f295cbb2a64456b3d",
     
    //hotel
    postbedType: "/post/bedType",
    getbedType: "/get/bedType",
    getTag: "/get/tag",
    postTag: "/post/tag",
    getAmenity: "/get/amenity",
    postAmenity: "/post/amenity",
    postroomType: "/post/roomType",
    getroomType: "/get/roomType",
    getroomCategory: "/get/roomCategory",
    postroomCategory:"/post/roomCategory",
    gethotelBooking:"/get/hotelBooking",
    addimage:"/post/roomImages",
    //city
    getCity: "/get/city",
    getstate:"/get/state",
    postCity:"/post/city",
    editCity:"/put/city",


    //theme
    getTheme: "get/travelTheme",
    postTheme: "post/travelTheme",
    editTheme: "put/travelTheme",


    //expert
    getClient:"/getClientList",
    getConsultation:"/get/consultation",
    putConsultationid:"/put/consultation?id=6230c1f39f30124474bfc38e",




    //roomgroup
    addroomgroup:"/store/roomGroup",


     //enquiry
     getEnquiry:"/get/enquiry",

    //  blog
    getblog:API_BASE_URL_WM + "/get/blog",
    postblog:API_BASE_URL_WM + "/post/blog",



    // Community
    getcommunity:'/user/getAllPosts',

    // forgot passwor
    postforgotpass:'/forgotPassword',

    // change password
    postupdatepassword:"/updatePassword",

    // Queitionnaire 
    postquestion:API_BASE_URL_HC + "/post/hraQuestions",
    getquestion:API_BASE_URL_HC + "/get/hraQuestions",
};
