import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import Navbarside from "../component/Navbarside";
import Footer from "./footer";

import { Revenuee } from "../store/slices/revenue";

function Revenue() {
  const [expertises, setexpertise] = useState([]);
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(Revenuee())
      .unwrap()
      .then((data) => {
        setexpertise(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      {/* <div className="card-title-wrap bar-success">
                        <h4 className="card-title">Zero configuration</h4>
                    </div> */}
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Agent Id</th>
                              <th>Booking Id</th>
                              <th>BookingCost</th>
                              <th>Payout</th>
                              <th>Service Tax</th>
                            </tr>
                          </thead>
                          {loading ? (
                            <Loader active inline />
                          ) : (
                            <>
                              <tbody>
                                {expertises.map((RevenueVal, index) => (
                                  <tr key={index}>
                                    <td>{RevenueVal.agentId}</td>
                                    <td>{RevenueVal.bookingId}</td>
                                    <td>{RevenueVal.bookingCost}</td>
                                    <td>{RevenueVal.payout}</td>
                                    <td>{RevenueVal.serviceTax}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default Revenue;
