import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import Navbarside from "../component/Navbarside";
import axios from "axios";
import { agentenquiry } from "./../store/slices/enquiry";
import { ExportToExcel } from "./ExportToExcel";
import Footer from "./footer";

import { API_PATHS } from "../utils/constants/api.constants";
import Moment from "moment";
function TravelAgentEnquiry() {
  const [expertises, setexpertise] = useState([]);
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const fileName = "AgencyEnquiry";

  React.useEffect(() => {
    const fetchData = () => {
      axios
        .get(API_PATHS.getEnquiry + "?enquiryFor=travelAgency")
        .then((postData) => {
          // reshaping the array
          const customHeadings = postData.data.data.map((item) => ({
            "Agent Name": item.name,
            "Agent Email": item.email,
            "Agent Mobile": item.contactNumber,
            "Agent Message": item.message,
            "Agency Name": item.agencyName,
            "Agency Address": item.address,
            "Date of Enquiry": Moment(item.created_at).format("DD-MM-YYYY"),
          }));

          setData(customHeadings);
        });
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    dispatch(agentenquiry())
      .unwrap()
      .then((data) => {
        setexpertise(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success">
                        <h4 className="card-title">
                          Travel Agency Enquiry &nbsp; &nbsp;
                          <ExportToExcel apiData={data} fileName={fileName} />
                        </h4>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Mobile</th>
                              <th>Message</th>
                              <th>Agency Name</th>
                              <th>Address</th>
                              <th>Date of Enquiry</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          {loading ? (
                            <Loader active inline />
                          ) : (
                            <>
                              <tbody>
                                {expertises.map(
                                  (TravelAgentEnquiryVal, index) => (
                                    <tr key={index}>
                                      <td>{TravelAgentEnquiryVal.name}</td>
                                      <td>{TravelAgentEnquiryVal.email}</td>
                                      <td>
                                        {TravelAgentEnquiryVal.contactNumber}
                                      </td>
                                      <td>{TravelAgentEnquiryVal.message}</td>
                                      <td>
                                        {TravelAgentEnquiryVal.agencyName}
                                      </td>
                                      <td>{TravelAgentEnquiryVal.address}</td>
                                      <td>
                                        {Moment(
                                          TravelAgentEnquiryVal.created_at
                                        ).format("DD-MM-YYYY")}
                                      </td>
                                      <td>{TravelAgentEnquiryVal.source}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
}
export default TravelAgentEnquiry;
