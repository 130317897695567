import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import AuthService from "../../services/auth.service";
import TravelAgency from './../../services/agency.service';

export const agency = createAsyncThunk(
    "agency/get/agency",
    async(thunkAPI) => {
        try {
            console.log("agency")
            const data = await TravelAgency.getAgency();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const updateAgencyName = createAsyncThunk(
    "auth/put/user",
    async(item,thunkAPI) => {
        try {
            const data = await TravelAgency.updateAgencyName(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);
export const agencyDetails = createAsyncThunk(
    "agency/get/agency",
    async(thunkAPI) => {
        try {
            console.log("agency")
            const data = await TravelAgency.getAgencyDetails();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const approveagency = createAsyncThunk(
    "agency/post/agency",
    async(item,thunkAPI) => {
        try {
            console.log("agency")
            const data = await TravelAgency.approveAgency(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const updateAgencyCharges = createAsyncThunk(
    "agency/put/agency",
    async(item,thunkAPI) => {
        try {
            console.log("agency")
            const data = await TravelAgency.updateAgencyCharges(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
    loading: false,
    error: "",
    user: AuthService.getUserDetails() || null,
    isLoggedIn: false,
};

const agencySlice = createSlice({
    name: "agency",
    initialState,
    extraReducers: {
        [agency.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [agency.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [agency.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },
        // [logout.fulfilled]: (state, action) => {
        //   state.isLoggedIn = false;
        //   state.user = null;
        // },
    },
});

const { reducer } = agencySlice;
export default reducer;