import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import Navbarside from "../component/Navbarside";
// import { UpdateAgency } from "./../store/slices/category";
import { agency } from "../store/slices/agency";
import { updateAgencyName } from "../store/slices/agency";
import Footer from "./footer";

function UpdateAgency(e) {
  const [allagency, setallAgency] = useState([]);
  const [agencyId, setagencyId] = useState("");
  const [agencyName, setagencyName] = useState("");
  const navigate = useNavigate("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(agency())
      .unwrap()
      .then((data) => {
        setallAgency(data.user.data);
        setagencyId(data.user.data[0]._id);
        setagencyName(data.user.data[0].name);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  async function upload(e) {
    e.preventDefault();
    let item = {
      agencyId,
    };
    dispatch(updateAgencyName(item))
      .unwrap()
      .then(() => {
        alert("Updated successfully");
        // navigate("/home");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }
  const handleagencyChange = (e) => {
    var value = allagency.filter(function (allagency) {
      return allagency.name == e.target.value;
    });
    setagencyId(value[0]._id);
    setagencyName(value[0].name);
  };

  const cancel = () => {
    navigate(-1);
  };

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Update Agency
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <form className="form" onSubmit={upload}>
                          <div className="row justify-content-md-center">
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Agency Name</label>
                                  <select
                                    className="form-control"
                                    onChange={handleagencyChange}
                                  >
                                    <option value="">Select</option>
                                    {allagency.map((option) => (
                                      <option value={option.agencyId}>
                                        {option.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          {loading ? (
                            <Loader active inline />
                          ) : (
                            <>
                              <div className="form-actions center">
                                <button type="submit" className="btn btn-blue">
                                  <i className="icon-note"></i> Save
                                </button>{" "}
                                &nbsp;
                                <button
                                  type="reset"
                                  className="btn btn-warning mr-1"
                                  onClick={cancel}
                                >
                                  <i className="icon-trash"></i> Cancel
                                </button>
                              </div>
                            </>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default UpdateAgency;
