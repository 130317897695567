import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class ConsultationService {
    static getConsultation(item) {
        let api = '';
    let user = JSON.parse(localStorage.getItem("user"));
    let id = localStorage.getItem("id");
    let role = user.data.roles[0];
    if(role === 'ROLE_SUPERADMIN'){
      api = id ? API_PATHS.getConsultation + '?_id=' + id : API_PATHS.getConsultation;
    }else if(role === 'ROLE_EXPERT'){
      api = id ? API_PATHS.getConsultation + '?_id=' + id + '&expertId=' + user.data.id: API_PATHS.getConsultation + '?expertId=' + user.data.id;
    }
    localStorage.removeItem("id");
      return axios
      .get(api)
      .then((response) => {
          if (response.data) {
              // localStorage.setItem("user", JSON.stringify(response.data));
          }
          return response.data;
      });
      }

      static addconsultation() {
        let api = '';
        // let user = JSON.parse(localStorage.getItem("user"));
        let id = localStorage.getItem("id");
        api = id ? API_PATHS.getConsultation + '?id=' + id : API_PATHS.getConsultation;
        localStorage.removeItem("id");
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }
    
      static putconsultation(item) {
        let api = '';
        // let user = JSON.parse(localStorage.getItem("user"));
        let id = localStorage.getItem("id");
        api = id ? API_PATHS.getConsultation + '?id=' + id : API_PATHS.getConsultation;
        localStorage.removeItem("id");
        return axios
            .put(api,item)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default ConsultationService;