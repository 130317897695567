import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class TravelAgency {
    static getAgency() {
        return axios
            .get(API_PATHS.getAgency)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static getAgencyDetails() {
        let api='';
        let id = localStorage.getItem("id");
        api = API_PATHS.getAgency + '?id=' + id;
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static updateAgencyName(item) {
        let api = '';
        // let user = JSON.parse(localStorage.getItem("user"));
        let id = localStorage.getItem("id");
        api = API_PATHS.putuser + '?id=' + id;
        localStorage.removeItem("id");
        return axios
            .put(api,item)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static approveAgency() {
        let api='';
        let item = JSON.parse(localStorage.getItem("item"));
        api = API_PATHS.approveAgency;
        return axios
          .post(api,item)
          .then((response) => {
            if (response.data) {
            
            }
            return response.data;
          });
      }
    
    
    static updateAgencyCharges(item) {
        let api='';
        let id = localStorage.getItem("id");
        console.log(item)
        api = API_PATHS.putAgency + '?id=' + id;
        return axios
          .put(api,item)
          .then((response) => {
            if (response.data) {
            
            }
            return response.data;
          });
      }
    
    

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}


export default TravelAgency;