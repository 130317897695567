import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import Navbarside from "../component/Navbarside";
import { Link } from "react-router-dom";
import { Amenitys } from "../store/slices/amenity";
import Moment from "moment";
import Footer from "./footer";

const Amenity = () => {
  const [expertises, setexpertise] = useState([]);
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(Amenitys())
      .unwrap()
      .then((data) => {
        setexpertise(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container pt-5">
              <section id="configuration">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title-wrap bar-success">
                          <h4 className="card-title">
                            Amenity &nbsp; &nbsp;
                            <Link
                              to="/UploadAmenity"
                              className="btn btn-primary"
                            >
                              <i className="fa fa-plus"></i> Add
                            </Link>
                          </h4>
                        </div>
                      </div>
                      <div className="card-body collapse show">
                        <div className="card-block card-dashboard table-responsive">
                          <table className="table table-striped table-bordered zero-configuration">
                            <thead>
                              <tr>
                                <th>Name</th>

                                <th>Created At</th>
                                <th>Updated At</th>
                              </tr>
                            </thead>

                            {loading ? (
                              <Loader active inline />
                            ) : (
                              <>
                                <tbody>
                                  {expertises.map((CityVal, index) => (
                                    <tr key={index}>
                                      <td>{CityVal.name}</td>

                                      <td>
                                        {Moment(CityVal.created_at).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </td>
                                      <td>
                                        {Moment(CityVal.updated_at).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Amenity;
