import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import Navbarside from "../component/Navbarside";
import { packages, enablePackages } from "./../store/slices/packages";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import Footer from "./footer";
import { userDetails } from "./../store/slices/auth";
function MyTravelPackage() {
  const [expertises, setexpertise] = useState([]);
  const [userData, setuserData] = useState("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  let user = JSON.parse(localStorage.getItem("user"));
  let id = user.data.id;
  React.useEffect(() => {
    dispatch(packages())
      .unwrap()
      .then((data) => {
        setexpertise(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(userDetails())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id === id) {
          setuserData(data.user.data[0].agencyId);
        }
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  const paginationChange = (event, value) => {
    console.log(value);
  };

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">
                        Travel Packages &nbsp; &nbsp;
                        {userData === "" && (
                          <Link to="/UpdateAgency" className="btn btn-primary">
                            <i className="fa fa-pencil"></i>Update Agancy
                            Details
                          </Link>
                        )}
                        {userData !== "" && (
                          <Link to="/UploadPackage" className="btn btn-primary">
                            <i className="fa fa-plus"></i> Add
                          </Link>
                        )}
                      </h4>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Package Name</th>
                              <th>Duration</th>
                              <th>Pricing</th>
                              <th>Is Approved</th>

                              <th>Action</th>
                            </tr>
                          </thead>
                          {loading ? (
                            <Loader active inline />
                          ) : (
                            <>
                              <tbody>
                                {expertises.map((categoryVal, index) => (
                                  <tr key={index}>
                                    <td>
                                      {" "}
                                      <Link
                                        to={`/PackageDetails/${
                                          categoryVal._id
                                            ? categoryVal._id
                                            : null
                                        }`}
                                      >
                                        {categoryVal.packageName}
                                      </Link>
                                    </td>
                                    <td>{categoryVal.duration}</td>
                                    <td>{categoryVal.pricePerPerson}</td>
                                    <td align="center">
                                      {categoryVal.isApproved === false && (
                                        <span className="btn btn-danger">
                                          No
                                        </span>
                                      )}
                                      {categoryVal.isApproved === true && (
                                        <span className="btn btn-danger">
                                          Yes
                                        </span>
                                      )}
                                    </td>

                                    <td>
                                      <Link
                                        to={`/Editpackage/${
                                          categoryVal._id
                                            ? categoryVal._id
                                            : null
                                        }`}
                                      >
                                        Edit
                                      </Link>{" "}
                                      &nbsp;
                                      <Link
                                        to={`/Copypackage/${
                                          categoryVal._id
                                            ? categoryVal._id
                                            : null
                                        }`}
                                      >
                                        Copy
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div style={{ display: "block", padding: 30, float: "right" }}>
            <Pagination count={10} onChange={paginationChange} />
          </div> */}
      <Footer />
    </body>
  );
}

export default MyTravelPackage;
