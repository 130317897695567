import React, { useState } from "react";
import Navbarside from "../component/Navbarside";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import { useNavigate, useParams } from "react-router-dom";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { packages } from "./../store/slices/packages";
import Footer from "./footer";

function PackageDetails() {
  const navigate = useNavigate("");
  const [city, setCity] = useState("");
  const [categoryName, setcategoryName] = useState("");
  const [packageName, setpackageName] = useState("");
  const [description, setDescription] = useState("");
  const [duration, setDuration] = useState("");
  const [ratings, setRatings] = useState("");
  const [departureLocation, setdepartureLocation] = useState("");
  const [inclusive, setInclusive] = useState("");
  const [exclusive, setExclusive] = useState("");
  const [termsCondition, settermsCondition] = useState("");
  const [importantNotes, setimportantNotes] = useState("");
  const [bookingProcedure, setbookingProcedure] = useState("");
  const [pricePerPerson, setpricePerPerson] = useState("");
  const [imageUrl, setimageUrl] = useState([
    {
      mediaLink: "",
    },
  ]);
  const [travelThemes, settravelThemes] = useState([
    {
      name: "",
    },
  ]);
  const [pricing, setPricing] = useState("");
  const [twinShareRate, settwinShareRate] = useState("");
  const [oddPersonPercent, setoddPersonPercent] = useState("");
  const [singlePaxRatePercent, setsinglePaxRatePercent] = useState("");
  const [pricePerChildrenPercent, setpricePerChildrenPercent] = useState("");
  const [minChildAge, setminChildAge] = useState("");
  const [tourIternary, settourIternary] = useState([
    { day: "", description: "", meal: "" },
  ]);
  const [hotelDescription, sethotelDescription] = useState([
    {
      destination: "",
      HotelName: "",
      address: "",
      duration: "",
    },
  ]);

  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { id } = useParams();
  localStorage.setItem("id", id);
  React.useEffect(() => {
    dispatch(packages())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id === id) {
          setcategoryName(data.user.data[0].categoryId.categoryName);
          setDuration(data.user.data[0].duration);
          setCity(data.user.data[0].city);
          setdepartureLocation(data.user.data[0].departureLocation);
          setInclusive(data.user.data[0].inclusive);
          setExclusive(data.user.data[0].exclusive);
          settermsCondition(data.user.data[0].termsCondition);
          settravelThemes(data.user.data[0].travelThemes);
          setimageUrl(data.user.data[0].imageUrl);
          setimportantNotes(data.user.data[0].importantNotes);
          setbookingProcedure(data.user.data[0].bookingProcedure);
          setpackageName(data.user.data[0].packageName);
          setDescription(data.user.data[0].description);
          setRatings(data.user.data[0].ratings);
          settwinShareRate(data.user.data[0].twinShareRate);
          setPricing(data.user.data[0].pricing);
          setoddPersonPercent(data.user.data[0].oddPersonPercent);
          setsinglePaxRatePercent(data.user.data[0].singlePaxRatePercent);
          setpricePerChildrenPercent(data.user.data[0].pricePerChildrenPercent);
          setminChildAge(data.user.data[0].minChildAge);
          settourIternary(data.user.data[0].tourIternary);
          sethotelDescription(data.user.data[0].hotelDescription);
        }
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Package Details
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <div className="row justify-content-md-center">
                          <div className="col-md-6">
                            <div className="form-group">
                              <p for="basicSelect">Category : {categoryName}</p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <p for="basicSelect">City : {city}</p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <p for="eventInput2">
                                Theme :{" "}
                                {travelThemes.map((theme, index) => (
                                  <p>{theme.name}</p>
                                ))}
                              </p>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <p for="eventInput2">
                                Package Name : {packageName}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <fieldset className="form-group">
                                <p for="placeTextarea">
                                  Description : {description}
                                </p>
                              </fieldset>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <p for="eventInput4">Duration: {duration} </p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <p for="eventInput5">Ratings : {ratings}</p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <p for="eventInput5">
                                Departure Location: {departureLocation}
                              </p>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <fieldset className="form-group">
                                <p for="placeTextarea">
                                  Inclusive: {inclusive}
                                </p>
                              </fieldset>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <fieldset className="form-group">
                                <p for="placeTextarea">
                                  Exclusive: {exclusive}
                                </p>
                              </fieldset>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <fieldset className="form-group">
                                <p for="placeTextarea">
                                  Terms & Conditions : {termsCondition}
                                </p>
                              </fieldset>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <fieldset className="form-group">
                                <p for="placeTextarea">
                                  Important Notes: {importantNotes}
                                </p>
                              </fieldset>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <fieldset className="form-group">
                                <p for="placeTextarea">
                                  Cancellation Policy: {bookingProcedure}
                                </p>
                              </fieldset>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <fieldset className="form-group">
                                <p for="placeTextarea">
                                  Actual Price: {pricing}
                                </p>
                              </fieldset>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <fieldset className="form-group">
                                <p for="placeTextarea">
                                  Price Twin Sharing: {twinShareRate}
                                </p>
                              </fieldset>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <fieldset className="form-group">
                                <p for="placeTextarea">
                                  Odd Person Percent: {oddPersonPercent}
                                </p>
                              </fieldset>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <fieldset className="form-group">
                                <p for="placeTextarea">
                                  Single Person Percent: {singlePaxRatePercent}
                                </p>
                              </fieldset>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <fieldset className="form-group">
                                <p for="placeTextarea">
                                  Price Per Child Percent:{" "}
                                  {pricePerChildrenPercent}
                                </p>
                              </fieldset>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <fieldset className="form-group">
                                <p for="placeTextarea">
                                  Min Child Age: {minChildAge}
                                </p>
                              </fieldset>
                            </div>
                          </div>
                        </div>
                        <br />
                        <h3>Tour Images</h3>
                        <br />
                        <div className="row justify-content-md-center">
                          <div className="col-md-12">
                            <div className="form-group">
                              <p for="eventInput5"> Image</p>
                              <div className="row">
                                {imageUrl.map((image, index) => (
                                  <div className="col-md-3">
                                    <img
                                      src={image.mediaLink}
                                      alt=""
                                      style={{
                                        height: "150px",
                                        width: "200px",
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6"></div>
                        </div>
                        <br />

                        <h3>TOUR ITERNARY</h3>
                        <br />

                        <table border="1">
                          <tr>
                            <th style={{ width: "100px" }}>Day</th>
                            <th style={{ width: "600px" }}>Iternary</th>
                            <th style={{ width: "200px" }}>Food Type</th>
                          </tr>

                          {tourIternary.map((itiernary, index) => (
                            <tr>
                              <td> {itiernary.day}</td>
                              <td> {itiernary.description}</td>
                              <td> {itiernary.meal}</td>
                            </tr>
                          ))}
                        </table>

                        <br />
                        <h2>Hotel Details</h2>
                        <br />

                        <table border="1">
                          <tr>
                            <th style={{ width: "100px" }}>Location</th>
                            <th style={{ width: "600px" }}>Hotel Name</th>
                            <th style={{ width: "200px" }}>No.of Days</th>
                            <th style={{ width: "200px" }}>
                              No.of Person Per Room
                            </th>
                          </tr>

                          {hotelDescription.map((hotel, index) => (
                            <tr>
                              <td>{hotel.destination}</td>
                              <td>{hotel.HotelName}</td>
                              <td>{hotel.Duration}</td>
                              <td>{hotel.capacityPerRoom}</td>
                            </tr>
                          ))}
                        </table>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default PackageDetails;
