import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FcSettings } from "react-icons/fc";
import { FaBuromobelexperte } from "react-icons/fa";
import { RiLogoutCircleRFill } from "react-icons/ri";
import { IoPersonCircle } from "react-icons/io5";
import { Link, useNavigate, NavLink } from "react-router-dom";
import logo from "../img/travelonify_logo.png";

const Navbarside = () => {
  const [userData, setuserData] = useState("");
  const dispatch = useDispatch();
  const open = () => {
    document.getElementById("side").classList.toggle("show");
  };

  const drop = () => {
    document.getElementById("usermenu").classList.toggle("showuser");
  };

  let data = JSON.parse(localStorage.getItem("user"));
  let role = data.data.roles[0];

  const navigate = useNavigate();

  function signOut(e) {
    localStorage.clear();
    navigate("/login");
  }

  const dashLinkitems = ({ isActive }) => {
    return {};
  };

  return (
    <div>
      <nav className="top_nav">
        <FcSettings className=" setting animate-rotate " onClick={open} />
        <IoPersonCircle className="user-icon" onClick={drop} />
      </nav>

      <nav className="sidebar" id="side">
        <NavLink to="/Home">
          <div className="d-flex justify-content-center logo-section">
            <img
              src={logo}
              alt="healthonify"
              style={{ height: "80px", width: "80px" }}
            />
          </div>
        </NavLink>
        <ul className="nav-lists pt-1">
          <li className="nav_items top_item">
            <NavLink to="/Home" className="menu-title" activeClassName="active">
              <i className="icon-home nav_icons "></i>
              Dashboard
            </NavLink>
          </li>

          {/* travel admin  menu start*/}
          {role === "ROLE_TRAVEL_ADMIN" && (
            <li className="nav_items">
              <NavLink to="/Travel" className="menu-title ">
                <i className="icon-plane nav_icons"></i>
                Travel Packages
              </NavLink>
            </li>
          )}
          {role === "ROLE_TRAVEL_ADMIN" && (
            <li className="nav_items">
              <NavLink to="/HotelList" className="menu-title ">
                <i className="icon-hourglass nav_icons "></i>
                Hotels
              </NavLink>
            </li>
          )}

          {role === "ROLE_TRAVEL_ADMIN" && (
            <li className="nav_items">
              <NavLink to="/TravelPackageEnquiry" className="menu-title ">
                <i className="icon-envelope-open  nav_icons "></i> Travel Packge
                Enquiry
              </NavLink>
            </li>
          )}
          {role === "ROLE_TRAVEL_ADMIN" && (
            <li className="nav_items">
              <NavLink to="/Blog" className="menu-title ">
                <i className="icon-feed nav_icons "></i>
                Blog
              </NavLink>
            </li>
          )}

          {/* travel admin  menu end*/}

          {/* travel agent menu start */}

          {role === "ROLE_TRAVELAGENT" && (
            <li className="nav_items">
              <NavLink to="/TravelPackages" className="menu-title ">
                <i className="icon-plane  nav_icons "></i>
                Travel Packages
              </NavLink>
            </li>
          )}

          {role === "ROLE_TRAVELAGENT" && (
            <li className="nav_items">
              <NavLink to="/TravelBooking" className="menu-title ">
                <i className="icon-bar-chart nav_icons "></i>
                Bookings
              </NavLink>
            </li>
          )}
          {role === "ROLE_TRAVELAGENT" && (
            <li className="nav_items">
              <NavLink to="/Revenue" className="menu-title ">
                <i className="icon-magnet nav_icons "></i>
                Revenue
              </NavLink>
            </li>
          )}
          {role === "ROLE_TRAVELAGENT" && (
            <li className="nav_items">
              <NavLink to="/Revenue" className="menu-title ">
                <i className="icon-user nav_icons "></i>
                Profile
              </NavLink>
            </li>
          )}
          {/* travel agent  menu end*/}

          {/*hotel admin menu starts here */}
          {role === "ROLE_HOTELADMIN" && (
            <li className="nav_items">
              <NavLink to="/RoomCategory" className="menu-title ">
                <i className="icon-home nav_icons "></i>
                Room Category
              </NavLink>
            </li>
          )}

          {role === "ROLE_HOTELADMIN" && (
            <li className="nav_items">
              <NavLink to="/Hotels" className="menu-title ">
                <i className="icon-user  nav_icons "></i>
                Hotel Details
              </NavLink>
            </li>
          )}
          {role === "ROLE_HOTELADMIN" && (
            <li className="nav_items">
              <NavLink to="/HotelBookings" className="menu-title ">
                <i className="icon-user  nav_icons "></i>
                Hotel Bookings
              </NavLink>
            </li>
          )}
          {role === "ROLE_HOTELADMIN" && (
            <li className="nav_items">
              <NavLink to="/Revenue" className="menu-title ">
                <i className="icon-user  nav_icons "></i>
                Revenue
              </NavLink>
            </li>
          )}
          <li className="nav_items">
            <Link to="/Login" onClick={signOut} className="menu-title ">
              <i className="icon-logout nav_icons "></i>
              Logout
            </Link>
          </li>
        </ul>
      </nav>
      <div className="drop-user" id="usermenu">
        <ul className="user-p">
          <li className="user-text">
            <NavLink to="/profile">
              <FaBuromobelexperte className="user-icon-style-mod" />
              My Profile
            </NavLink>
          </li>

          <li className="user-text">
            <Link to="/Login" onClick={signOut}>
              <RiLogoutCircleRFill className="user-icon-style-mod" />
              Logout
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbarside;
