import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CityService from "../../services/travelcity.service";
import AuthService from "../../services/auth.service";

export const Citys = createAsyncThunk(
    "travelCitys/get/city",
    async(thunkAPI) => {
        try {
            const data = await CityService.getCity();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
)


export const uploadCitys = createAsyncThunk(
    "travelCitys/post/city",
    async(item, thunkAPI) => {
        try {
            const data = await CityService.uploadCity(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) || 
                error.message ||  
                error.toString(); 
            return thunkAPI.rejectWithValue({ message });  
        }
    }
);


export const editCity = createAsyncThunk(
    "travelCitys/put/city",
    async(item, thunkAPI) => {
        try {
            const data = await CityService.editCity(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
  );

// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
    loading: false,
    error: "",
    user: AuthService.getUserDetails() || null,
    isLoggedIn: false,
};

const CitysSlice = createSlice({
    name: "citys",
    initialState,
    extraReducers: {
        [Citys.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [Citys.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [Citys.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },
        [uploadCitys.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [uploadCitys.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [uploadCitys.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },




        // [logout.fulfilled]: (state, action) => {
        //   state.isLoggedIn = false;
        //   state.user = null;
        // },
    },
});

const { reducer } = CitysSlice;
export default reducer;