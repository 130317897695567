import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class BlogService {
    static getBlog() {
       let api='';
        let id = localStorage.getItem("id"); 
        api = API_PATHS.getblog + '?expertiseId=' + id ;
        localStorage.removeItem("id");
        return axios
        .get( api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static getsingleBlog() {
        let api='';
         let id = localStorage.getItem("id"); 
         api = API_PATHS.getblog + '?_id=' + id ;

         localStorage.removeItem("id");
         return axios   
         .get( api)
             .then((response) => {
                 if (response.data) {
                     // localStorage.setItem("user", JSON.stringify(response.data));
                 }
                 return response.data;
             });
     }

     static postBlogs(item) {
        return axios
            .post(API_PATHS.postblog,
                item
            )
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default BlogService; 