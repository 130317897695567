import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
import { otpConfirmation } from "../store/slices/auth";
import { useDispatch } from "react-redux";
import logo from "../img/travelonify_logo.png";

function VerifyOtp() {
  const dispatch = useDispatch();
  const [otp, setotp] = useState("");
  const [mobileNo, setMobile] = useState("");
  const navigate = useNavigate("");

  React.useEffect(() => {
    const data = JSON.parse(localStorage.getItem("user"));
    setMobile(data.data[0].mobileNo);
  }, [mobileNo]);
  async function confirmOtp(e) {
    e.preventDefault();
    dispatch(otpConfirmation({ mobileNo, otp }))
      .unwrap()
      .then(() => {
        navigate("/Home");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }
  return (
    <div>
      <section className="login-bg-image">
        <div className="bg-overlay-orange" />

        <div className="card-section">
          <div className="d-flex justify-content-around align-items-center">
            <img src={logo} alt="travel-logo" className="div-logo" />

            <div className="h-logn-line" />

            <div className="login-form-2">
              {/*error && <Alert variant="danger">{error}</Alert>*/}
              <div className="d-flex justify-content-center">
                <img src={logo} alt="logo" className="logo-form-2" />
              </div>
              <h2
                className="text-center"
                style={{ color: "black", fontWeight: "500" }}
              >
                Verify OTP
              </h2>
              <br />
              <form onSubmit={confirmOtp}>
                <div className="form-group">
                  <div className="col-md-12">
                    <input
                      maxlength="5"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Enter OTP"
                      value={otp}
                      onChange={(e) => setotp(e.target.value)}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="text-center col-md-12">
                    <button
                      type="submit"
                      className="btn btn-warning px-4 py-2  white font-small-4 box-shadow-2 border-0"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default VerifyOtp;
