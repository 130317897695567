import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class BookingRevenue {
    static travelRevenue() {

        let api = '';
        let user = JSON.parse(localStorage.getItem("user"));
        let role = user.data.roles[0];
        if(role === 'ROLE_TRAVEL_ADMIN'){
          api = API_PATHS.travelRevenue;
        }else if(role === 'ROLE_TRAVELAGENT'){
          api = API_PATHS.travelRevenue + '?agentId=' + user.data.id;
        }
        return axios
          .get(api)
          .then((response) => {
            if (response.data) {
              // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
          });
      }

       

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default BookingRevenue;