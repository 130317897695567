import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addroomgroup } from "../store/slices/roomgroup";

import { Loader } from "semantic-ui-react";
import Footer from "./footer";
import Navbarside from "../component/Navbarside";
import { roomType } from "../store/slices/roomtype";
import { bedType } from "../store/slices/bedtype";
import { roomcategory } from "../store/slices/roomcategory";

function AddRoomGroup(e) {
  const { id } = useParams();
  const [hotelId] = useState(id);
  const [bedtypes, setbedTypes] = useState([]);
  const [roomtypes, setroomTypes] = useState([]);
  const [roomcategorys, setroomCategorys] = useState([]);
  const [roomCategoryId, setRoomCategoryId] = useState("");
  const [roomTypeId, setRoomTypeId] = useState("");
  const [roomTypeName, setRoomTypeName] = useState("");
  const [bedTypeName, setBedTypeName] = useState("");
  const [roomcategoryName, setroomcategoryName] = useState("");
  const [bedTypeId, setBedTypeId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [totalRoomCount, setTotalRoomCount] = useState("");
  const [twinShareRate, setPricePerRoom] = useState("");
  const [oddPersonPercent, setPersonPerPersonInRoom] = useState("");
  const [pricePerChildrenPercent, setPricePerChildPercent] = useState("");
  const [singlePaxRatePercent, setSingleOccupancePricePercent] = useState("");
  const [availableRoomCount, setDoubleOccupancyPricePercent] = useState("");
  const [tripleOccupancyPricePercent, setTripleOccupancyPricePercent] =
    useState("");

  const navigate = useNavigate("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const status = "true";

  React.useEffect(() => {
    dispatch(roomType())
      .unwrap()
      .then((data) => {
        setroomTypes(data.user.data);
        setRoomTypeId(data.user.data[0]._id);
        setRoomTypeName(data.user.data[0].name);
        // console.log("data", data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(bedType())
      .unwrap()
      .then((data) => {
        setbedTypes(data.user.data);
        setBedTypeId(data.user.data[0]._id);
        setBedTypeName(data.user.data[0].name);
        // console.log("data", data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(roomcategory())
      .unwrap()
      .then((data) => {
        setroomCategorys(data.user.data);
        setRoomCategoryId(data.user.data[0]._id);
        setroomcategoryName(data.user.data[0].name);
        // console.log("data", data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  async function upload(e) {
    e.preventDefault();
    let item = {
      hotelId,
      roomCategoryId,
      roomTypeId,
      bedTypeId,
      name,
      description,
      totalRoomCount,
      twinShareRate,
      oddPersonPercent,
      pricePerChildrenPercent,
      singlePaxRatePercent,
      availableRoomCount,
      tripleOccupancyPricePercent,
      status,
    };

    dispatch(addroomgroup(item))
      .unwrap()
      .then(() => {
        alert("Room Added successfully");
        navigate("/Hotels");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const handleroomTypeChange = (e) => {
    var value = roomtypes.filter(function (roomtypes) {
      return roomtypes.name == e.target.value;
    });
    setRoomTypeId(value[0]._id);
    setRoomTypeName(value[0].name);
  };

  const handlebedTypeChange = (e) => {
    var value = bedtypes.filter(function (bedtypes) {
      return bedtypes.name == e.target.value;
    });
    setBedTypeId(value[0]._id);
    setBedTypeName(value[0].name);
  };

  const handleroomcategoryChange = (e) => {
    var value = roomcategorys.filter(function (roomcategorys) {
      return roomcategorys.name == e.target.value;
    });
    setRoomCategoryId(value[0]._id);
    setroomcategoryName(value[0].name);
  };
  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Add Room
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <form className="form" onSubmit={upload}>
                          <div className="row ">
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea"> Room Type</label>
                                  <select
                                    className="form-control"
                                    onChange={handleroomTypeChange}
                                    required
                                  >
                                    <option value="">Select</option>
                                    {roomtypes.map((option) => (
                                      <option value={option.roomTypeId}>
                                        {option.name}
                                      </option>
                                    ))}
                                  </select>

                                  <div className="alert-message">{}</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    {" "}
                                    Room Category
                                  </label>
                                  <select
                                    className="form-control"
                                    onChange={handleroomcategoryChange}
                                    required
                                  >
                                    <option value="">Select</option>
                                    {roomcategorys.map((option) => (
                                      <option value={option.roomCategoryId}>
                                        {option.name}
                                      </option>
                                    ))}
                                  </select>

                                  <div className="alert-message">{}</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea"> Bed Type </label>
                                  <select
                                    className="form-control"
                                    onChange={handlebedTypeChange}
                                    required
                                  >
                                    <option value="">Select</option>
                                    {bedtypes.map((option) => (
                                      <option value={option.bedTypeId}>
                                        {option.name}
                                      </option>
                                    ))}
                                  </select>

                                  <div className="alert-message">{}</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea"> Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                  <div className="alert-message">{}</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    {" "}
                                    Description
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Description"
                                    value={description}
                                    onChange={(e) =>
                                      setDescription(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">{}</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    {" "}
                                    Total Room Count
                                  </label>
                                  <input
                                    type="number"
                                    min="1"
                                    className="form-control"
                                    Placeholder="Enter Total Room Count"
                                    value={totalRoomCount}
                                    onChange={(e) =>
                                      setTotalRoomCount(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">{}</div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    AVailable Rooms
                                  </label>
                                  <input
                                    type="number"
                                    min="1"
                                    className="form-control"
                                    Placeholder="Enter Double Occupancy Price Percent"
                                    value={availableRoomCount}
                                    onChange={(e) =>
                                      setDoubleOccupancyPricePercent(
                                        e.target.value
                                      )
                                    }
                                  />
                                  <div className="alert-message">{}</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    {" "}
                                    Twin Share Room Price
                                  </label>
                                  <input
                                    type="number"
                                    min="1"
                                    className="form-control"
                                    Placeholder="Enter Price Per-Room"
                                    value={twinShareRate}
                                    onChange={(e) =>
                                      setPricePerRoom(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">{}</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Odd Person Price Percentage
                                  </label>
                                  <input
                                    type="number"
                                    min="1"
                                    className="form-control"
                                    Placeholder="Enter Person Per-Person In Room"
                                    value={oddPersonPercent}
                                    onChange={(e) =>
                                      setPersonPerPersonInRoom(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">{}</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    {" "}
                                    Price Per-Child Percent
                                  </label>
                                  <input
                                    type="number"
                                    min="1"
                                    className="form-control"
                                    Placeholder="Enter Price Per-Child Percent"
                                    value={pricePerChildrenPercent}
                                    onChange={(e) =>
                                      setPricePerChildPercent(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">{}</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    {" "}
                                    single Occupancy Price Percent
                                  </label>
                                  <input
                                    type="number"
                                    min="1"
                                    className="form-control"
                                    Placeholder="Enter single Occupance Price Percent"
                                    value={singlePaxRatePercent}
                                    onChange={(e) =>
                                      setSingleOccupancePricePercent(
                                        e.target.value
                                      )
                                    }
                                  />
                                  <div className="alert-message">{}</div>
                                </div>
                              </div>
                            </div>

                            {/* <div className="col-md-6">
                                <div className="form-body">
                                  <div className="form-group">
                                    <label for="placeTextarea">Status</label>
                                    <input
                                      type="number"
                                      min="1"
                                      className="form-control"
                                      value={tripleOccupancyPricePercent}
                                      onChange={(e) =>
                                        setTripleOccupancyPricePercent(
                                          e.target.value
                                        )
                                      }
                                    />
                                    <div className="alert-message">{}</div>
                                  </div>
                                </div>
                              </div> */}
                          </div>
                          {loading ? (
                            <Loader active inline />
                          ) : (
                            <>
                              <div className="form-actions center">
                                <button type="submit" className="btn btn-blue">
                                  <i className="icon-note"></i> Save
                                </button>
                                &nbsp;
                                <button
                                  type="reset"
                                  className="btn btn-warning mr-1"
                                >
                                  <i className="icon-trash"></i> Cancel
                                </button>
                              </div>
                            </>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </body>
  );
}
export default AddRoomGroup;
