import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import Navbarside from "../component/Navbarside";
import { bedType } from "../store/slices/bedtype";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Footer from "./footer";

import { ellipsis } from "polished";

function BedType() {
  const MainContainer = styled.div`
    padding: 10px;
  `;

  const TitleText = styled.div`
    font-size: 18px;
  `;

  const DescriptionText = styled.div`
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
    ${({ showMore }) => showMore && ellipsis(undefined, 2)}
  `;

  const ShowMoreText = styled.div`
    cursor: pointer;
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: blue;
  `;
  const [expertises, setexpertise] = useState([]);
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate("");

  React.useEffect(() => {
    dispatch(bedType())
      .unwrap()
      .then((data) => {
        setexpertise(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  const [isShowMore, setIsShowMore] = useState(true);

  const toggleReadMore = () => setIsShowMore((show) => !show);

  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title-wrap bar-success">
                          <h4 className="card-title">
                            Bed Type &nbsp; &nbsp;
                            <Link
                              to="/UploadBedType"
                              className="btn btn-primary"
                            >
                              <i className="fa fa-plus"></i> Add
                            </Link>
                          </h4>
                        </div>
                      </div>
                      <div className="card-body collapse show">
                        <div className="card-block card-dashboard table-responsive">
                          <table className="table table-striped table-bordered zero-configuration">
                            <thead>
                              <tr>
                                <th>Bed Type</th>

                                <th>Image</th>
                              </tr>
                            </thead>
                            {loading ? (
                              <Loader active inline />
                            ) : (
                              <>
                                <tbody>
                                  {expertises.map((cityVal, index) => (
                                    <tr key={index}>
                                      <td>{cityVal.name}</td>

                                      <td>
                                        <img
                                          src={cityVal.imageUrl}
                                          alt=""
                                          style={{
                                            width: "150px",
                                            height: "100px",
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
}
export default BedType;
