import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Addtravels } from "../store/slices/agent";

import { Loader } from "semantic-ui-react";
import Footer from "./footer";
import Navbarside from "../component/Navbarside";

function Addtravel(e) {
  const [packageId, setPackageId] = useState("");
  const [roomCategoryId, setRoomCategoryId] = useState("");
  const [roomTypeId, setRoomTypeId] = useState("");
  const [bedTypeId, setBedTypeId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [totalRoomCount, setTotalRoomCount] = useState("");
  const [pricePerRoom, setPricePerRoom] = useState("");
  const [personPerPersonInRoom, setPersonPerPersonInRoom] = useState("");
  const [pricePerChildPercent, setPricePerChildPercent] = useState("");
  const [singleOccupancePricePercent, setSingleOccupancePricePercent] =
    useState("");
  const [doubleOccupancyPricePercent, setDoubleOccupancyPricePercent] =
    useState("");
  const [tripleOccupancyPricePercent, setTripleOccupancyPricePercent] =
    useState("");
  const [status, setStatus] = useState("");

  const navigate = useNavigate("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  async function upload(e) {
    let hotelId = JSON.parse(localStorage.getItem("user")).data.id;

    e.preventDefault();
    let item = {
      packageId,
      roomCategoryId,
      roomTypeId,
      bedTypeId,
      name,
      description,
      totalRoomCount,
      pricePerRoom,
      personPerPersonInRoom,
      pricePerChildPercent,
      singleOccupancePricePercent,
      doubleOccupancyPricePercent,
      tripleOccupancyPricePercent,
      status,
    };

    dispatch(Addtravels(item))
      .unwrap()
      .then(() => {
        alert("Uploaded successfully");
        navigate("/TravelAgents");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }
  return (
    <div>
      <Navbarside />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8 ">
            <div className="container-fluid pt-5">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Add travel
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <form className="form" onSubmit={upload}>
                          <div className="row ">
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea"> Package ID</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Package ID"
                                    value={packageId}
                                    onChange={(e) =>
                                      setPackageId(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">{}</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    {" "}
                                    Room Type Id
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Room Type Id"
                                    value={roomTypeId}
                                    onChange={(e) =>
                                      setRoomTypeId(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">{}</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    {" "}
                                    Room Category Id
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Room Category Id"
                                    value={roomCategoryId}
                                    onChange={(e) =>
                                      setRoomCategoryId(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">{}</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    {" "}
                                    Bed Type Id
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Bed Type Id"
                                    value={bedTypeId}
                                    onChange={(e) =>
                                      setBedTypeId(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">{}</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea"> Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                  <div className="alert-message">{}</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    {" "}
                                    Description
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Description"
                                    value={description}
                                    onChange={(e) =>
                                      setDescription(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">{}</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    {" "}
                                    Total Room Count
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Total Room Count"
                                    value={totalRoomCount}
                                    onChange={(e) =>
                                      setTotalRoomCount(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">{}</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    {" "}
                                    Price Per-Room
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Price Per-Room"
                                    value={pricePerRoom}
                                    onChange={(e) =>
                                      setPricePerRoom(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">{}</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    {" "}
                                    Person Per-Person In Room
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Person Per-Person In Room"
                                    value={personPerPersonInRoom}
                                    onChange={(e) =>
                                      setPersonPerPersonInRoom(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">{}</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    {" "}
                                    Price Per-Child Percent
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Price Per-Child Percent"
                                    value={pricePerChildPercent}
                                    onChange={(e) =>
                                      setPricePerChildPercent(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">{}</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    {" "}
                                    single Occupance Price Percent
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter single Occupance Price Percent"
                                    value={singleOccupancePricePercent}
                                    onChange={(e) =>
                                      setSingleOccupancePricePercent(
                                        e.target.value
                                      )
                                    }
                                  />
                                  <div className="alert-message">{}</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    {" "}
                                    Double Occupancy Price Percent
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Double Occupancy Price Percent"
                                    value={doubleOccupancyPricePercent}
                                    onChange={(e) =>
                                      setDoubleOccupancyPricePercent(
                                        e.target.value
                                      )
                                    }
                                  />
                                  <div className="alert-message">{}</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    {" "}
                                    Triple Occupancy Price Percent
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Triple Occupancy Price Percent"
                                    value={tripleOccupancyPricePercent}
                                    onChange={(e) =>
                                      setTripleOccupancyPricePercent(
                                        e.target.value
                                      )
                                    }
                                  />
                                  <div className="alert-message">{}</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea"> Status</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Status"
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                  />
                                  <div className="alert-message">{}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {loading ? (
                            <Loader active inline />
                          ) : (
                            <>
                              <div className="form-actions center">
                                <button type="submit" className="btn btn-blue">
                                  <i className="icon-note"></i> Save
                                </button>
                                &nbsp;
                                <button
                                  type="reset"
                                  className="btn btn-warning mr-1"
                                >
                                  <i className="icon-trash"></i> Cancel
                                </button>
                              </div>
                            </>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default Addtravel;
