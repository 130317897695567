import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Loader } from "semantic-ui-react";
import Footer from "./footer";
import Navbarside from "../component/Navbarside";
import { uploadhotels } from "../store/slices/Hotels";
import { Amenitys } from "../store/slices/amenity";
import { tag } from "../store/slices/tag";
import PropertyService from "../services/property.service";
import { citys, states } from "../store/slices/city";

function Addhotels(e) {
  const [allcity, setAllCity] = useState([]);
  const [city, setcityId] = useState("");
  const [cityName, setcityName] = useState("");
  const [allstate, setAllState] = useState([]);
  const [state, setstateId] = useState("");
  const [stateName, setstateName] = useState("");
  const [hotelamenity, setHotelAmenity] = useState([]);
  const [hoteltag, setHotelTag] = useState([]);
  const [name, setName] = useState("");
  const [rating, setRating] = useState("");
  const [travelonifyReviewScore, setTravelonifyReviewScore] = useState("");
  const [uniqueName, setUniqueName] = useState("");
  const [description, setDescription] = useState("");
  const [standardPrice, setStandardPrice] = useState("");
  const [availableRooms, setAvailableRooms] = useState("");

  const navigate = useNavigate("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState([]);
  const [tagchecked, setTagChecked] = useState([]);
  const [properties, setProperties] = useState([]);
  const [propertyId, setpropertyId] = useState("");
  const [buildingName, setbuildingName] = useState();
  const [street, setStreet] = useState("");
  const [zipCode, setZipcode] = useState("");

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  const handleTagCheck = (event) => {
    var updatedLists = [...tagchecked];
    if (event.target.tagchecked) {
      updatedLists = [...tagchecked, event.target.value];
    } else {
      updatedLists.splice(tagchecked.indexOf(event.target.value), 1);
    }
    setTagChecked(updatedLists);
  };

  React.useEffect(() => {
    dispatch(citys())
      .unwrap()
      .then((data) => {
        setAllCity(data.user.data);
        setcityId(data.user.data[0]._id);
        setcityName(data.user.data[0].name);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(states())
      .unwrap()
      .then((data) => {
        setAllState(data.user.data);
        setstateId(data.user.data[0]._id);
        setstateName(data.user.data[0].name);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  // alert messages Start
  const [alertname, setAlertname] = useState("");
  const [alertrating, setAlertrating] = useState("");
  const [alertscore, setAlertscore] = useState("");
  const [alerthname, setAlerthname] = useState("");
  const [alertdescription, setAlertdescription] = useState("");
  const [alertstandard, setAlertstandard] = useState("");
  const [alertavailable, setAlertavailable] = useState("");
  const [alertamenites, setAlertamenites] = useState("");
  const [alertags, setAlertags] = useState("");

  // to get amenity list

  React.useEffect(() => {
    dispatch(Amenitys())
      .unwrap()
      .then((data) => {
        setHotelAmenity(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  //to get tags

  React.useEffect(() => {
    dispatch(tag())
      .unwrap()
      .then((data) => {
        setHotelTag(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  //to get property type

  const getproperty = createAsyncThunk(
    "category/get/travelCategory",
    async (thunkAPI) => {
      try {
        const data = await PropertyService.getPropertys();
        return { user: data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  React.useEffect(() => {
    dispatch(getproperty())
      .unwrap()
      .then((data) => {
        setProperties(data.user.data);
        setpropertyId(data.user.data[0]._id);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  // const save = (e) => {
  //   const nameReg = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
  //   if (nameReg.test(name)) {
  //     setAlertname("");
  //   } else if (!nameReg.test(name) && name === "") {
  //     setAlertname("Enter your name");
  //     e.preventDefault("");
  //   } else {
  //     setAlertname(
  //       "Contain only upper case ,lower case, not symbols or numbers"
  //     );
  //     e.preventDefault("");
  //   }

  //   const ratingReg = /^(\+\d{1,3}[._:$!%-]?)?\d{2}$/;

  //   if (ratingReg.test(rating)) {
  //     setAlertrating("");
  //   } else if (!ratingReg.test(rating) && rating === "") {
  //     setAlertrating("Enter your rating's");
  //     e.preventDefault("");
  //   } else {
  //     setAlertrating("");
  //   }

  //   const scoreReg = /^(\+\d{1,3}[._:$!%-]?)?\d{2}$/;

  //   if (scoreReg.test(travelonifyReviewScore)) {
  //     setAlertscore("");
  //   } else if (
  //     !scoreReg.test(travelonifyReviewScore) &&
  //     travelonifyReviewScore === ""
  //   ) {
  //     setAlertscore("Enter your review score");
  //     e.preventDefault("");
  //   } else {
  //     setAlertscore("");
  //   }

  //   const uniqueReg = /^(([A-Za-z0-10]+[,.]?[ ]?|[a-z0-10]+['-]?)+)$/;
  //   if (uniqueReg.test(uniqueName)) {
  //     setAlerthname("");
  //   } else if (!uniqueReg.test(uniqueName) && uniqueName === "") {
  //     setAlerthname("Enter your hotel name");
  //     e.preventDefault("");
  //   } else {
  //     setAlerthname("");
  //   }

  //   const desReg = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
  //   if (desReg.test(description)) {
  //     setAlertdescription("");
  //   } else if (!desReg.test(description) && description === "") {
  //     setAlertdescription("Enter your description");
  //     e.preventDefault("");
  //   } else {
  //     setAlertdescription("");
  //   }

  //   const standardReg = /^(([0-10]+[,.]?[ ]?|[0-10]+['-]?)+)$/;
  //   if (standardReg.test(standardPrice)) {
  //     setAlertstandard("");
  //   } else if (!standardReg.test(standardPrice) && standardPrice === "") {
  //     setAlertstandard("Enter standard price");
  //     e.preventDefault("");
  //   } else {
  //     setAlertstandard("");
  //   }

  //   const availrooms = /^(([0-10]+[,.]?[ ]?|[0-10]+['-]?)+)$/;
  //   if (availrooms.test(availableRooms)) {
  //     setAlertavailable("");
  //   } else if (!availrooms.test(availableRooms) && availableRooms === "") {
  //     setAlertavailable("Enter available room count");
  //     e.preventDefault("");
  //   } else {
  //     setAlertavailable("");
  //   }
  // };
  let amenities = checked;
  let tags = tagchecked;
  async function upload(e) {
    let hotelAdminId = JSON.parse(localStorage.getItem("user")).data.id;
    e.preventDefault();
    let address = {
      buildingName,
      street,
      zipCode,
      city,
      state,
    };
    let billingAddress = {
      buildingName,
      street,
      zipCode,
      city,
      state,
    };
    let item = {
      hotelAdminId,
      name,
      rating,
      travelonifyReviewScore,
      uniqueName,
      description,
      standardPrice,
      availableRooms,
      amenities,
      tags,
      contact,
      nearbyPlaces,
      address,
      billingAddress,
    };

    dispatch(uploadhotels(item))
      .unwrap()
      .then(() => {
        alert("Uploaded successfully");
        // navigate("/Hotels");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const [nearbyPlaces, setNearbyPlaces] = useState([
    {
      name: "",
      description: "",
    },
  ]);

  const handleAddForms2 = () => {
    let place = [...nearbyPlaces];
    place.push({
      name: "",
      description: "",
    });
    setNearbyPlaces(place);
  };

  const handleDeleteForms2 = (e) => {
    if (nearbyPlaces.length > 1) {
      let place = [...nearbyPlaces];
      place.pop();
      setNearbyPlaces(place);
    }
  };
  const handleRowChange2 = (e, index) => {
    const place = [...nearbyPlaces];
    place[index][e.currentTarget.name] = e.currentTarget.value;
    setNearbyPlaces(place);
    console.log(nearbyPlaces);
  };

  const [contact, setContact] = useState([
    {
      name: "",
      info: "",
    },
  ]);

  const handleAddForms1 = () => {
    let con = [...contact];
    con.push({
      name: "",
      info: "",
    });
    setContact(con);
  };

  const handleDeleteForms1 = (e) => {
    if (contact.length > 1) {
      let con = [...contact];
      con.pop();
      setContact(con);
    }
  };
  const handleRowChange1 = (e, index) => {
    const con = [...contact];
    con[index][e.currentTarget.name] = e.currentTarget.value;
    setContact(con);
    console.log(contact);
  };

  const handlePropertyChange = (e) => {
    var value = properties.filter(function (property) {
      return property.propertyName == e.target.value;
    });
    setpropertyId(value[0]._id);
  };

  const handlecityChange = (e) => {
    var value = allcity.filter(function (allcity) {
      return allcity.name == e.target.value;
    });
    setcityId(value[0]._id);
    setcityName(value[0].name);
  };

  const handlestateChange = (e) => {
    var value = allstate.filter(function (allstate) {
      return allstate.name == e.target.value;
    });
    setstateId(value[0]._id);
    setstateName(value[0].name);
  };

  const cancel = () => {
    setAlertname("");
    setAlertrating("");
    setAlertscore("");
    setAlerthname("");
    setAlertdescription("");
    setAlertstandard("");
    setAlertavailable("");
    setAlertamenites("");
    setAlertags("");
    setName("");
    setRating("");
    setTravelonifyReviewScore("");
    setUniqueName("");
    setDescription("");
    setStandardPrice("");
    setAvailableRooms("");
  };

  return (
    <div>
      <Navbarside />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          List Hotel
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <form className="form" onSubmit={upload}>
                          <div className="row ">
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Hotel Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Hotel Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                  <div className="alert-message">
                                    {alertname}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Hotel Unique Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Hotel Unique Name"
                                    value={uniqueName}
                                    onChange={(e) =>
                                      setUniqueName(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">
                                    {alerthname}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Description</label>
                                  <textarea
                                    className="form-control"
                                    Placeholder="Enter Description"
                                    value={description}
                                    onChange={(e) =>
                                      setDescription(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">
                                    {alertdescription}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Rating</label>
                                  <input
                                    type="number"
                                    min="1"
                                    className="form-control"
                                    Placeholder="Enter Rating's"
                                    value={rating}
                                    onChange={(e) => setRating(e.target.value)}
                                  />
                                  <div className="alert-message">
                                    {alertrating}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Review Score
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter  Review Score"
                                    value={travelonifyReviewScore}
                                    onChange={(e) =>
                                      setTravelonifyReviewScore(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">
                                    {alertscore}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Standard Price
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Standerd Price"
                                    value={standardPrice}
                                    onChange={(e) =>
                                      setStandardPrice(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">
                                    {alertstandard}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Available Rooms
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Available Rooms"
                                    value={availableRooms}
                                    onChange={(e) =>
                                      setAvailableRooms(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">
                                    {alertavailable}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Amenities</label>
                                  <div className="row">
                                    {hotelamenity.map((amenityVal, index) => (
                                      <div className="col-md-2">
                                        <input
                                          type="checkbox"
                                          name="options"
                                          value={amenityVal._id}
                                          onChange={handleCheck}
                                        />
                                        &nbsp;
                                        {amenityVal.name}
                                      </div>
                                    ))}
                                  </div>
                                  <div className="alert-message">
                                    {alertamenites}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Tags</label>
                                  <div className="row">
                                    {hoteltag.map((tagVal, index) => (
                                      <div className="col-md-3">
                                        <input
                                          type="checkbox"
                                          name="options"
                                          value={tagVal._id}
                                          onChange={handleTagCheck}
                                        />
                                        &nbsp;
                                        {tagVal.name}
                                      </div>
                                    ))}
                                  </div>
                                  <div className="alert-message">
                                    {alertamenites}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <h5>Contact Information</h5>
                              <br />
                              <div className="row">
                                <div className="col-md-12" align="center">
                                  <button
                                    type="button"
                                    className="btn btn-blue"
                                    onClick={(e) => handleAddForms1(e)}
                                  >
                                    <i className="fa fa-plus-circle" />
                                  </button>
                                  &nbsp; &nbsp;
                                  <button
                                    type="button"
                                    className="btn btn-warning"
                                    onClick={(e) => handleDeleteForms1(e)}
                                  >
                                    <i className="fa fa-minus-circle" />
                                  </button>
                                </div>
                              </div>
                              <br />
                              {contact.map((contactfor, index) => (
                                <div key={index} className="form-group row">
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <input
                                      onChange={(e) =>
                                        handleRowChange1(e, index)
                                      }
                                      className="form-control"
                                      placeholder="Name"
                                      name="name"
                                      type="text"
                                      value={contactfor.name}
                                    />
                                  </div>

                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <input
                                      onChange={(e) =>
                                        handleRowChange1(e, index)
                                      }
                                      className="form-control"
                                      placeholder="Mobile Number"
                                      name="info"
                                      type="text"
                                      value={contactfor.info}
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className="col-lg-12">
                              <h5>Address</h5>

                              <div className="form-group row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                  <input
                                    onChange={(e) =>
                                      setbuildingName(e.target.value)
                                    }
                                    className="form-control"
                                    placeholder="Address"
                                    name="name"
                                    type="text"
                                    // value={contactfor.name}
                                  />
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                  <input
                                    onChange={(e) => setStreet(e.target.value)}
                                    className="form-control"
                                    placeholder="Street"
                                    name="info"
                                    type="text"
                                    // value={contactfor.info}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                  <input
                                    onChange={(e) => setZipcode(e.target.value)}
                                    className="form-control"
                                    placeholder="Pincode"
                                    name="info"
                                    type="text"
                                    // value={contactfor.info}
                                  />
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-12">
                                  <select
                                    className="form-control"
                                    onChange={handlecityChange}
                                  >
                                    <option value="">Select City</option>
                                    {allcity.map((option) => (
                                      <option value={option.cityId}>
                                        {option.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                  <select
                                    className="form-control"
                                    onChange={handlestateChange}
                                  >
                                    <option value="">Select State</option>
                                    {allstate.map((option) => (
                                      <option value={option.stateId}>
                                        {option.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <br />
                            <div className="col-lg-12 mt-5">
                              <h5>Near By Places</h5>
                              <br />
                              <div className="row">
                                <div className="col-md-12" align="center">
                                  <button
                                    type="button"
                                    className="btn btn-blue"
                                    onClick={(e) => handleAddForms2(e)}
                                  >
                                    <i className="fa fa-plus-circle" />
                                  </button>
                                  &nbsp; &nbsp;
                                  <button
                                    type="button"
                                    className="btn btn-warning"
                                    onClick={(e) => handleDeleteForms2(e)}
                                  >
                                    <i className="fa fa-minus-circle" />
                                  </button>
                                </div>
                              </div>
                              <br />
                              {nearbyPlaces.map((nearby, index) => (
                                <div key={index} className="form-group row">
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <input
                                      onChange={(e) =>
                                        handleRowChange2(e, index)
                                      }
                                      className="form-control"
                                      placeholder="Enter Place Name"
                                      name="name"
                                      type="text"
                                      value={nearby.name}
                                    />
                                  </div>

                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <input
                                      onChange={(e) =>
                                        handleRowChange2(e, index)
                                      }
                                      className="form-control"
                                      placeholder="Description"
                                      name="destination"
                                      type="text"
                                      value={nearby.destination}
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="basicSelect">
                                  Select Property Type
                                </label>
                                <select
                                  className="form-control"
                                  onChange={handlePropertyChange}
                                >
                                  <option value="">Select</option>
                                  {properties.map((option) => (
                                    <option value={option.propertyId}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                          {loading ? (
                            <Loader active inline />
                          ) : (
                            <div>
                              <div className="form-actions center">
                                <button
                                  type="submit"
                                  className="btn btn-blue"
                                  // onClick={save}
                                >
                                  <i className="icon-note"></i> Save
                                </button>
                                &nbsp;
                                <button
                                  type="reset"
                                  className="btn btn-warning mr-1"
                                  onClick={cancel}
                                >
                                  <i className="icon-trash"></i> Cancel
                                </button>
                              </div>
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default Addhotels;
