import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class HotelService {
    static getHotels() {
      
        let api = '';
        let user = JSON.parse(localStorage.getItem("user"));
        let id = localStorage.getItem("id");
        let role = user.data.roles[0];
        if(role === 'ROLE_TRAVEL_ADMIN'){
          api = id ? API_PATHS.gethotels + '?_id=' + id : API_PATHS.gethotels;
        }else if(role === 'ROLE_HOTELADMIN'){
          api = id ? API_PATHS.gethotels + '?_id=' + id + '&hotelAdminId=' + user.data.id: API_PATHS.gethotels + '?hotelAdminId=' + user.data.id;
        }
        localStorage.removeItem("id");
        return axios
        .get(api)
        .then((response) => {
          if (response.data) {
         
          }
          return response.data;
        });
    }
  
    
  static enableHotel() {
    let api='';
    let item = JSON.parse(localStorage.getItem("item"));
    let id = localStorage.getItem("id");
    console.log(item)
    api = API_PATHS.approveHotel + '?id=' + id;
    return axios
      .put(api,item)
      .then((response) => {
        if (response.data) {
        
        }
        return response.data;
      });
  }
    static uploadHotels(item) {
        return axios
            .post(API_PATHS.posthotels,
                item
            )
            .then((response) => {
                if (response.data) {
                     // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static uploadHotelImage(item) {
      return axios
          .post(API_PATHS.addimage,
              item
          )
          .then((response) => {
              if (response.data) {
                   // localStorage.setItem("user", JSON.stringify(response.data));
              }
              return response.data;
          });
  }

      
    static updateHotelCharges(item) {
        let api='';
        api = API_PATHS.updateHoteltax;
        return axios
          .put(api,item)
          .then((response) => {
            if (response.data) {
            
            }
            return response.data;
          });
      }
    
    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default HotelService;