import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ThemeService from "../../services/theme.service";
import AuthService from "../../services/auth.service";

export const themes = createAsyncThunk(
  "city/get/travelTheme",
  async ( thunkAPI) => {
    try {
      const data = await ThemeService.getTheme();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
export const uploadthemes = createAsyncThunk(
    "themes/post/travelTheme",
    async(item, thunkAPI) => {
        try {
            const data = await ThemeService.uploadTheme(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);
export const editthemes = createAsyncThunk(
  "themes/put/editTheme",
  async(item, thunkAPI) => {
      try {
          const data = await ThemeService.editTheme(item);
          return { user: data };
      } catch (error) {
          const message =
              (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString();
          return thunkAPI.rejectWithValue({ message });
      }
  }
);

export const enablethemes = createAsyncThunk(
  "themes/put/editTheme",
  async(item, thunkAPI) => {
      try {
          const data = await ThemeService.enableTheme(item);
          return { user: data };
      } catch (error) {
          const message =
              (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString();
          return thunkAPI.rejectWithValue({ message });
      }
  }
);
// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
  loading: false,
  error: "",
  user: AuthService.getUserDetails() || null,
  isLoggedIn: false,
};

const themesSlice = createSlice({
  name: "themes",
  initialState,
  extraReducers: {
    [themes.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [themes.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [themes.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },
    [uploadthemes.pending]: (state) => {
        state.loading = true;
        state.error = "";
        state.isLoggedIn = false;
        state.user = null;
      },
      [uploadthemes.fulfilled]: (state, action) => {
        state.loading = false;
        state.error = "";
        state.isLoggedIn = true;
        state.user = action.payload.user;
      },
      [uploadthemes.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
        state.isLoggedIn = false;
        state.user = null;
      },
      [editthemes.pending]: (state) => {
        state.loading = true;
        state.error = "";
        state.isLoggedIn = false;
        state.user = null;
      },
      [editthemes.fulfilled]: (state, action) => {
        state.loading = false;
        state.error = "";
        state.isLoggedIn = true;
        state.user = action.payload.user;
      },
      [editthemes.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
        state.isLoggedIn = false;
        state.user = null;
      },
    // [logout.fulfilled]: (state, action) => {
    //   state.isLoggedIn = false;
    //   state.user = null;
    // },
  },
});

const { reducer } = themesSlice;
export default reducer;