import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "./footer";
import Navbarside from "../component/Navbarside";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import { Clients } from "./../store/slices/client";

const MyClients = () => {
  const [client, setClient] = useState([]);
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  let expertId = JSON.parse(localStorage.getItem("user")).data.id;
  let flow = "consultation";

  React.useEffect(() => {
    let item = {
      expertId,
      flow,
    };
    localStorage.setItem("item", JSON.stringify(item));
    dispatch(Clients())
      .unwrap()
      .then((data) => {
        setClient(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  });

  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title-wrap bar-success">
                          <h4 className="card-title">
                            My Clients &nbsp; &nbsp;
                            {/* <Link
                                  to="/UploadCategory"
                                  className="btn btn-primary"
                                >
                                  <i className="fa fa-plus"></i> Add
                                </Link> */}
                          </h4>
                        </div>
                      </div>
                      <div className="card-body collapse show">
                        <div className="card-block card-dashboard table-responsive">
                          <table className="table table-striped table-bordered zero-configuration">
                            <thead>
                              <tr>
                                <th>Client Name</th>
                                <th>City/ State</th>
                                <th>Country</th>
                                <th>Image</th>
                              </tr>
                            </thead>
                            {loading ? (
                              <Loader active inline />
                            ) : (
                              <>
                                <tbody>
                                  {client.map((categoryVal, index) => (
                                    <tr key={index}>
                                      <td>
                                        {categoryVal.firstName}{" "}
                                        {categoryVal.lastName}
                                      </td>
                                      <td>
                                        {categoryVal.city}/{categoryVal.state}
                                      </td>
                                      <td>{categoryVal.country}</td>
                                      <td>
                                        <img
                                          src={categoryVal.imageUrl}
                                          alt=""
                                          style={{
                                            width: "120px",
                                            height: "80px",
                                          }}
                                        />{" "}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </body>
    </div>
  );
};

export default MyClients;
