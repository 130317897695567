import React from "react";
import Background from "./../img/sidebar-bg/01.jpg";
import logo from "./../img/logo.png";
import Navbarside from "../component/Navbarside";
import Footer from "./footer";

const Profile = () => {
  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <div className="card mb-4">
                    <div className="card-header">
                      <div className="card-title-wrap bar-primary">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Personal Information
                        </h4>
                      </div>
                    </div>

                    <div className="card-body">
                      <div className="card-block">
                        <div className="align-self-center halfway-fab text-center">
                          <a className="profile-image">
                            <img
                              src="../app-assets/img/portrait/medium/avatar-m-9.jpg"
                              className="rounded-circle img-border gradient-summer width-100"
                              alt="Card image"
                            />
                          </a>
                        </div>

                        <div className="text-center">
                          <span className="font-medium-2 text-uppercase">
                            Mark
                          </span>
                          <p className="grey font-small-2">Facebook Founder</p>
                        </div>
                        <br />
                        <br />
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <span className="text-bold-500 primary">
                              <i className="icon-user font-small-3"></i> Name:
                              Mark
                            </span>
                          </div>

                          <div className="col-12 col-md-6">
                            <span className="text-bold-500 primary">
                              <i className="icon-envelope-open font-small-3"></i>{" "}
                              Email: mark@gmail.com
                            </span>
                          </div>

                          <div
                            className="col-12 col-md-6"
                            style={{ marginTop: "20px" }}
                          >
                            <span className="text-bold-500 primary">
                              <i className="icon-screen-smartphone font-small-3"></i>{" "}
                              Phone: 98989898989
                            </span>
                          </div>
                          <div
                            className="col-12 col-md-6"
                            style={{ marginTop: "20px" }}
                          >
                            <span className="text-bold-500 primary">
                              <i className="icon-present font-small-3"></i>{" "}
                              Birthday: June 10th, 1988
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Profile;
