import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateHotelCharge, GetHotels } from "../store/slices/Hotels";
import { Loader } from "semantic-ui-react";
import Navbarside from "../component/Navbarside";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "./footer";
import { API_PATHS } from "../utils/constants/api.constants";

function UpdateHotelCharges(e) {
  const [cancelledCheque, setcancelledCheque] = useState("");
  const [facilitationFeePercent, setfacilitationFeePercent] = useState("");
  const [gstOnFfPercent, setgstOnFfPercent] = useState("");
  const [reversalOnFfPercent, setreversalOnFfPercent] = useState("");
  const [hplCommissionPercent, sethplCommissionPercent] = useState("");
  const [gstOnHplCommissionPercent, setgstOnHplCommissionPercent] =
    useState("");
  const [gstOnEffectivePricePercent, setgstOnEffectivePricePercent] =
    useState("");

  // Alert message
  const [alertfacilitationFeePercent, setAlertfacilitationFeePercent] =
    useState("");
  const [alertgstOnFfPercent, setAlertgstOnFfPercent] = useState("");
  const [alertreversalOnFfPercent, setAlertreversalOnFfPercent] = useState("");
  const [alerthplCommissionPercent, setAlerthplCommissionPercent] =
    useState("");
  const [alertgstOnHplCommissionPercent, setAlertgstOnHplCommissionPercent] =
    useState("");
  const [alertgstOnEffectivePricePercent, setAlertgstOnEffectivePricePercent] =
    useState("");
  const [alertimageurl, setAlertimageurl] = useState("");
  const [messagech, setMessagech] = useState("");

  const navigate = useNavigate("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { id } = useParams();
  localStorage.setItem("id", id);
  let hotelId = id;

  async function chequeUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setcancelledCheque(data.data.location);
    setMessagech(data.message);
  }
  React.useEffect(() => {
    dispatch(GetHotels())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id == id) {
          setfacilitationFeePercent(data.user.data[0].facilitationFeesPercent);
          setgstOnFfPercent(data.user.data[0].gstOnFfPercent);
          setreversalOnFfPercent(data.user.data[0].reversalOnFfPercent);
          sethplCommissionPercent(data.user.data[0].hplCommissionPercent);
          setgstOnEffectivePricePercent(
            data.user.data[0].gstOnEffectivePricePercent
          );
          setgstOnHplCommissionPercent(
            data.user.data[0].gstOnHplCommissionPercent
          );
          setcancelledCheque(data.user.data[0].cancelledCheque);
        }
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  async function upload(e) {
    e.preventDefault();
    let item = {
      cancelledCheque,
      facilitationFeePercent,
      gstOnFfPercent,
      reversalOnFfPercent,
      hplCommissionPercent,
      gstOnHplCommissionPercent,
      gstOnEffectivePricePercent,
      hotelId,
    };
    dispatch(updateHotelCharge(item))
      .unwrap()
      .then(() => {
        alert("Charges updated successfully");
        navigate("/HotelList");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const save = (e) => {
    const regtext = /^[0-9][0-9]?$|^100$/;
    if (regtext.test(facilitationFeePercent)) {
      setAlertfacilitationFeePercent("");
    } else if (
      !regtext.test(facilitationFeePercent) &&
      facilitationFeePercent === ""
    ) {
      setAlertfacilitationFeePercent(
        "Please enter your facilitation fee percent"
      );
      e.preventDefault();
    } else {
      setAlertfacilitationFeePercent("");
    }

    const regimage = /(gif|jpe?g|tiff?|png|webp|bmp)$/i;
    if (regimage.test(cancelledCheque)) {
      setAlertimageurl("");
    } else if (!regimage.test(cancelledCheque) && cancelledCheque === "") {
      setAlertimageurl("Please enter image link cancelled cheque");
      e.preventDefault();
    } else {
      setAlertimageurl("Invalid file");
      e.preventDefault();
    }

    const regalltext = /^[0-9][0-9]?$|^100$/;
    if (regalltext.test(reversalOnFfPercent)) {
      setAlertreversalOnFfPercent("");
    } else if (
      !regalltext.test(reversalOnFfPercent) &&
      reversalOnFfPercent === ""
    ) {
      setAlertreversalOnFfPercent("Please enter your reversal on percent");
      e.preventDefault();
    } else {
      setAlertreversalOnFfPercent("");
    }

    const regnumber = /^[0-9][0-9]?$|^100$/;
    if (regnumber.test(gstOnFfPercent)) {
      setAlertgstOnFfPercent("");
    } else if (!regnumber.test(gstOnFfPercent) && gstOnFfPercent === "") {
      setAlertgstOnFfPercent("Please enter your gst on percent");
      e.preventDefault();
    } else {
      setAlertgstOnFfPercent("");
    }

    const regnumber2 = /^[0-9][0-9]?$|^100$/;
    if (regnumber2.test(hplCommissionPercent)) {
      setAlerthplCommissionPercent("");
    } else if (
      !regnumber2.test(hplCommissionPercent) &&
      hplCommissionPercent === ""
    ) {
      setAlerthplCommissionPercent("Please enter your hpl commission percent");
      e.preventDefault();
    } else {
      setAlerthplCommissionPercent("");
    }

    const regnumber3 = /^[0-9][0-9]?$|^100$/;
    if (regnumber3.test(gstOnHplCommissionPercent)) {
      setAlertgstOnHplCommissionPercent("");
    } else if (
      !regnumber3.test(gstOnHplCommissionPercent) &&
      gstOnHplCommissionPercent === ""
    ) {
      setAlertgstOnHplCommissionPercent(
        "Please enter your gst hpl commission percent"
      );
      e.preventDefault();
    } else {
      setAlertgstOnHplCommissionPercent("");
    }

    const regnumber4 = /^[0-9][0-9]?$|^100$/;
    if (regnumber4.test(gstOnEffectivePricePercent)) {
      setAlertgstOnEffectivePricePercent("");
    } else if (
      !regnumber4.test(gstOnEffectivePricePercent) &&
      gstOnEffectivePricePercent === ""
    ) {
      setAlertgstOnEffectivePricePercent(
        "Please enter your gst effective price percent"
      );
      e.preventDefault();
    } else {
      setAlertgstOnEffectivePricePercent("");
    }
  };

  const clear = () => {
    navigate(-1);
  };

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-3" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Update Hotel Charges
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <form className="form" onSubmit={upload}>
                          <div className="row justify-content-md-center">
                            <div className="col-md-4">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Facilitation Fees Percentage
                                  </label>
                                  <input
                                    type="number"
                                    min="1"
                                    className="form-control"
                                    Placeholder="Facilitation Fees Percentage"
                                    value={facilitationFeePercent}
                                    onChange={(e) =>
                                      setfacilitationFeePercent(e.target.value)
                                    }
                                  />
                                  <p className="alert-message">
                                    {alertfacilitationFeePercent}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    HPL Commission Percentage
                                  </label>
                                  <input
                                    type="number"
                                    min="1"
                                    className="form-control"
                                    Placeholder="HPL Commission Percentage"
                                    value={hplCommissionPercent}
                                    onChange={(e) =>
                                      sethplCommissionPercent(e.target.value)
                                    }
                                  />
                                  <p className="alert-message">
                                    {alerthplCommissionPercent}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    GST on Effective Price
                                  </label>
                                  <input
                                    type="number"
                                    min="1"
                                    className="form-control"
                                    Placeholder="GST on Effective Price"
                                    value={gstOnEffectivePricePercent}
                                    onChange={(e) =>
                                      setgstOnEffectivePricePercent(
                                        e.target.value
                                      )
                                    }
                                  />
                                  <p className="alert-message">
                                    {alertgstOnEffectivePricePercent}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    GST Percent on Facilitation Fees
                                  </label>
                                  <input
                                    type="number"
                                    min="1"
                                    className="form-control"
                                    Placeholder="GST Percent on Facilitation Fees"
                                    value={gstOnFfPercent}
                                    onChange={(e) =>
                                      setgstOnFfPercent(e.target.value)
                                    }
                                  />
                                  <p className="alert-message">
                                    {alertgstOnFfPercent}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Reverse Percent on Facilitation Fees
                                  </label>
                                  <input
                                    type="number"
                                    min="1"
                                    className="form-control"
                                    Placeholder="Reverse Percent on Facilitation Fees"
                                    value={reversalOnFfPercent}
                                    onChange={(e) =>
                                      setreversalOnFfPercent(e.target.value)
                                    }
                                  />

                                  <p className="alert-message">
                                    {alertreversalOnFfPercent}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    GST on HPL Commission Percentage
                                  </label>
                                  <input
                                    type="number"
                                    min="1"
                                    className="form-control"
                                    Placeholder="GST on HPL Commission Percentage"
                                    value={gstOnHplCommissionPercent}
                                    onChange={(e) =>
                                      setgstOnHplCommissionPercent(
                                        e.target.value
                                      )
                                    }
                                  />
                                  <p className="alert-message">
                                    {alertgstOnHplCommissionPercent}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Cancelled Cheque
                                  </label>
                                  <input
                                    type="file"
                                    className="form-control-file"
                                    onChange={(e) => chequeUpload(e)}
                                  />
                                  <p className="alert-message">
                                    {alertimageurl}
                                  </p>
                                  <p
                                    style={{
                                      color: "green",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {messagech}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-8" />
                          </div>
                          {loading ? (
                            <Loader active inline />
                          ) : (
                            <>
                              <div className="form-actions center">
                                <button
                                  type="submit"
                                  className="btn btn-blue"
                                  onClick={save}
                                  disabled={!messagech}
                                >
                                  <i className="icon-note"></i> Save
                                </button>{" "}
                                &nbsp;
                                <button
                                  type="reset"
                                  className="btn btn-warning mr-1"
                                  onClick={clear}
                                >
                                  <i className="icon-trash"></i> Cancel
                                </button>
                              </div>
                            </>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
}
export default UpdateHotelCharges;
