import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import Navbarside from "../component/Navbarside";
import { citys, enablecitys } from "../store/slices/city";
import { Link, useNavigate } from "react-router-dom";

import Footer from "./footer";

function CityList() {
  const [cities, setCities] = useState([]);
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate("");

  React.useEffect(() => {
    dispatch(citys())
      .unwrap()
      .then((data) => {
        setCities(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  async function enableCity(id) {
    let isActive = true;
    let item = { set: { isActive } };
    localStorage.setItem("item", JSON.stringify(item));
    localStorage.setItem("id", id);

    dispatch(enablecitys())
      .unwrap()
      .then(() => {
        // alert("Uploaded succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  async function disableCity(id) {
    let isActive = false;
    let item = { set: { isActive } };
    localStorage.setItem("item", JSON.stringify(item));
    localStorage.setItem("id", id);

    dispatch(enablecitys())
      .unwrap()
      .then(() => {
        // alert("Uploaded succesfully");
        // navigate("/CityList");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
    console.log(item);
    console.log(id);
  }
  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title-wrap bar-success">
                          <h4 className="card-title">
                            City &nbsp; &nbsp;
                            <Link to="/UploadCity" className="btn btn-primary">
                              <i className="fa fa-plus"></i> Add City
                            </Link>
                          </h4>
                        </div>
                      </div>
                      <div className="card-body collapse show">
                        <div className="card-block card-dashboard table-responsive">
                          <table className="table table-striped table-bordered zero-configuration">
                            <thead>
                              <tr>
                                <th>City Name</th>
                                {/* <th>Tag Line</th> */}
                                <th style={{ width: "300px" }}>Description</th>
                                <th>Image</th>
                                <th>Action</th>
                                <th>Active</th>
                              </tr>
                            </thead>
                            {loading ? (
                              <Loader active inline />
                            ) : (
                              <>
                                <tbody>
                                  {cities.map((cityVal, index) => (
                                    <tr key={index}>
                                      <td>{cityVal.name}</td>
                                      {/* <td>
                                            {" "}
                                            {cityVal.isActive === false && (
                                              <p>Inactive</p>
                                            )}
                                            {cityVal.isActive === true && (
                                              <p>Active</p>
                                            )}
                                          </td> */}
                                      <td>
                                        {/* // <DescriptionText
                                            //   showMore={isShowMore}
                                            // > */}
                                        {cityVal.description}
                                        {/* </DescriptionText>
                                            <ShowMoreText
                                              onClick={toggleReadMore}
                                            >
                                              {isShowMore
                                                ? "View more..."
                                                : "View less"}
                                            </ShowMoreText> */}
                                      </td>{" "}
                                      <td>
                                        <img
                                          src={cityVal.image}
                                          alt=""
                                          style={{
                                            width: "150px",
                                            height: "100px",
                                            borderRadius: "5px",
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <Link
                                          style={{
                                            height: "25px",
                                            padding: "0 10px",
                                          }}
                                          to={`/EditCity/${
                                            cityVal._id ? cityVal._id : null
                                          }`}
                                          className="btn btn-primary"
                                        >
                                          Edit
                                        </Link>{" "}
                                      </td>
                                      <td>
                                        {cityVal.isActive === false && (
                                          <button
                                            style={{
                                              height: "25px",
                                              padding: "0 10px",
                                            }}
                                            className="btn btn-danger"
                                            onClick={() =>
                                              enableCity(cityVal._id)
                                            }
                                          >
                                            No
                                          </button>
                                        )}
                                        {cityVal.isActive === true && (
                                          <button
                                            style={{
                                              height: "25px",
                                              padding: "0 10px",
                                            }}
                                            className="btn btn-success"
                                            onClick={() =>
                                              disableCity(cityVal._id)
                                            }
                                          >
                                            Yes
                                          </button>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
}
export default CityList;
