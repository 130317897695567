import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editcitys, citys } from "../store/slices/city";
import { Loader } from "semantic-ui-react";
import Navbarside from "../component/Navbarside";
import { useNavigate, useParams } from "react-router-dom";
import { API_PATHS } from "../utils/constants/api.constants";
import Footer from "./footer";

function EditCity(e) {
  const [name, setName] = useState("");
  const [state, setState] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");

  // Alert message

  const [alertname, setAlertname] = useState("");
  const [alertstate, setAlertstate] = useState("");
  const [alertdescription, setAlertdescription] = useState("");
  const [alertimage, setAlertimage] = useState("");

  const navigate = useNavigate("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { id } = useParams();
  localStorage.setItem("id", id);
  React.useEffect(() => {
    dispatch(citys())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id == id) {
          setName(data.user.data[0].name);
          setState(data.user.data[0].state);
          setImage(data.user.data[0].image);
          setDescription(data.user.data[0].description);
        }
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  async function imageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setImage(data.data.location);
  }
  async function upload(e) {
    e.preventDefault();
    let item = { set: { name, state, description, image } };

    dispatch(editcitys(item))
      .unwrap()
      .then(() => {
        alert("Uploaded succesfully");
        navigate("/City");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const save = (e) => {
    const regtext = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (regtext.test(name)) {
      setAlertname("");
    } else if (!regtext.test(name) && name === "") {
      setAlertname("Please enter your name");
      e.preventDefault();
    } else {
      setAlertname("");
    }

    const regalltext = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    if (regalltext.test(description)) {
      setAlertdescription("");
    } else if (!regalltext.test(description) && description === "") {
      setAlertdescription("Please enter your description");
      e.preventDefault();
    } else {
      setAlertdescription("");
    }

    const regnumber = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9]+[ ]?|[/-])+)$/;
    if (regnumber.test(state)) {
      setAlertstate("");
    } else if (!regnumber.test(state) && state === "") {
      setAlertstate("Please enter your prise tag line");
      e.preventDefault();
    } else {
      setAlertstate("");
    }

    const regimage = /(gif|jpe?g|tiff?|png|webp|bmp)$/i;
    if (regimage.test(image)) {
      setAlertimage("");
    } else if (!regimage.test(image) && image === "") {
      setAlertimage("Please enter image link");
      e.preventDefault();
    } else {
      setAlertimage("Invalid file");
      e.preventDefault();
    }
  };

  const clear = () => {
    navigate(-1);
  };

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Edit City
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <form className="form" onSubmit={upload}>
                          <div className="row justify-content-md-center">
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">City Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="City Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                  <p className="alert-message">{alertname}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Price Tagline
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Price Tagline"
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                  />
                                  <p className="alert-message">{alertstate}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Image</label>
                                  <br />
                                  <img
                                    src={image}
                                    style={{ width: "150px", heigh: "100px" }}
                                    alt=""
                                    className="mb-2"
                                  />
                                  <br />
                                  <input
                                    type="file"
                                    className="form-control-file"
                                    Placeholder="Price Tagline"
                                    name="imageUrl"
                                    onChange={(e) => imageUpload(e)}
                                  />
                                  <p className="alert-message">{alertimage}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Description</label>
                                  <textarea
                                    className="form-control"
                                    Placeholder="Description"
                                    value={description}
                                    onChange={(e) =>
                                      setDescription(e.target.value)
                                    }
                                  ></textarea>
                                  <p className="alert-message">
                                    {alertdescription}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {loading ? (
                            <Loader active inline />
                          ) : (
                            <>
                              <div className="form-actions center">
                                <button
                                  type="submit"
                                  className="btn btn-blue"
                                  disabled={!image}
                                  onClick={save}
                                >
                                  <i className="icon-note"></i> Save
                                </button>{" "}
                                &nbsp;
                                <button
                                  type="reset"
                                  className="btn btn-warning mr-1"
                                  onClick={clear}
                                >
                                  <i className="icon-trash"></i> Cancel
                                </button>
                              </div>
                            </>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
}
export default EditCity;
