import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class PropertyService {
    static getPropertys() {
        return axios
            .get(API_PATHS.getProperty)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }
    
    static uploadPropertys(item) {
        return axios
            .post(API_PATHS.postProperty,
                item
            )
            .then((response) => {
                if (response.data) {
                     // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }
    

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default PropertyService;