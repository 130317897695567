import React, { useState } from "react";
import Navbarside from "../component/Navbarside";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";

import { BookingDetail } from "./../store/slices/booking";
import Footer from "./footer";

function BookingDetails() {
  const navigate = useNavigate("");
  const [bookingNumber, setbookingNumber] = useState("");
  const [userName, setuserName] = useState("");
  const [packageId, setpackageId] = useState("");
  const [agentId, setagentId] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [basicRoomRate, setgrossAmount] = useState("");
  const [taxes, setTaxes] = useState("");
  const [discount, setDiscount] = useState("");
  const [netAmount, setnetAmount] = useState("");
  const [adultsPrice, setimportantNotes] = useState("");
  const [childrenPrice, setchildrenPrice] = useState("");

  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { id } = useParams();
  localStorage.setItem("id", id);
  React.useEffect(() => {
    dispatch(BookingDetail())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id === id) {
          setuserName(data.user.data[0].userName);
          setstartDate(data.user.data[0].startDate);
          setbookingNumber(data.user.data[0].bookingNumber);
          setgrossAmount(data.user.data[0].pricingDetails.basicRoomRate);
          setTaxes(data.user.data[0].pricingDetails.taxes);
          setDiscount(data.user.data[0].pricingDetails.discount);
          setnetAmount(data.user.data[0].pricingDetails.netAmount);
          setimportantNotes(data.user.data[0].pricingDetails.adultsPrice);
          setchildrenPrice(data.user.data[0].pricingDetails.childrenPrice);
          setpackageId(data.user.data[0].packageName);
          setagentId(data.user.data[0].agentId._id);
          setendDate(data.user.data[0].endDate);
        }
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Booking Details
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <div className="row justify-content-md-center">
                          <div className="col-md-6">
                            <div className="form-group">
                              <p for="basicSelect">
                                Customer Name : {userName}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <p for="basicSelect">
                                Booking Number : {bookingNumber}
                              </p>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <fieldset className="form-group">
                                <p for="placeTextarea">AgentId : {agentId}</p>
                              </fieldset>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <p for="eventInput4">Travel Date: {startDate} </p>
                            </div>
                          </div>
                          {/* <div className="col-md-6">
                            <div className="form-group">
                              <p for="eventInput5">End Date : {endDate}</p>
                            </div>
                          </div> */}
                          <div className="col-md-6">
                            <div className="form-group">
                              <p for="eventInput5">
                                Gross Amount: {basicRoomRate}
                              </p>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <fieldset className="form-group">
                                <p for="placeTextarea">Taxes: {taxes}</p>
                              </fieldset>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <fieldset className="form-group">
                                <p for="placeTextarea">Discount: {discount}</p>
                              </fieldset>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <fieldset className="form-group">
                                <p for="placeTextarea">
                                  Net Amount : {netAmount}
                                </p>
                              </fieldset>
                            </div>
                          </div>
                          {/* <div className="col-md-6">
                            <div className="form-group">
                              <fieldset className="form-group">
                                <p for="placeTextarea">
                                  Price for Adult: {adultsPrice}
                                </p>
                              </fieldset>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <fieldset className="form-group">
                                <p for="placeTextarea">
                                  Price For Children: {childrenPrice}
                                </p>
                              </fieldset>
                            </div>
                          </div> */}

                          <div className="col-md-6"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default BookingDetails;
