import React from "react";
import Navbarside from "../component/Navbarside";
import Footer from "./footer";
import { Link } from "react-router-dom";
function Travel() {
  let data = JSON.parse(localStorage.getItem("user"));
  let role = data.data.roles[0];
  return (
    <div>
      <Navbarside />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                  <Link to="/TravelPackage">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-2 card-title mb-0">
                                Travel Packages
                              </h4>
                            </div>
                            <div className="media-right text-right">
                              <i
                                className="fa fa-building-o"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart"
                          className="height-50 lineChartWidget WidgetlineChart mb-2"
                        ></div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                  <Link to="/TravelCategory">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-2 card-title mb-0">
                                Travel Category
                              </h4>
                            </div>
                            <div className="media-right text-right">
                              <i className="icon-wallet font-large-1 warning"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart1"
                          className="height-50 lineChartWidget WidgetlineChart1 mb-2"
                        ></div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                  <Link to="/City">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-2 card-title mb-0">
                                City
                              </h4>
                            </div>
                            <div className="media-right text-right">
                              <i className="icon-basket-loaded font-large-1 success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-50 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                  <Link to="/ThemeList">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-2 card-title mb-0">
                                Theme
                              </h4>
                            </div>
                            <div className="media-right text-right">
                              <i className="icon-basket-loaded font-large-1 success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-50 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                  <Link to="/TravelBooking">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-2 card-title mb-0">
                                Booking
                              </h4>
                            </div>
                            <div className="media-right text-right">
                              <i className="icon-cup font-large-1 primary"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart"
                          className="height-50 lineChartWidget WidgetlineChart mb-2"
                        ></div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                  <Link to="/TravelAgency">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-2 card-title mb-0">
                                Travel Agency
                              </h4>
                            </div>
                            <div className="media-right text-right">
                              <i className="icon-wallet font-large-1 warning"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart1"
                          className="height-50 lineChartWidget WidgetlineChart1 mb-2"
                        ></div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                  <Link to="/TravelAgents">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-2 card-title mb-0">
                                Travel Agent
                              </h4>
                            </div>
                            <div className="media-right text-right">
                              <i className="icon-basket-loaded font-large-1 success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-50 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Travel;
