import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import Navbarside from "../component/Navbarside";
import { hotelAdmin } from "../store/slices/hotelAdmin";
import { API_PATHS } from "../utils/constants/api.constants";
import { ExportToExcel } from "./ExportToExcel";
import axios from "axios";
import Footer from "./footer";

const Hoteladmin = () => {
  const [expertises, setexpertise] = useState([]);
  const [data, setData] = React.useState([]);
  const fileName = "HotelAdmin";
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  React.useEffect(() => {
    const fetchData = () => {
      axios.get(API_PATHS.gethotels).then((postData) => {
        // reshaping the array
        const customHeadings = postData.data.data.map((item) => ({
          "Admin Name": item.firstName,
          "Admin Email": item.email,
          "Admin Mobile": item.mobileNo,
        }));

        setData(customHeadings);
      });
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    dispatch(hotelAdmin())
      .unwrap()
      .then((data) => {
        setexpertise(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header" align="right">
                      <ExportToExcel apiData={data} fileName={fileName} />
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Mobile Number</th>
                              <th>Email</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          {loading ? (
                            <Loader active inline />
                          ) : (
                            <tbody>
                              {expertises.map((agentVal, index) => (
                                <tr key={index}>
                                  <td>
                                    {agentVal.firstName} {agentVal.lastName}
                                  </td>
                                  <td>{agentVal.mobileNo}</td>
                                  <td>{agentVal.email}</td>
                                  <td></td>
                                </tr>
                              ))}
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Hoteladmin;
