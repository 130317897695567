import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./slices/auth";
import categoryReducer from "./slices/category";
import bookingReducer from "./slices/booking";
import revenueReducer from "./slices/revenue";
import agentReducer from './slices/agent';
import packagesReducer from "./slices/packages";
// import hotelReducer from "./slices/Hotel";
import cityReducer from "./slices/travelCitys";


const reducer = {
    auth: authReducer,
    category: categoryReducer,
    booking: bookingReducer,
    revenue: revenueReducer,
    travelagent: agentReducer,
    packages: packagesReducer,
    // hotel: hotelReducer,
    city: cityReducer,
}




const store = configureStore({
    reducer: reducer,
    devTools: true,
})

export default store;