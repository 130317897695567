import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../img/travelonify_logo.png";
import { updatePass } from "../store/slices/auth";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import { VscEye } from "react-icons/vsc";

const ChangePassword = () => {

//     const location = useLocation();
//     const values = location.state;
//    const mobileNo = values.mobile;

  const [otp, setOtp] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [newpassword, setNewpassword] = useState("");

  const [alertnewpassword, setAlertnewpassword] = useState("");
  const [alertotp, setAlertotp] = useState("");

  const navigate = useNavigate("");
  const { loading, error, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

{ /*
password show and hide code :-
const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };*/}

  async function signIn(e) {
    e.preventDefault();
    dispatch(updatePass({otp, mobileNo, newpassword }))
      .unwrap()
      .then(() => {
        navigate("/");
      })
      .catch(({ message }) => {
        alert(message);
      });



  
  }

const save = (e) =>{


    const regNum = /^[0-9][0-9]?$|^100$/;
    if (regNum.test(otp)) {
      setAlertotp("");
    } else if (!regNum.test(otp) && otp === "") {
      setAlertotp("Please enter your OTP");
      e.preventDefault("");
    } else {
      setAlertotp("");
      

    }

    const regpassword =  /^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,8}$/
    if (regpassword.test(newpassword)) {
      setAlertnewpassword("");
    } else if (!regpassword.test(newpassword) && newpassword === "") {
      setAlertnewpassword("Please enter new password");
      e.preventDefault("");

    } else {
      setAlertnewpassword("Invalid password");
      e.preventDefault("");

    }

}

  return (
    <div>
    <section className="login-bg-image">
    <div className="bg-overlay-orange" />

    
    <div className="card-section">
<div className="d-flex justify-content-around align-items-center">

<img src={logo} alt="travel-logo" className="div-logo"/>

<div className="h-logn-line" />

<div className="login-form-2">
{/*error && <Alert variant="danger">{error}</Alert>*/}
<div className="d-flex justify-content-center">
<img src={logo} alt="logo" className="logo-form-2" />
</div>
<h2 className="text-center" style={{color:"black", fontWeight:"500"}}>Change Password </h2>
<br/>
<form onSubmit={signIn}>

   

<input
maxlength="4"
onKeyPress={(event) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
}}
type="text"
value={otp}
onChange={(e) => setOtp(e.target.value)}
className="form-control text-black-ph"
placeholder="Enter your OTP"
style={{ height: "50px" }}
/>
<p className="alert-message">{alertotp}</p>

    <input
      type="text"
      value={newpassword}
      onChange={(e) => setNewpassword(e.target.value)}
      className="form-control text-black-ph"
      placeholder="Enter your new password"
      style={{ height: "50px" }}
    />
  

  <p className="alert-message">{alertnewpassword}</p>

  <div className="col-md-12 d-flex justify-content-center mt-4">
    <button
      type="submit"
      className="btn btn-warning mb-1 login-btn col-white"
      onClick={save}
    >
      Submit
    </button>
  </div>

  
</form>
</div>
</div>
</div>

  
  </section>
    </div>
  );
};

export default ChangePassword;
