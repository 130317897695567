import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CityService from "../../services/city.service";
import AuthService from "../../services/auth.service";

export const citys = createAsyncThunk(
  "city/get/getCity",
  async ( thunkAPI) => {
    try {
      const data = await CityService.getCity();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const states = createAsyncThunk(
  "city/get/states",
  async ( thunkAPI) => {
    try {
      const data = await CityService.getState();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
export const uploadcitys = createAsyncThunk(
    "citys/post/postCity",
    async(item, thunkAPI) => {
        try {
            const data = await CityService.uploadCity(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);
export const editcitys = createAsyncThunk(
  "citys/put/editCity",
  async(item, thunkAPI) => {
      try {
          const data = await CityService.editCity(item);
          return { user: data };
      } catch (error) {
          const message =
              (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString();
          return thunkAPI.rejectWithValue({ message });
      }
  }
);

export const enablecitys = createAsyncThunk(
  "citys/put/editCity",
  async(item, thunkAPI) => {
      try {
          const data = await CityService.enableCity(item);
          return { user: data };
      } catch (error) {
          const message =
              (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString();
          return thunkAPI.rejectWithValue({ message });
      }
  }
);
// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
  loading: false,
  error: "",
  user: AuthService.getUserDetails() || null,
  isLoggedIn: false,
};

const citysSlice = createSlice({
  name: "citys",
  initialState,
  extraReducers: {
    [citys.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [citys.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [citys.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },
    [uploadcitys.pending]: (state) => {
        state.loading = true;
        state.error = "";
        state.isLoggedIn = false;
        state.user = null;
      },
      [uploadcitys.fulfilled]: (state, action) => {
        state.loading = false;
        state.error = "";
        state.isLoggedIn = true;
        state.user = action.payload.user;
      },
      [uploadcitys.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
        state.isLoggedIn = false;
        state.user = null;
      },
      [editcitys.pending]: (state) => {
        state.loading = true;
        state.error = "";
        state.isLoggedIn = false;
        state.user = null;
      },
      [editcitys.fulfilled]: (state, action) => {
        state.loading = false;
        state.error = "";
        state.isLoggedIn = true;
        state.user = action.payload.user;
      },
      [editcitys.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
        state.isLoggedIn = false;
        state.user = null;
      },
    // [logout.fulfilled]: (state, action) => {
    //   state.isLoggedIn = false;
    //   state.user = null;
    // },
  },
});

const { reducer } = citysSlice;
export default reducer;