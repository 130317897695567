import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAgencyCharges, agencyDetails } from "../store/slices/agency";
import { Loader } from "semantic-ui-react";
import Navbarside from "../component/Navbarside";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "./footer";

function UpdateCharges(e) {
  const [facilitationFeesPercent, setfacilitationFeesPercent] = useState("");
  const [gstOnFfPercent, setgstOnFfPercent] = useState("");
  const [reversalOnFfPercent, setreversalOnFfPercent] = useState("");
  const [hplCommissionPercent, sethplCommissionPercent] = useState("");

  // Alert message
  const [alertname, setAlertname] = useState("");
  const [alertstate, setAlertstate] = useState("");
  const [alertdescription, setAlertdescription] = useState("");
  const [alerthoff, setAlerthoff] = useState("");

  const navigate = useNavigate("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { id } = useParams();
  localStorage.setItem("id", id);

  React.useEffect(() => {
    dispatch(agencyDetails())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id == id) {
          setfacilitationFeesPercent(data.user.data[0].facilitationFeesPercent);
          setgstOnFfPercent(data.user.data[0].gstOnFfPercent);
          setreversalOnFfPercent(data.user.data[0].reversalOnFfPercent);
          sethplCommissionPercent(data.user.data[0].hplCommissionPercent);
        }
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  async function upload(e) {
    e.preventDefault();
    let item = {
      set: {
        facilitationFeesPercent,
        gstOnFfPercent,
        reversalOnFfPercent,
        hplCommissionPercent,
      },
    };

    dispatch(updateAgencyCharges(item))
      .unwrap()
      .then(() => {
        alert("Charges updated successfully");
        navigate("/TravelAgency");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const save = (e) => {
    const regtext = /^[0-9][0-9]?$|^100$/;
    if (regtext.test(facilitationFeesPercent)) {
      setAlertname("");
    } else if (
      !regtext.test(facilitationFeesPercent) &&
      facilitationFeesPercent === ""
    ) {
      setAlertname("Please enter facilitation fees percent");
      e.preventDefault();
    } else {
      setAlertname("");
    }

    const regalltext = /^[0-9][0-9]?$|^100$/;
    if (regalltext.test(reversalOnFfPercent)) {
      setAlertdescription("");
    } else if (
      !regalltext.test(reversalOnFfPercent) &&
      reversalOnFfPercent === ""
    ) {
      setAlertdescription("Please enter your reversal on percent");
      e.preventDefault();
    } else {
      setAlertdescription("");
    }

    const regnumber = /^[0-9][0-9]?$|^100$/;
    if (regnumber.test(gstOnFfPercent)) {
      setAlertstate("");
    } else if (!regnumber.test(gstOnFfPercent) && gstOnFfPercent === "") {
      setAlertstate("Please enter your prise tag line");
      e.preventDefault();
    } else {
      setAlertstate("");
    }

    const numberregex = /^[0-9][0-9]?$|^100$/;
    if (numberregex.test(hplCommissionPercent)) {
      setAlerthoff("");
    } else if (
      !numberregex.test(hplCommissionPercent) &&
      hplCommissionPercent === ""
    ) {
      setAlerthoff("Please enter your commission percent");
      e.preventDefault();
    } else {
      setAlerthoff("");
    }
  };

  const clear = () => {
    navigate(-1);
  };

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-3" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Update Agency Charges
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <form className="form" onSubmit={upload}>
                          <div className="row justify-content-md-center">
                            <div className="col-md-3">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    HPL Commission Percentage
                                    <br />
                                    &nbsp;
                                  </label>
                                  <input
                                    type="number"
                                    min="1"
                                    className="form-control"
                                    Placeholder="HPL Commission Percentage"
                                    value={hplCommissionPercent}
                                    onChange={(e) =>
                                      sethplCommissionPercent(e.target.value)
                                    }
                                  />
                                  <p className="alert-message">{alerthoff}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Facilitation Fees Percentage
                                  </label>
                                  <br />
                                  &nbsp;
                                  <input
                                    type="number"
                                    min="1"
                                    className="form-control"
                                    Placeholder="Facilitation Fees Percentage"
                                    value={facilitationFeesPercent}
                                    onChange={(e) =>
                                      setfacilitationFeesPercent(e.target.value)
                                    }
                                  />
                                  <p className="alert-message">{alertname}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    GST Percent on Facilitation Fees
                                  </label>
                                  <input
                                    type="number"
                                    min="1"
                                    className="form-control"
                                    Placeholder="GST Percent on Facilitation Fees"
                                    value={gstOnFfPercent}
                                    onChange={(e) =>
                                      setgstOnFfPercent(e.target.value)
                                    }
                                  />
                                  <p className="alert-message">{alertstate}</p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Reverse Percent on Facilitation Fees
                                  </label>
                                  <input
                                    type="number"
                                    min="1"
                                    className="form-control"
                                    Placeholder="Reverse Percent on Facilitation Fees"
                                    value={reversalOnFfPercent}
                                    onChange={(e) =>
                                      setreversalOnFfPercent(e.target.value)
                                    }
                                  />

                                  <p className="alert-message">
                                    {alertdescription}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {loading ? (
                            <Loader active inline />
                          ) : (
                            <>
                              <div className="form-actions center">
                                <button
                                  type="submit"
                                  className="btn btn-blue"
                                  onClick={save}
                                >
                                  <i className="icon-note"></i> Save
                                </button>{" "}
                                &nbsp;
                                <button
                                  type="reset"
                                  className="btn btn-warning mr-1"
                                  onClick={clear}
                                >
                                  <i className="icon-trash"></i> Cancel
                                </button>
                              </div>
                            </>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
}
export default UpdateCharges;
