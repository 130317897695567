import React from 'react';
import './App.css';
import Home from './component/Home';

import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

//auth
import Login from './component/Login';
import Profile from './component/Profile';
import Register from './component/registration';
import Notfoundpage from './component/Not-foundpage';
import VerifyOtp from './component/verifyOtp';

//travel
import Addpackage from './component/Uploadpackage';
import TravelPackage from './component/TravelPackage';
import CopyPackage from './component/CopyPackage';
import AgencyRegister from './component/AgencyRegister';
import PackageDetails from './component/PackageDetails';
import MyTravelPackage from './component/MyTravelPackage';
import TravelCategory from './component/Travelcategory';
import UploadCategory from './component/UploadCategory';
import TravelBooking from './component/TravelBooking';
import BookingDetails from './component/BookingDetails';
import Revenue from './component/Revenue';
import TravelAgents from './component/TravelAgents';
import ProtectedRoutes from './component/ProtectedRoutes';
import PublicRoutes from './component/PublicRoutes';
import Editpackage from './component/EditPackage';
import Travel from './component/Travel';


//hotel
import BedType from './component/BedType';
import UploadBedType from "./component/UploadBedType";
import Hotels from './component/Hotels';
import RoomCategory from './component/RoomCategory';
import AddRoomCategory from './component/AddRoomcategory';
import AddRoomGroup from './component/AddRoomGroupTravel';
import UploadHotel from './component/UploadHotel';
import Editcategory from './component/Editcategory';
import Editcity from './component/Editcity';
import Hoteladmin from './component/Hoteladmin';
import UploadCity from './component/UploadCity';
import UpdateCharges from './component/UpdateCharges';
import HotelList from './component/HotelList';
import Enquiry from './component/Enquiry';
import HotelBooking from './component/HotelBookings';

import Rooms from './component/Rooms';
import City from './component/City';
import Amenity from './component/Amenity';
import Tag from './component/Tag';
import Property from './component/Property';
import UploadAmenity from './component/UploadAmenity';
import UploadProperty from './component/UploadProperty';
import UploadTag from './component/UploadTag';
import Addhotels from './component/Addhotels';
import Addtravel from './component/Addtravel';
import ThemeList from './component/theme';
import UploadTheme from './component/UploadTheme';
import EditTheme from './component/EditTheme';
import AddImage from './component/AddImage';

import MyClients from './component/MyClients';
import {ExportToExcel} from './component/ExportToExcel';
import TravelAgency from "./component/TravelAgency";
import AddRoomGroupTravel from './component/AddRoomGroupTravel';
import RoomType from './component/RoomType';
import UploadRoomType from './component/UploadRoomType';
import TravelPackageEnquiry from './component/TravelPackageENquiry';
import TravelAgentEnquiry from './component/TravelAgentEnquiry';
import Addrooms from './component/Addrooms';
import Blog from './component/Blog';
import BlogDetails from './component/BlogDetails';
import Addblog from './component/Addblog';
import Traveldetails from './component/Traveldetails';
import UpdateAgency from './component/UpdateAgency';
import UpdateHotelCharges from './component/UpdateHotelCharges';
import ForgotPassword from './component/ForgotPassword';
import ChangePassword from './component/ChangePassword';
import Questionnaire from './component/Questionnaire';
import AddQuestionnaire from './component/AddQuestionnaire';
import Updateconsultphysiotherapy from './component/Updateconsult-physiotherapy';
function App() {

    return (<Router> <Routes>
         <Route path="/"  element={<ProtectedRoutes />}> 
          <Route exact path='/Travel' element={<Travel />} /> 
         <Route exact path='/Home' element={<Home />} /> 
         <Route exact path='/Blog' element={<Blog />} /> 
         <Route exact path='/Hotels' element={<Hotels />} /> 
         <Route exact path='/Addrooms' element={<Addrooms />} /> 
         <Route exact path='/Not-foundpage' element={<Notfoundpage />} /> 
         <Route exact path='/Addtravel' element={<Addtravel />} /> 
         <Route exact path='/Addblog' element={<Addblog />} />
         <Route exact path='/Property' element={<Property />} /> 
         <Route exact path='/Tag' element={<Tag />} /> 
         <Route exact path='/City' element={<City />} /> 
         <Route exact path='/Rooms' element={<Rooms />} /> 
         <Route exact path='/Amenity' element={<Amenity />} /> 
         <Route exact path='/HotelBookings' element={<HotelBooking />} /> 
        <Route path="/" element={<Navigate replace to="home" />}/> 
        <Route exact path="/BookingDetails/:id" element={<BookingDetails/>}/>
        <Route exact path="/UpdateCharges/:id" element={<UpdateCharges/>}/>
        <Route exact path="/AddRoom/:id" element={<AddRoomGroup/>}/> 
        <Route exact path="/AddImage/:id" element={<AddImage/>}/>        
        <Route exact path='/travelCategory' element={< TravelCategory/>} />  
        <Route exact path='/Profile' element={<Profile />} /> 
        <Route exact path='/Uploadpackage' element={<Addpackage />} /> 
        <Route exact path='/UploadHotel' element={<UploadHotel />} /> 
        <Route exact path='/UploadAmenity' element={<UploadAmenity />} /> 
        <Route exact path='/UploadProperty' element={<UploadProperty />} /> 
        <Route exact path='/Addhotels' element={<Addhotels />} /> 
        <Route exact path='/TravelBooking' element={<TravelBooking />} /> 
        <Route exact path='/Amenity' element={<Amenity />} /> 
        <Route exact path='/HotelList' element={<HotelList />} /> 
        <Route exact path='/CopyPackage' element={<CopyPackage />} /> 
        <Route exact path='/Hoteladmin' element={<Hoteladmin />} /> 
        <Route exact path='/TravelPackage' element={<TravelPackage />} /> 
        <Route exact path='/TravelPackages' element={<MyTravelPackage />} /> 
        <Route exact path="/TravelAgency" element={<TravelAgency/>}/>
        <Route exact path='/register/VerifyOtp' element={<VerifyOtp />} />
        <Route exact path ="/UploadCategory" element={<UploadCategory/>} />
        <Route exact path="/TravelAgents" element={<TravelAgents/>}/>
        <Route exact path="/EditPackage/:id" element={<Editpackage/>}/>
        <Route exact path="/AddRoomGroupTravel/:id" element={<AddRoomGroupTravel/>}/>
        <Route exact path="/CopyPackage/:id" element={<CopyPackage/>}/>
        <Route exact path="/PackageDetails/:id" element={<PackageDetails/>}/>
         <Route exact path="/EditCategory/:id" element={<Editcategory/>}/>
        <Route exact path="/RoomCategory" element={<RoomCategory/>}/>
        <Route exact path="/BedType" element={<BedType/>}/>
        <Route exact path="/UploadBedType" element={<UploadBedType/>}/>
        <Route exact path="/UploadTag" element={<UploadTag/>}/>
        <Route exact path="/UploadCity" element={<UploadCity/>} />
        <Route exact path="/Editcity/:id" element={<Editcity/>}/>
        <Route exact path="/ThemeList" element={<ThemeList/>}/>
        <Route exact path="/EditTheme/:id" element={<EditTheme/>}/>
        <Route exact path="/Traveldetails/:id" element={<Traveldetails/>}/>
        <Route exact path="/UploadTheme" element={<UploadTheme/>} />
        <Route exact path="/AddRoomCategory" element={<AddRoomCategory/>} />
        <Route exact path="/UploadRoomType" element={<UploadRoomType/>} />
        <Route exact path="/RoomType" element={<RoomType/>} />
        <Route exact path="/MyClients" element={<MyClients/>} />
        <Route exact path="/TravelPackageEnquiry" element={<TravelPackageEnquiry/>}/>
        <Route exact path="/TravelAgentEnquiry" element={<TravelAgentEnquiry/>}/>
        <Route exact path="/UpdateAgency" element={<UpdateAgency/>}/>
        <Route exact path="/UpdateHotelCharges/:id" element={<UpdateHotelCharges/>}/>
       
        <Route exact path="/Questionnaire" element={<Questionnaire/>}/>
        <Route exact path="/AddQuestionnaire" element={<AddQuestionnaire/>}/>
        </Route>

        <Route path="login" element={<PublicRoutes/>}>
          <Route exact path="/login" element={<Login />}/>
        </Route>
        
        <Route exact path = '/register/VerifyOtp' element = {<VerifyOtp/> }/>
        <Route exact path = '/register' element = {<Register/>} />   
        <Route exact path = '/agencyregister' element = {<AgencyRegister/>} />       
         <Route exact path='/ForgotPassword' element={<ForgotPassword />} /> 
         <Route exact path='/ChangePassword' element={<ChangePassword />} /> 
          
        
        </Routes> 
        </Router >
    );
}

export default App;