import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PackagesService from "../../services/packages.service";
import AuthService from "../../services/auth.service";

export const packages = createAsyncThunk(
  "packages/get/travelPackage",
  async ( thunkAPI) => {
    try {
      const data = await PackagesService.getTravelPackage();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
export const packageDetails = createAsyncThunk(
  "packages/get/travelPackage",
  async ( thunkAPI) => {
    try {
      const data = await PackagesService.getTravelPackage();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
export const uploadPackages = createAsyncThunk(
    "packages/post/travelPackage",
    async(item, thunkAPI) => {
        try {
            const data = await PackagesService.uploadPackage(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);
export const editPackages = createAsyncThunk(
  "packages/put/travelPackage",
  async(item, thunkAPI) => {
      try {
          const data = await PackagesService.editPackage(item);
          return { user: data };
      } catch (error) {
          const message =
              (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString();
          return thunkAPI.rejectWithValue({ message });
      }
  }
);

export const enablePackages = createAsyncThunk(
  "citys/put/editCity",
  async(item, thunkAPI) => {
      try {
          const data = await PackagesService.enablePackage(item);
          return { user: data };
      } catch (error) {
          const message =
              (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString();
          return thunkAPI.rejectWithValue({ message });
      }
  }
);
// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
  loading: false,
  error: "",
  user: AuthService.getUserDetails() || null,
  isLoggedIn: false,
};

const packagesSlice = createSlice({
  name: "packages",
  initialState,
  extraReducers: {
    [packages.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [packages.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [packages.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },
    [uploadPackages.pending]: (state) => {
        state.loading = true;
        state.error = "";
        state.isLoggedIn = false;
        state.user = null;
      },
      [uploadPackages.fulfilled]: (state, action) => {
        state.loading = false;
        state.error = "";
        state.isLoggedIn = true;
        state.user = action.payload.user;
      },
      [uploadPackages.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
        state.isLoggedIn = false;
        state.user = null;
      },
      [editPackages.pending]: (state) => {
        state.loading = true;
        state.error = "";
        state.isLoggedIn = false;
        state.user = null;
      },
      [editPackages.fulfilled]: (state, action) => {
        state.loading = false;
        state.error = "";
        state.isLoggedIn = true;
        state.user = action.payload.user;
      },
      [editPackages.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
        state.isLoggedIn = false;
        state.user = null;
      },
      [enablePackages.pending]: (state) => {
        state.loading = true;
        state.error = "";
        state.isLoggedIn = false;
        state.user = null;
      },
      [enablePackages.fulfilled]: (state, action) => {
        state.loading = false;
        state.error = "";
        state.isLoggedIn = true;
        state.user = action.payload.user;
      },
      [enablePackages.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
        state.isLoggedIn = false;
        state.user = null;
      },
    // [logout.fulfilled]: (state, action) => {
    //   state.isLoggedIn = false;
    //   state.user = null;
    // },
  },
});

const { reducer } = packagesSlice;
export default reducer;