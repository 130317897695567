import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BookingTravel from "../../services/booking.services";
import AuthService from "../../services/auth.service";

export const Booking = createAsyncThunk(
    "booking/get/travelBooking",
    async(thunkAPI) => {
        try {
            console.log("booking")
            const data = await BookingTravel.travelBooking();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const BookingDetail = createAsyncThunk(
    "booking/get/travelBooking",
    async(thunkAPI) => {
        try {
            console.log("booking")
            const data = await BookingTravel.travelBookingDetails();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const HotelBookings = createAsyncThunk(
    "booking/get/hotelBooking",
    async(thunkAPI) => {
        try {
            //console.log("booking")
            const data = await BookingTravel.hotelBooking();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
    loading: false,
    error: "",
    user: AuthService.getUserDetails() || null,
    isLoggedIn: false,
};

const bookingSlice = createSlice({
    name: "booking",
    initialState,
    extraReducers: {
        [Booking.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [Booking.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [Booking.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },
        // [logout.fulfilled]: (state, action) => {
        //   state.isLoggedIn = false;
        //   state.user = null;
        // },
    },
});

const { reducer } = bookingSlice;
export default reducer;