import React, { useState } from "react";
import Navbarside from "../component/Navbarside";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import HotelService from "../services/hotel.service";
import Footer from "./footer";

import { useNavigate } from "react-router-dom";

function UploadHotel(e) {
  const navigate = useNavigate("");

  const [tags, setTags] = useState("");

  const [amenities, setAmenities] = useState("");

  const [uniqueName, setuniqueName] = useState();
  const [description, setDescription] = useState();

  const [name, setName] = useState([]);

  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // React.useEffect(() => {
  //   dispatch(Tags())
  //     .unwrap()
  //     .then((data) => {
  //       setName(data.user.data);
  //     })
  //     .catch(({ message }) => {
  //       alert(message);
  //     });
  // }, [dispatch]);

  // React.useEffect(() => {
  //   dispatch(Amenities())
  //     .unwrap()
  //     .then((data) => {
  //       setName(data.user.data);
  //     })
  //     .catch(({ message }) => {
  //       alert(message);
  //     });
  // }, [dispatch]);

  // const Tags = createAsyncThunk("tags/get/tag", async (thunkAPI) => {
  //   try {
  //     const data = await HotelService.getTag();
  //     return { user: data };
  //   } catch (error) {
  //     const message =
  //       (error.response &&
  //         error.response.data &&
  //         error.response.data.message) ||
  //       error.message ||
  //       error.toString();
  //     return thunkAPI.rejectWithValue({ message });
  //   }
  // });

  // const Amenities = createAsyncThunk(
  //   "Amenity/get/amenity",
  //   async (thunkAPI) => {
  //     try {
  //       const data = await HotelService.getAmenity();
  //       return { user: data };
  //     } catch (error) {
  //       const message =
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //         error.message ||
  //         error.toString();
  //       return thunkAPI.rejectWithValue({ message });
  //     }
  //   }
  // );

  const handleTagChange = (e) => {
    var value = tags.filter(function (tag) {
      return tag.name == e.target.value;
    });
    setTags(value[0].id);
  };

  const handleAmenitiesChange = (e) => {
    var value = amenities.filter(function (amenity) {
      return amenity.name == e.target.value;
    });
    setAmenities(value[0].id);
  };

  const cancel =()=>{

    navigate(-1);

  }

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Upload Hotel
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <form onSubmit="">
                          <div className="row justify-content-md-center">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="basicSelect">Select Category</label>
                                <select
                                  className="form-control"
                                  onChange={handleTagChange}
                                  required
                                >
                                  {name.map((tag) => (
                                    <option value={tag.tag}>{tag.name}</option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="eventInput2">Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  onChange={(e) => setName(e.target.value)}
                                  id="inputcategoryName"
                                  // value={name}
                                  required
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="eventInput2">uniqueName</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="uniqueName"
                                  onChange={(e) =>
                                    setuniqueName(e.target.value)
                                  }
                                  value={uniqueName}
                                  required
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="eventInput2">Description</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="uniqueName"
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                  value={description}
                                  required
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="basicSelect">Select Category</label>
                                <select
                                  className="form-control"
                                  onChange={handleAmenitiesChange}
                                  required
                                >
                                  {name.map((amenities) => (
                                    <option value={amenities.amenities}>
                                      {amenities.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="d-flex justify-content-center">
                          <button type="submit" className="btn btn-warning">Submit</button>
                          &nbsp;
                          &nbsp;
                          <button type="button" className="btn btn-danger" onClick={cancel}>Cancel</button>

                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

<Footer/>
    </div>
  );
}

export default UploadHotel;
