import React, { Component } from "react";
import axios from "axios";
import Navbarside from "../component/Navbarside";
import Footer from "./footer";

function Home() {
  let data = JSON.parse(localStorage.getItem("user"));
  let role = data.data.roles[0];
  return (
    <div>
      <Navbarside />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              {role === "ROLE_TRAVELAGENT" && (
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                Total Booking
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="icon-cup font-large-1 primary"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart"
                          className="height-75 lineChartWidget WidgetlineChart mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">Total Sales</span>
                            </div>
                            <div className="media-right text-right">
                              <i className="icon-wallet font-large-1 warning"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart1"
                          className="height-75 lineChartWidget WidgetlineChart1 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                Total Payable
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="icon-basket-loaded font-large-1 success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-75 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">Total Paid</span>
                            </div>
                            <div className="media-right text-right">
                              <i className="icon-basket-loaded font-large-1 success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-75 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {role === "ROLE_HOTELADMIN" && (
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                Total Booking
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="icon-cup font-large-1 primary"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart"
                          className="height-75 lineChartWidget WidgetlineChart mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">Total Sales</span>
                            </div>
                            <div className="media-right text-right">
                              <i className="icon-wallet font-large-1 warning"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart1"
                          className="height-75 lineChartWidget WidgetlineChart1 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                Total Payable
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="icon-basket-loaded font-large-1 success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-75 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">Total Paid</span>
                            </div>
                            <div className="media-right text-right">
                              <i className="icon-basket-loaded font-large-1 success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-75 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {role === "ROLE_TRAVEL_ADMIN" && (
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                Agency Booking
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="icon-cup font-large-1 primary"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart"
                          className="height-75 lineChartWidget WidgetlineChart mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                Hotel Booking
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="icon-wallet font-large-1 warning"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart1"
                          className="height-75 lineChartWidget WidgetlineChart1 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">B2C Booking</span>
                            </div>
                            <div className="media-right text-right">
                              <i className="icon-basket-loaded font-large-1 success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-75 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Home;
