import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import Navbarside from "./Navbarside";
import { Link } from "react-router-dom";
import { GetHotels, enableHotel } from "../store/slices/Hotels";
import Footer from "./footer";

const HotelList = () => {
  const [expertises, setexpertise] = useState([]);
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(GetHotels())
      .unwrap()
      .then((data) => {
        setexpertise(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  async function enableApprove(id) {
    let isApproved = true;
    let item = { set: { isApproved } };
    localStorage.setItem("item", JSON.stringify(item));
    localStorage.setItem("id", id);

    dispatch(enableHotel())
      .unwrap()
      .then(() => {
        // alert("Uploaded succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
    console.log(item);
    console.log(id);
  }

  async function disableApprove(id) {
    let isApproved = false;
    let item = { set: { isApproved } };
    localStorage.setItem("item", JSON.stringify(item));
    localStorage.setItem("id", id);

    dispatch(enableHotel())
      .unwrap()
      .then(() => {
        // alert("Uploaded succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
    console.log(item);
    console.log(id);
  }
  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success">
                        <h4 className="card-title">
                          Hotels &nbsp; &nbsp;
                          {/* <Link to="/Addhotels" className="btn btn-primary">
                            <i className="fa fa-plus"></i> Add
                          </Link> */}
                        </h4>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Hotel Name</th>
                              <th>Address</th>
                              <th>Added On</th>
                              <th>Status</th>
                              <th>Update Charges</th>
                            </tr>
                          </thead>

                          {loading ? (
                            <Loader active inline />
                          ) : (
                            <>
                              <tbody>
                                {expertises.map((hotelsVal, index) => (
                                  <tr key={index}>
                                    <td>{hotelsVal.name}</td>
                                    <td>
                                      {hotelsVal.address.buildingName},{" "}
                                      {hotelsVal.address.street},{" "}
                                      {hotelsVal.address.buildingName},
                                      {hotelsVal.address.city.name},
                                      {hotelsVal.address.state.name}-{" "}
                                      {hotelsVal.address.zipCode}
                                    </td>
                                    <td>{hotelsVal.created_at}</td>
                                    <td>
                                      {hotelsVal.isApproved === false && (
                                        <button
                                          className="btn btn-danger"
                                          style={{
                                            height: "25px",
                                            padding: "0 10px",
                                          }}
                                          onClick={() =>
                                            enableApprove(hotelsVal._id)
                                          }
                                        >
                                          Pending
                                        </button>
                                      )}
                                      {hotelsVal.isApproved === true && (
                                        <button
                                          className="btn btn-success"
                                          style={{
                                            height: "25px",
                                            padding: "0 10px",
                                          }}
                                          onClick={() =>
                                            disableApprove(hotelsVal._id)
                                          }
                                        >
                                          Approved
                                        </button>
                                      )}
                                    </td>
                                    <td>
                                      <Link
                                        style={{
                                          height: "25px",
                                          padding: "0 10px",
                                        }}
                                        className="btn btn-primary"
                                        to={`/UpdateHotelCharges/${
                                          hotelsVal._id ? hotelsVal._id : null
                                        }`}
                                      >
                                        Update Charges
                                      </Link>
                                    </td>
                                    {/* <td>
                                      {" "}
                                      <Link
                                        to={`/AddRoom/${
                                          hotelsVal._id ? hotelsVal._id : null
                                        }`}
                                      >
                                        View Rooms
                                      </Link>
                                    </td> */}
                                  </tr>
                                ))}
                              </tbody>
                            </>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default HotelList;
