import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import RoomCategoryService from "../../services/roomcategory.service";
import AuthService from "../../services/auth.service";

export const roomcategory = createAsyncThunk(
    "roomcategory/get/roomcategory",
    async(thunkAPI) => {
        try {
            const data = await RoomCategoryService.getroomCategory();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
)


export const postroomcategorys = createAsyncThunk(
    "roomcategory/post/roomcategory",
    async(item, thunkAPI) => {
        try {
            const data = await RoomCategoryService.postroomCategory(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
    loading: false,
    error: "",
    user: AuthService.getUserDetails() || null,
    isLoggedIn: false,
};

const roomcategorySlice = createSlice({
    name: "roomcategory",
    initialState,
    extraReducers: {
        [roomcategory.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [roomcategory.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [roomcategory.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },
        [postroomcategorys.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [postroomcategorys.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [postroomcategorys.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },
        // [logout.fulfilled]: (state, action) => {
        //   state.isLoggedIn = false;
        //   state.user = null;
        // },
    },
});

const { reducer } = roomcategorySlice;
export default reducer;