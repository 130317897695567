import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import axios from "axios";
import { Link } from "react-router-dom";
import Navbarside from "../component/Navbarside";
import { API_PATHS } from "../utils/constants/api.constants";
import { agency, approveagency } from "../store/slices/agency";
import { ExportToExcel } from "./ExportToExcel";
import Footer from "./footer";
function TravelAgency() {
  const [expertises, setexpertise] = useState([]);
  const [data, setData] = React.useState([]);
  const fileName = "TravelAgent";
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  React.useEffect(() => {
    const fetchData = () => {
      axios.get(API_PATHS.getAgency).then((postData) => {
        // reshaping the array
        const customHeadings = postData.data.data.map((item) => ({
          "Agency Name": item.agencyName,
          "Agency Email": item.email,
          "Agency Mobile": item.mobileNo,
        }));

        setData(customHeadings);
      });
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    dispatch(agency())
      .unwrap()
      .then((data) => {
        setexpertise(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  async function enableApprove(id) {
    let agencyId = id;
    let item = { agencyId };
    localStorage.setItem("item", JSON.stringify(item));

    dispatch(approveagency())
      .unwrap()
      .then(() => {
        // alert("Uploaded succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  async function disableApprove(id) {
    let isApproved = false;
    let item = { set: { isApproved } };
    localStorage.setItem("item", JSON.stringify(item));
    localStorage.setItem("id", id);

    dispatch(approveagency())
      .unwrap()
      .then(() => {
        // alert("Uploaded succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
    console.log(item);
    console.log(id);
  }
  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title-wrap bar-warning">
                          <h4
                            className="card-title"
                            id="basic-layout-form-center"
                          >
                            Travel Agency&nbsp; &nbsp;
                            <ExportToExcel apiData={data} fileName={fileName} />
                          </h4>
                        </div>
                      </div>
                      <div className="card-body collapse show">
                        <div className="card-block card-dashboard table-responsive">
                          <table className="table table-striped table-bordered zero-configuration">
                            <thead>
                              <tr>
                                <th>Agency Name</th>
                                <th>Mobile Number</th>
                                <th>Email</th>
                                <th>Website</th>
                                <th>Is Active</th>
                                <th>Is Approved</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            {loading ? (
                              <Loader active inline />
                            ) : (
                              <>
                                <tbody>
                                  {expertises.map((agencyVal, index) => (
                                    <tr key={index}>
                                      <td>
                                        <Link
                                          to={`/Traveldetails/${
                                            agencyVal._id ? agencyVal._id : null
                                          }`}
                                        >
                                          {agencyVal.name}
                                        </Link>
                                      </td>
                                      <td>{agencyVal.mobileNo}</td>
                                      <td>{agencyVal.email}</td>
                                      <td>{agencyVal.webSite}</td>
                                      <td align="center">
                                        {agencyVal.isActive === false && (
                                          <button
                                            className="btn btn-danger"
                                            style={{
                                              height: "25px",
                                              padding: "0 10px",
                                            }}
                                            onClick={() =>
                                              enableApprove(agencyVal._id)
                                            }
                                          >
                                            No
                                          </button>
                                        )}
                                        {agencyVal.isActive === true && (
                                          <button
                                            className="btn btn-success"
                                            style={{
                                              height: "25px",
                                              padding: "0 10px",
                                            }}
                                            onClick={() =>
                                              disableApprove(agencyVal._id)
                                            }
                                          >
                                            Yes
                                          </button>
                                        )}
                                      </td>
                                      <td align="center">
                                        {agencyVal.isApproved === false && (
                                          <button
                                            className="btn btn-danger"
                                            style={{
                                              height: "25px",
                                              padding: "0 10px",
                                            }}
                                            onClick={() =>
                                              enableApprove(agencyVal._id)
                                            }
                                          >
                                            No
                                          </button>
                                        )}
                                        {agencyVal.isApproved === true && (
                                          <button
                                            className="btn btn-success"
                                            style={{
                                              height: "25px",
                                              padding: "0 10px",
                                            }}
                                            onClick={() =>
                                              disableApprove(agencyVal._id)
                                            }
                                          >
                                            Yes
                                          </button>
                                        )}
                                      </td>
                                      <td>
                                        <Link
                                          style={{
                                            height: "25px",
                                            padding: "0 10px",
                                          }}
                                          className="btn btn-primary"
                                          to={`/UpdateCharges/${
                                            agencyVal._id ? agencyVal._id : null
                                          }`}
                                        >
                                          Update Charges
                                        </Link>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </body>
    </div>
  );
}
export default TravelAgency;
