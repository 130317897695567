import axios from "axios";
import {  API_PATHS } from "../utils/constants/api.constants";

class PackagesService {
  static getTravelPackage() {
    let api = '';
    let user = JSON.parse(localStorage.getItem("user"));
    let id = localStorage.getItem("id");
    let role = user.data.roles[0];
    if(role === 'ROLE_TRAVEL_ADMIN'){
      api = id ? API_PATHS.getTravelPackage + '?_id=' + id : API_PATHS.getTravelPackage;
    }else if(role === 'ROLE_TRAVELAGENT'){
      api = id ? API_PATHS.getTravelPackage + '?_id=' + id + '&agentId=' + user.data.id: API_PATHS.getTravelPackage + '?agentId=' + user.data.id;
    }
    localStorage.removeItem("id");
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
       
        }
        return response.data;
      });
  }

  static packageDetails() {
    let api = '';
    let id = localStorage.getItem("id");
      api =API_PATHS.getTravelPackage + '?_id=' + id;
    localStorage.removeItem("id");
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
       
        }
        return response.data;
      });
  }

  static uploadPackage(item) {
    return axios
      .post(API_PATHS.uploadPackages, 
       item
      )
      .then((response) => {
        if (response.data) {
         
        }
        return response.data;
      });
  }

  static editPackage(item) {
    let api = '';
    let id = localStorage.getItem("id");
    api = API_PATHS.editPackages + '?id=' + id;
    
    localStorage.removeItem("id");
    return axios
      .put(api,item)
      .then((response) => {
        if (response.data) {
        
        }
        return response.data;
      });
  }

  static enablePackage() {
    let api='';
    let item = JSON.parse(localStorage.getItem("item"));
    let id = localStorage.getItem("id");
    console.log(item)
    api = API_PATHS.editPackages + '?id=' + id;
    return axios
      .put(api,item)
      .then((response) => {
        if (response.data) {
        
        }
        return response.data;
      });
  }


  static removeUserDetails() {
    localStorage.removeItem("user");
  }

  static getUserDetails() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default PackagesService;
