import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { uploadAmenitys } from "../store/slices/amenity";
import { Loader } from "semantic-ui-react";
import Footer from "./footer";
import Navbarside from "../component/Navbarside";

function UploadAmenity(e) {
  const [name, setName] = useState("");
  const [alertamenity, setAlertamenity] = useState("");

  const navigate = useNavigate("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const save = (e) => {
    const nameReg = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (nameReg.test(name)) {
      setAlertamenity("");
    } else if (!nameReg.test(name) && name === "") {
      setAlertamenity("Please enter the aminity name");
      e.preventDefault("");
    } else {
      setAlertamenity("");
      
    }
  };

  const clear = () => {
    navigate(-1);

  };

  async function upload(e) {
    e.preventDefault();
    let item = {
      name,
    };

    dispatch(uploadAmenitys(item))
      .unwrap()
      .then(() => {
        alert("Uploaded successfully");
        navigate("/Amenity");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }
  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Add Amenity
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <form className="form" onSubmit={upload}>
                          <div className="row ">
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Amenity Name
                                  </label>
                                  <textarea
                                    type="text"
                                    className="form-control"
                                    Placeholder="Amenity Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                  <div className="alert-message">
                                    {alertamenity}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {loading ? (
                            <Loader active inline />
                          ) : (
                            <div>
                              <div className="form-actions center">
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                  onClick={save}
                                >
                                  <i className="icon-note"></i> Save
                                </button>
                                &nbsp;
                                <button
                                  type="reset"
                                  className="btn btn-danger mr-1"
                                  onClick={clear}
                                >
                                  <i className="icon-trash"></i> Cancel
                                </button>
                              </div>
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
}
export default UploadAmenity;
