import React, { useState } from "react";
import Navbarside from "../component/Navbarside";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { uploadPackages } from "./../store/slices/packages";
import CategoryService from "../services/category.service";
import { useNavigate } from "react-router-dom";
import { citys } from "../store/slices/city";
import { API_PATHS } from "../utils/constants/api.constants";
import { themes } from "../store/slices/theme";
import { Multiselect } from "multiselect-react-dropdown";
import Footer from "./footer";

function Addpackage(e) {
  const [message, setMessage] = useState("");
  const [options, setOptions] = useState([]);
  const navigate = useNavigate("");
  const isActive = "true";
  const [categoryId, setcategoryId] = useState("");
  const [cityId, setcityId] = useState("");
  const [city, setCity] = useState("");
  const [packageName, setpackageName] = useState("");
  const [description, setDescription] = useState("");
  const [duration, setDuration] = useState("");
  const [ratings, setRatings] = useState("");
  const [departureLocation, setdepartureLocation] = useState("");
  const [inclusive, setInclusive] = useState("");
  const [exclusive, setExclusive] = useState("");
  const [termsCondition, settermsCondition] = useState("");
  const [importantNotes, setimportantNotes] = useState("");
  const [bookingProcedure, setbookingProcedure] = useState("");
  const [pricing, setPricing] = useState("");
  const [twinShareRate, settwinShareRate] = useState("");
  const [oddPersonPercent, setoddPersonPercent] = useState("");
  const [singlePaxRatePercent, setsinglePaxRatePercent] = useState("");
  const [pricePerChildrenPercent, setpricePerChildrenPercent] = useState("");
  const [minChildAge, setminChildAge] = useState("");

  const [alertcategoryid, setAlertcategoryid] = useState("");

  const [imageUrl, setimageUrl] = useState([
    {
      mediaLink: "",
    },
  ]);
  const [travelThemes, settravelThemes] = useState([
    {
      name: "",
      themeId: "",
    },
  ]);
  const [tourIternary, settourIternary] = useState([
    { day: "", description: "", meal: "" },
  ]);
  const [categories, setCategories] = useState([]);
  const [cities, setCities] = useState([]);
  const [hotelDescription, sethotelDescription] = useState([
    {
      destination: "",
      HotelName: "",
      address: "",
      duration: "",
    },
  ]);
  let isSpecial = false;
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // alert-message
  const [alertpackageName, setAlertpackageName] = useState("");
  const [alertdescription, setAlertdescription] = useState("");
  const [alertduration, setAlertduration] = useState("");
  const [alertratings, setAlertratings] = useState("");
  const [alertdepartureLocation, setAlertdepartureLocation] = useState("");
  const [alertinclusive, setAlertinclusive] = useState("");
  const [alertexclusive, setAlertexclusive] = useState("");
  const [alerttermsCondition, setAlerttermsCondition] = useState("");
  const [alertimportantNotes, setAlertimportantNotes] = useState("");
  const [alertbookingProcedure, setAlertbookingProcedure] = useState("");
  const [alertpricing, setAlertpricing] = useState("");
  const [alerttwinShareRate, setAlerttwinShareRate] = useState("");
  const [alertoddPersonPercent, setAlertoddPersonPercent] = useState("");
  const [alertsinglePaxRatePercent, setAlertsinglePaxRatePercent] =
    useState("");
  const [alertpricePerChildrenPercent, setAlertpricePerChildrenPercent] =
    useState("");
  const [alertminChildAge, setAlertminChildAge] = useState("");
  const [alertimageUrl, setAlertimageUrl] = useState("");

  // -------------------

  const save = (e) => {
    if (packageName === "") {
      setAlertpackageName("Please enter the package name");
      e.preventDefault();
    } else {
      setAlertpackageName("");
    }

    if (description === "") {
      setAlertdescription("Please enter the description");
      e.preventDefault();
    } else {
      setAlertdescription("");
    }

    //   const regtext3d = /^[0-9][0-9]?$|^100$/;
    //   if (regtext3d.test(duration)) {
    //     setAlertduration("");
    //   } else if (!regtext3d.test(duration) && duration === "") {
    //     setAlertduration("Please enter the duration");
    //     e.preventDefault();
    //   } else {
    //     setAlertduration("");
    //   }

    //   const regtext3r = /^[0-9][0-9]?$|^100$/;
    //   if (regtext3r.test(ratings)) {
    //     setAlertratings("");
    //   } else if (!regtext3r.test(ratings) && ratings === "") {
    //     setAlertratings("Please enter the ratings");
    //     e.preventDefault();
    //   } else {
    //     setAlertratings("");
    //   }

    if (inclusive === "") {
      setAlertinclusive("Please enter the inclusive");
      e.preventDefault();
    } else {
      setAlertinclusive("");
    }

    if (exclusive === "") {
      setAlertexclusive("Please enter the exclusive");
      e.preventDefault();
    } else {
      setAlertexclusive("");
    }

    if (termsCondition === "") {
      setAlerttermsCondition("Please enter the terms & Condition");
      e.preventDefault();
    } else {
      setAlerttermsCondition("");
    }

    if (importantNotes === "") {
      setAlertimportantNotes("Please enter the important notes");
      e.preventDefault();
    } else {
      setAlertimportantNotes("");
    }

    if (bookingProcedure === "") {
      setAlertbookingProcedure("Please enter cancellation policy");
      e.preventDefault();
    } else {
      setAlertbookingProcedure("");
    }

    const regtextactual = /^[0-9][0-9]?$|^100$/;
    if (regtextactual.test(pricing)) {
      setAlertpricing("");
    } else if (!regtextactual.test(pricing) && pricing === "") {
      setAlertpricing("Please enter actual price");
      e.preventDefault();
    } else {
      setAlertpricing("");
    }

    const regtexttwin = /^[0-9][0-9]?$|^100$/;
    if (regtexttwin.test(twinShareRate)) {
      setAlerttwinShareRate("");
    } else if (!regtexttwin.test(twinShareRate) && twinShareRate === "") {
      setAlerttwinShareRate("Please enter twin sharing price");
      e.preventDefault();
    } else {
      setAlerttwinShareRate("");
    }

    const regtextodd = /^[0-9][0-9]?$|^100$/;
    if (regtextodd.test(oddPersonPercent)) {
      setAlertoddPersonPercent("");
    } else if (!regtextodd.test(oddPersonPercent) && oddPersonPercent === "") {
      setAlertoddPersonPercent("Please enter odd person percent");
      e.preventDefault();
    } else {
      setAlertoddPersonPercent("");
    }

    const regtextsingle = /^[0-9][0-9]?$|^100$/;
    if (regtextsingle.test(singlePaxRatePercent)) {
      setAlertsinglePaxRatePercent("");
    } else if (
      !regtextsingle.test(singlePaxRatePercent) &&
      singlePaxRatePercent === ""
    ) {
      setAlertsinglePaxRatePercent("Please enter single person percent");
      e.preventDefault();
    } else {
      setAlertsinglePaxRatePercent("");
    }

    //   const regtextpricechild = /^[0-9][0-9]?$|^100$/;
    //   if (regtextpricechild.test(pricePerChildrenPercent)) {
    //     setAlertpricePerChildrenPercent("");
    //   } else if (
    //     !regtextpricechild.test(pricePerChildrenPercent) &&
    //     pricePerChildrenPercent === ""
    //   ) {
    //     setAlertpricePerChildrenPercent("Please enter child per percentage");
    //     e.preventDefault();
    //   } else {
    //     setAlertpricePerChildrenPercent("");
    //   }

    //   const regtextdep = /^[0-9][0-9]?$|^100$/;
    //   if (regtextdep.test(departureLocation)) {
    //     setAlertdepartureLocation("");
    //   } else if (
    //     !regtextdep.test(departureLocation) &&
    //     departureLocation === ""
    //   ) {
    //     setAlertdepartureLocation("Please enter departure location");
    //     e.preventDefault();
    //   } else {
    //     setAlertdepartureLocation("");
    //   }

    //   const regtxtchildage = /^[0-9][0-9]?$|^100$/;
    //   if (regtxtchildage.test(minChildAge)) {
    //     setAlertminChildAge("");
    //   } else if (!regtxtchildage.test(minChildAge) && minChildAge === "") {
    //     setAlertminChildAge("Please enter min child age");
    //     e.preventDefault();
    //   } else {
    //     setAlertminChildAge("");
    //   }
  };

  const clear = () => {
    navigate(-1);
  };

  React.useEffect(() => {
    dispatch(category())
      .unwrap()
      .then((data) => {
        setCategories(data.user.data);
        setcategoryId(data.user.data[0]._id);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(themes())
      .unwrap()
      .then((data) => {
        // setTheme(data.user.data);
        let option = [];
        for (let i = 0; i < data.user.data.length; i++) {
          option.push({
            name: data.user.data[i].name,
            id: data.user.data[i]._id,
          });
        }

        setOptions(option);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  const category = createAsyncThunk(
    "category/get/travelCategory",
    async (thunkAPI) => {
      try {
        const data = await CategoryService.getCategory();
        return { user: data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  React.useEffect(() => {
    dispatch(citys())
      .unwrap()
      .then((data) => {
        setCities(data.user.data);
        setcityId(data.user.data[0]._id);
        setCity(data.user.data[0].name);
        // console.log("data", data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  const handleAddForms = () => {
    let iternary = [...tourIternary];
    iternary.push({ day: "", description: "", meal: "" });
    settourIternary(iternary);
  };

  const handleDeleteForms = (e) => {
    if (tourIternary.length > 1) {
      let iternary = [...tourIternary];
      iternary.pop();
      settourIternary(iternary);
    }
  };
  const handleRowChange = (e, index) => {
    const iternary = [...tourIternary];
    iternary[index][e.currentTarget.name] = e.currentTarget.value;
    settourIternary(iternary);
    // console.log(tourIternary);
  };

  const handleAddForms1 = () => {
    let hotel = [...hotelDescription];
    hotel.push({
      destination: "",
      HotelName: "",
      address: "",
      capacityPerRoom: "",
    });
    sethotelDescription(hotel);
  };

  const handleDeleteForms1 = (e) => {
    if (hotelDescription.length > 1) {
      let hotel = [...hotelDescription];
      hotel.pop();
      sethotelDescription(hotel);
    }
  };
  const handleRowChange1 = (e, index) => {
    const hotel = [...hotelDescription];
    hotel[index][e.currentTarget.name] = e.currentTarget.value;
    sethotelDescription(hotel);
    // console.log(hotelDescription);
  };

  async function imageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    const files = [];
    files.push(...e.target.files);
    files.forEach((file) => formdata.append("file", file));
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.multiUploadImage, requestOptions);
    let data = await response.json();
    let imgurl = [];
    for (let i = 0; i < data.data.length; i++) {
      imgurl.push({
        mediaLink: data.data[i],
      });
    }
    setimageUrl(imgurl);
    setMessage(data.message);
  }

  function onSelect(selectedList, selectedItem) {
    settravelThemes(selectedList);
  }

  function onRemove(selectedList, removedItem) {
    settravelThemes(selectedList);
  }
  async function Upload(e) {
    let agentId = JSON.parse(localStorage.getItem("user")).data.id;
    e.preventDefault();
    let item = {
      categoryId,
      cityId,
      city,
      travelThemes,
      agentId,
      packageName,
      description,
      duration,
      ratings,
      departureLocation,
      inclusive,
      exclusive,
      tourIternary,
      termsCondition,
      importantNotes,
      imageUrl,
      bookingProcedure,
      pricing,
      isActive,
      twinShareRate,
      oddPersonPercent,
      singlePaxRatePercent,
      pricePerChildrenPercent,
      minChildAge,
      hotelDescription,
      isSpecial,
    };

    dispatch(uploadPackages(item))
      .unwrap()
      .then(() => {
        alert("Package uploaded successfully");
        navigate("/TravelPackage");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const handleCategoryChange = (e) => {
    var value = categories.filter(function (category) {
      return category.categoryName == e.target.value;
    });
    setcategoryId(value[0]._id);
  };

  const handleCityChange = (e) => {
    var value = cities.filter(function (citys) {
      return citys.name == e.target.value;
    });
    setcityId(value[0]._id);
    setCity(value[0].name);
  };

  return (
    <div>
      <Navbarside />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Add Package
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <form onSubmit={Upload}>
                          <div className="row justify-content-md-center">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="basicSelect">Select Category</label>
                                <select
                                  className="form-control"
                                  onChange={handleCategoryChange}
                                  required
                                >
                                  <option value="">Select</option>
                                  {categories.map((option) => (
                                    <option value={option.categoryId}>
                                      {option.categoryName}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="basicSelect">Select City</label>
                                <select
                                  className="form-control"
                                  onChange={handleCityChange}
                                  required
                                >
                                  <option value="">Select</option>
                                  {cities.map((option) => (
                                    <option value={option.cityId}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="eventInput2">Theme</label>
                                <Multiselect
                                  options={options} // Options to display in the dropdown
                                  // selectedValues={travelThemes}
                                  displayValue="name"
                                  onSelect={onSelect} // Function will trigger on select event
                                  onRemove={onRemove} // Function will trigger on remove event
                                  onChange={settravelThemes}
                                  // onChange={handleThemeChange}
                                  required
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="eventInput2">Package Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="packageName"
                                  onChange={(e) =>
                                    setpackageName(e.target.value)
                                  }
                                  id="inputpackageName"
                                  value={packageName}
                                  required
                                />
                                <p className="alert-message">
                                  {alertpackageName}
                                </p>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="eventInput4">Duration</label>
                                <input
                                  type="text"
                                  name="duration"
                                  onChange={(e) => setDuration(e.target.value)}
                                  id="inputduration"
                                  value={duration}
                                  className="form-control"
                                  required
                                />
                                <p className="alert-message">{alertduration}</p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="eventInput5">Ratings</label>
                                <input
                                  type="number"
                                  name="ratings"
                                  min="1"
                                  onChange={(e) => setRatings(e.target.value)}
                                  id="inputratings"
                                  value={ratings}
                                  className="form-control"
                                  required
                                />
                                <p className="alert-message">{alertratings}</p>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="form-group">
                                <fieldset className="form-group">
                                  <label for="placeTextarea">Inclusive</label>
                                  <textarea
                                    className="form-control"
                                    name="inclusive"
                                    onChange={(e) =>
                                      setInclusive(e.target.value)
                                    }
                                    id="inputinclusive"
                                    value={inclusive}
                                    rows="3"
                                    required
                                  ></textarea>
                                  <p className="alert-message">
                                    {alertinclusive}
                                  </p>
                                </fieldset>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <fieldset className="form-group">
                                  <label for="placeTextarea">Exclusive</label>
                                  <textarea
                                    className="form-control"
                                    name="exclusive"
                                    onChange={(e) =>
                                      setExclusive(e.target.value)
                                    }
                                    id="inputexclusive"
                                    value={exclusive}
                                    required
                                    rows="3"
                                  ></textarea>
                                  <p className="alert-message">
                                    {alertexclusive}
                                  </p>
                                </fieldset>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <fieldset className="form-group">
                                  <label for="placeTextarea">
                                    Terms & Condition
                                  </label>
                                  <textarea
                                    className="form-control"
                                    name="termsCondition"
                                    onChange={(e) =>
                                      settermsCondition(e.target.value)
                                    }
                                    id="inputtermsCondition"
                                    value={termsCondition}
                                    required
                                    rows="3"
                                  ></textarea>
                                  <p className="alert-message">
                                    {alerttermsCondition}
                                  </p>
                                </fieldset>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <fieldset className="form-group">
                                  <label for="placeTextarea">
                                    Important Notes
                                  </label>
                                  <textarea
                                    className="form-control"
                                    name="importantNotes"
                                    onChange={(e) =>
                                      setimportantNotes(e.target.value)
                                    }
                                    id="inputimportNotes"
                                    value={importantNotes}
                                    required
                                    rows="3"
                                  ></textarea>
                                  <p className="alert-message">
                                    {alertimportantNotes}
                                  </p>
                                </fieldset>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="form-group">
                                <fieldset className="form-group">
                                  <label for="placeTextarea">
                                    Cancellation Policy
                                  </label>
                                  <textarea
                                    className="form-control"
                                    name="bookingProcedure"
                                    onChange={(e) =>
                                      setbookingProcedure(e.target.value)
                                    }
                                    id="inputbookingProcedure"
                                    value={bookingProcedure}
                                    rows="3"
                                    required
                                  ></textarea>
                                  <p className="alert-message">
                                    {alertbookingProcedure}
                                  </p>
                                </fieldset>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <fieldset className="form-group">
                                  <label for="placeTextarea">Description</label>
                                  <textarea
                                    className="form-control"
                                    name="description"
                                    onChange={(e) =>
                                      setDescription(e.target.value)
                                    }
                                    id="inputdescription"
                                    value={description}
                                    required
                                    rows="3"
                                  ></textarea>
                                  <p className="alert-message">
                                    {alertdescription}
                                  </p>
                                </fieldset>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <fieldset className="form-group">
                                  <label for="placeTextarea">
                                    Actual Price
                                  </label>
                                  <input
                                    type="number"
                                    min="1"
                                    className="form-control"
                                    name="pricing"
                                    onChange={(e) => setPricing(e.target.value)}
                                    id="inputtourIternary"
                                    value={pricing}
                                    required
                                  />
                                  <p className="alert-message">
                                    {alertpricing}
                                  </p>
                                </fieldset>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <fieldset className="form-group">
                                  <label for="placeTextarea">
                                    Price Twin Sharing
                                  </label>
                                  <input
                                    type="number"
                                    min="1"
                                    className="form-control"
                                    name="twinShareRate"
                                    onChange={(e) =>
                                      settwinShareRate(e.target.value)
                                    }
                                    id="inputtwinShareRate"
                                    value={twinShareRate}
                                    required
                                  />
                                  <p className="alert-message">
                                    {alerttwinShareRate}
                                  </p>
                                </fieldset>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="eventInput5">
                                  Odd Person Percentage
                                </label>
                                <input
                                  type="number"
                                  min="1"
                                  name="oddPersonPercent"
                                  onChange={(e) =>
                                    setoddPersonPercent(e.target.value)
                                  }
                                  id="inputdepartureLocation"
                                  value={oddPersonPercent}
                                  className="form-control"
                                  required
                                />
                                <p className="alert-message">
                                  {alertoddPersonPercent}
                                </p>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="eventInput5">
                                  Single Person Percentage
                                </label>
                                <input
                                  type="number"
                                  min="1"
                                  name="departureLocation"
                                  onChange={(e) =>
                                    setsinglePaxRatePercent(e.target.value)
                                  }
                                  id="inputdepartureLocation"
                                  value={singlePaxRatePercent}
                                  className="form-control"
                                  required
                                />
                                <p className="alert-message">
                                  {alertsinglePaxRatePercent}
                                </p>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="eventInput5">
                                  Price Per Child Percentage
                                </label>
                                <input
                                  type="number"
                                  min="1"
                                  name="departureLocation"
                                  onChange={(e) =>
                                    setpricePerChildrenPercent(e.target.value)
                                  }
                                  id="inputdepartureLocation"
                                  value={pricePerChildrenPercent}
                                  className="form-control"
                                  required
                                />
                                <p className="alert-message">
                                  {alertpricePerChildrenPercent}
                                </p>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="eventInput5">Min Child Age</label>
                                <input
                                  type="number"
                                  min="1"
                                  name="departureLocation"
                                  onChange={(e) =>
                                    setminChildAge(e.target.value)
                                  }
                                  id="inputdepartureLocation"
                                  value={minChildAge}
                                  className="form-control"
                                  required
                                />
                                <p className="alert-message">
                                  {alertminChildAge}
                                </p>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="eventInput5">
                                  Departure Location
                                </label>
                                <input
                                  type="text"
                                  name="departureLocation"
                                  onChange={(e) =>
                                    setdepartureLocation(e.target.value)
                                  }
                                  id="inputdepartureLocation"
                                  value={departureLocation}
                                  className="form-control"
                                  required
                                />
                                <p className="alert-message">
                                  {alertdepartureLocation}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6" />
                          </div>
                          <br />
                          <h3>Tour Images</h3>
                          <br />
                          <div className="row justify-content-md-center">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="eventInput5">
                                  Upload Images (Max 10 images and resolution
                                  1920X1080)
                                </label>
                                <input
                                  type="File"
                                  name="imageUrl"
                                  multiple
                                  onChange={(e) => imageUpload(e)}
                                  id="inputimageUrl"
                                  className="form-control-file"
                                  required
                                />
                                <br></br>
                                <p>{message}</p>
                              </div>
                            </div>

                            <div className="col-md-6"></div>
                          </div>
                          <br />

                          <h3>TOUR ITERNARY</h3>
                          <br />
                          <div className="row">
                            <div className="col-md-4"></div>
                            <div className="col-md-4" align="center">
                              <button
                                type="button"
                                className="btn btn-success"
                                onClick={(e) => handleAddForms(e)}
                              >
                                <i className="fa fa-plus-circle" />
                              </button>
                              &nbsp; &nbsp;
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={(e) => handleDeleteForms(e)}
                              >
                                <i className="fa fa-minus-circle" />
                              </button>
                            </div>
                          </div>
                          <br />
                          {tourIternary.map((itiernary, index) => (
                            <div key={index} className="form-group row">
                              <div className="col-md-3">
                                <label for="eventInput5">Day</label>
                                <input
                                  onChange={(e) => handleRowChange(e, index)}
                                  className="form-control"
                                  placeholder="Day"
                                  name="day"
                                  type="text"
                                  required
                                  value={itiernary.day}
                                />
                              </div>
                              <div className="col-md-6">
                                <label for="eventInput5">Iternary</label>
                                <textarea
                                  onChange={(e) => handleRowChange(e, index)}
                                  className="form-control"
                                  placeholder="Description"
                                  name="description"
                                  type="text"
                                  value={itiernary.description}
                                  required
                                />
                              </div>
                              <div className="col-md-3">
                                <label for="eventInput5">Meal Type</label>
                                <input
                                  onChange={(e) => handleRowChange(e, index)}
                                  className="form-control"
                                  placeholder="Meal Type"
                                  name="meal"
                                  type="text"
                                  value={itiernary.meal}
                                  required
                                />
                              </div>
                            </div>
                          ))}
                          <br />
                          <h2>Hotel Details</h2>
                          <br />
                          <div className="row">
                            <div className="col-md-4"></div>
                            <div className="col-md-4" align="center">
                              <button
                                type="button"
                                className="btn btn-success"
                                onClick={(e) => handleAddForms1(e)}
                              >
                                <i className="fa fa-plus-circle" />
                              </button>
                              &nbsp; &nbsp;
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={(e) => handleDeleteForms1(e)}
                              >
                                <i className="fa fa-minus-circle" />
                              </button>
                            </div>
                          </div>
                          <br />
                          {hotelDescription.map((hotel, index) => (
                            <div key={index} className="form-group row">
                              <div className="col-md-3">
                                <label for="eventInput5">Destination</label>
                                <input
                                  onChange={(e) => handleRowChange1(e, index)}
                                  className="form-control"
                                  placeholder="Destination"
                                  name="destination"
                                  type="text"
                                  value={hotel.destination}
                                  required
                                />
                              </div>
                              <div className="col-md-3">
                                <label for="eventInput5">Hotel Name</label>
                                <input
                                  onChange={(e) => handleRowChange1(e, index)}
                                  className="form-control"
                                  placeholder="Hotel Name"
                                  name="HotelName"
                                  type="text"
                                  value={hotel.HotelName}
                                  required
                                />
                              </div>
                              <div className="col-md-3">
                                <label for="eventInput5">Duration</label>
                                <input
                                  onChange={(e) => handleRowChange1(e, index)}
                                  className="form-control"
                                  placeholder="Duration"
                                  name="Duration"
                                  type="text"
                                  required
                                  value={hotel.Duration}
                                />
                              </div>
                              <div className="col-md-3">
                                <label for="eventInput5">
                                  Capacity Per Room
                                </label>
                                <input
                                  onChange={(e) => handleRowChange1(e, index)}
                                  className="form-control"
                                  placeholder="Capacity Per Room"
                                  name="capacityPerRoom"
                                  type="number"
                                  value={hotel.capacityPerRoom}
                                  required
                                />
                              </div>
                            </div>
                          ))}
                          {loading ? (
                            <Loader active inline />
                          ) : (
                            <>
                              <div className="form-actions center">
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                  // onClick={save}
                                  disabled={!message}
                                >
                                  <i className="icon-note"></i> Save
                                </button>
                                &nbsp;
                                <button
                                  type="reset"
                                  className="btn btn-danger mr-1"
                                  onClick={clear}
                                >
                                  <i className="icon-trash"></i> Cancel
                                </button>
                              </div>
                            </>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Addpackage;
