import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import Navbarside from "../component/Navbarside";
import { editCategory } from "./../store/slices/category";
import { category } from "./../store/slices/category";
import Footer from "./footer";

function Editcategory(e) {
  let createdBy = "Admin";
  const [categoryName, setcategoryName] = useState("");
  const [alertcategoryName, setAlertcategoryName] = useState("");

  const navigate = useNavigate("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { id } = useParams();
  localStorage.setItem("id", id);
  React.useEffect(() => {
    dispatch(category())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id == id) {
          setcategoryName(data.user.data[0].categoryName);
        }
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  async function upload(e) {
    e.preventDefault();
    let item = {
      set: { categoryName },
    };

    dispatch(editCategory(item))
      .unwrap()
      .then(() => {
        alert("Updated successfully");
        navigate("/travelCategory");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const save = (e) => {
    const regName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (regName.test(categoryName)) {
      setAlertcategoryName("");
    } else if (!regName.test(categoryName) && categoryName === "") {
      setAlertcategoryName("Please enter your name");
      e.preventDefault("");
    } else {
      setAlertcategoryName("");
    }
  };

  const clear = () => {
    navigate(-1);
  };

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Edit Category
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <form className="form" onSubmit={upload}>
                          <div className="row justify-content-md-center">
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Category Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="category Name"
                                    value={categoryName}
                                    onChange={(e) =>
                                      setcategoryName(e.target.value)
                                    }
                                  />
                                  <p className="alert-message">
                                    {alertcategoryName}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <input
                                    type="hidden"
                                    className="form-control"
                                    Placeholder="Created By"
                                    value={createdBy}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {loading ? (
                            <Loader active inline />
                          ) : (
                            <>
                              <div className="form-actions center">
                                <button
                                  type="submit"
                                  className="btn btn-blue"
                                  onClick={save}
                                >
                                  <i className="icon-note"></i> Save
                                </button>{" "}
                                &nbsp;
                                <button
                                  type="reset"
                                  className="btn btn-warning mr-1"
                                  onClick={clear}
                                >
                                  <i className="icon-trash"></i> Cancel
                                </button>
                              </div>
                            </>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default Editcategory;
