import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class CityService {
    static getCity() {
        let api = '';
        let id = localStorage.getItem("id");
          api = id ? API_PATHS.getCity + '?_id=' + id : API_PATHS.getCity;
        
        localStorage.removeItem("id");
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static uploadCity(item) {
        return axios
            .post(API_PATHS.uploadCity,
                item
            )
            .then((response) => {
                if (response.data) {
                    //localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static editCity(item) {
        let api = '';
        let id = localStorage.getItem("id");
        api = API_PATHS.editCity + '?id=' + id;
        localStorage.removeItem("id");
        return axios
          .put(api,item)
          .then((response) => {
            if (response.data) {
            
            }
            return response.data;
          });
      }




    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default CityService;
