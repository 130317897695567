import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postroomTypes } from "../store/slices/roomtype";
import { Loader } from "semantic-ui-react";
import Footer from "./footer";
import { API_PATHS } from "../utils/constants/api.constants";
import Navbarside from "../component/Navbarside";

function UploadRoomType(e) {
  const [hotelId, setHotelId] = useState("");
  const [roomCategoryId, setRoomCategoryId] = useState("");
  const [roomTypeId, setRoomTypeId] = useState("");
  const [bedTypeId, setBedTypeId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [totalRoomCount, setTotalRoomCount] = useState("");
  const [availableRoomCount, setAvailableRoomCount] = useState("");
  const [pricePerRoom, setPricePerRoom] = useState("");
  const [personPerPersonInRoom, setPersonPerPersonInRoom] = useState("");
  const [pricePerChildPercent, setPricePerChildPercent] = useState("");
  const [amenities, setAmenities] = useState([]);
  const [tags, setTags] = useState([]);
  const [imageUrl, setimageUrl] = useState("");

  const [status, setStatus] = useState("");

  const [alertname, setAlertname] = useState("");
  const [alertmedia, setAlertmedia] = useState("");

  const navigate = useNavigate("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  async function imageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setimageUrl(data.data.location);
  }

  async function upload(e) {
    e.preventDefault();
    let item = {
      hotelId,
      roomCategoryId,
      roomTypeId,
      bedTypeId,
      amenities,
      name,
      status,
      tags,
      description,
      totalRoomCount,
      availableRoomCount,
      pricePerRoom,
      personPerPersonInRoom,
      pricePerChildPercent,
    };

    dispatch(postroomTypes(item))
      .unwrap()
      .then(() => {
        alert("Uploaded successfully");
        navigate("/RoomType");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const save = (e) => {
    const regtext = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (regtext.test(name)) {
      setAlertname("");
    } else if (!regtext.test(name) && name === "") {
      setAlertname("Please enter room type");
      e.preventDefault();
    } else {
      setAlertname("Single and double quotes are not-valid");
      e.preventDefault();
    }

    const regimage = /(gif|jpe?g|tiff?|png|webp|bmp)$/i;
    if (regimage.test(imageUrl)) {
      setAlertmedia("");
    } else if (!regimage.test(imageUrl) && imageUrl === "") {
      setAlertmedia("Please enter image link");
      e.preventDefault();
    } else {
      setAlertmedia("Invalid file");
      e.preventDefault();
    }
  };

  const clear = () => {
    navigate(-1);
  };

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Upload Room Type
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <form className="form" onSubmit={upload}>
                          <div className="row ">
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Hotel</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Room Type Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    // required
                                  />
                                  <p className="alert-message">{alertname}</p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Room Category
                                  </label>
                                  <input
                                    type="file"
                                    className="form-control-file"
                                    Placeholder="Price Tagline"
                                    name="imageUrl"
                                    onChange={(e) => imageUpload(e)}
                                    required
                                  />
                                  <p className="alert-message">{alertmedia}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {loading ? (
                            <Loader active inline />
                          ) : (
                            <>
                              <div className="form-actions center">
                                <button
                                  type="submit"
                                  className="btn btn-blue"
                                  onClick={save}
                                  disabled={!imageUrl}
                                >
                                  <i className="icon-note"></i> Save
                                </button>
                                &nbsp;
                                <button
                                  type="reset"
                                  className="btn btn-warning mr-1"
                                  onClick={clear}
                                >
                                  <i className="icon-trash"></i> Cancel
                                </button>
                              </div>
                            </>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default UploadRoomType;
