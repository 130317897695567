import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import Navbarside from "./Navbarside";
import { Addquestion } from "../store/slices/questionnaire";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Footer from "./footer";

import { ellipsis } from "polished";

function AddQuestionnaire() {
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate("");

  const [question, setQuestion] = useState("");
  const [order, setOrder] = useState("");

  const [alertOrder, setAlertOrder] = useState("");
  const [alertQuestion, setAlertQuestion] = useState("");

  const [options, setOptions] = useState([
    { optionOrder: "", optionValue: "", points: "" },
  ]);

  async function upload(e) {
    e.preventDefault();

    let item = { question, order, options };
    dispatch(Addquestion(item))
      .unwrap()
      .then(() => {
        alert("Uploaded successfully");
        // navigate("/questionnaire");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const save = (e) => {
    const nameReg = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9]+[ ]?|[?])+)$/;
    if (nameReg.test(question)) {
      setAlertQuestion("");
    } else if (!nameReg.test(question) && question === "") {
      setAlertQuestion("Please enter your question");
      e.preventDefault("");
    } else {
      setAlertQuestion("");
    }

    const numberReg = /^[0-9][0-9]?$|^100$/;
    if (numberReg.test(question)) {
      setAlertOrder("");
    } else if (!numberReg.test(question) && question === "") {
      setAlertOrder("Please enter your question order");
      e.preventDefault("");
    } else {
      setAlertOrder("");
    }
  };

  const clear = () => {
    setQuestion("");
    setOrder("");
    setAlertOrder("");
    setAlertQuestion("");
  };

  const handleAddForms = () => {
    let questions = [...options];
    questions.push({ optionOrder: "", optionValue: "", points: "" });
    setOptions(questions);
  };

  const handleDeleteForms = (e) => {
    if (options.length > 1) {
      let questions = [...options];
      questions.pop();
      setOptions(questions);
    }
  };
  const handleRowChange = (e, index) => {
    const questions = [...options];
    questions[index][e.currentTarget.name] = e.currentTarget.value;
    setOptions(questions);
    // console.log(tourIternary);
  };

  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title-wrap bar-success">
                          <h4 className="card-title">
                            Questionnaire &nbsp; &nbsp;
                          </h4>
                        </div>
                      </div>

                      <section className="form-Questionnaire">
                        <form onSubmit={upload}>
                          <div className="row">
                            <div className="col-md-6 col-sm-12">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter your question"
                                onChange={(e) => setQuestion(e.target.value)}
                                value={question}
                              />
                              <p className="alert-message">{alertQuestion}</p>
                            </div>

                            <div className="col-md-6 col-sm-12">
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter your order"
                                onChange={(e) => setOrder(e.target.value)}
                                value={order}
                              />
                              <p className="alert-message">{alertOrder}</p>
                            </div>

                            <div className="col-12 pt-5">
                              <h5>Options</h5>
                            </div>

                            <div className="col-12 d-flex justify-content-center">
                              <button
                                type="button"
                                className="btn btn-success"
                                onClick={(e) => handleAddForms(e)}
                              >
                                <i className="fa fa-plus-circle" />
                              </button>
                              &nbsp; &nbsp;
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={(e) => handleDeleteForms(e)}
                              >
                                <i className="fa fa-minus-circle" />
                              </button>
                            </div>

                            {options.map((questions, index) => (
                              <div className="col-12">
                                <div key={index} className="row">
                                  <div className="col-md-4 col-sm-12 pt-2 pb-1">
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Enter your Option order"
                                      onChange={(e) =>
                                        handleRowChange(e, index)
                                      }
                                      value={questions.optionOrder}
                                      name="optionOrder"
                                      required
                                    />
                                  </div>

                                  <div className="col-md-4 col-sm-12 pt-2 pb-1">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter your Option value"
                                      onChange={(e) =>
                                        handleRowChange(e, index)
                                      }
                                      value={questions.optionValue}
                                      name="optionValue"
                                      required
                                    />
                                  </div>

                                  <div className="col-md-4 col-sm-12 pt-2 pb-1">
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Enter your points"
                                      onChange={(e) =>
                                        handleRowChange(e, index)
                                      }
                                      value={questions.points}
                                      name="points"
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>

                          <div className="col-12 d-flex justify-content-center">
                            <button
                              type="submit"
                              className="btn btn-warning col-white"
                              onClick={save}
                            >
                              Upload
                            </button>
                            &nbsp; &nbsp;
                            <button
                              type="reset"
                              className="btn btn-danger col-white"
                              onClick={clear}
                            >
                              Cancel
                            </button>
                          </div>
                        </form>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
}
export default AddQuestionnaire;
