import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class EnquiryService {
    static getEnquiry() {
        let api = '';
    let user = JSON.parse(localStorage.getItem("user"));
  
    let role = user.data.roles[0];
    if(role === 'ROLE_TRAVEL_ADMIN'){
        api = API_PATHS.getEnquiry + '?enquiryFor=travelPackage';
    }
        // localStorage.removeItem("id");
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static getagentEnquiry() {
        let api = '';
    let user = JSON.parse(localStorage.getItem("user"));
  
    let role = user.data.roles[0];
   
        api = API_PATHS.getEnquiry + '?enquiryFor=travelAgency';
    
        // localStorage.removeItem("id");
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    
    static editEnquiry(item) {
        let api = '';
        let id = localStorage.getItem("id");
        api = API_PATHS.editEnquiry + '?id=' + id;
        localStorage.removeItem("id");
        return axios
          .put(api,item)
          .then((response) => {
            if (response.data) {
            
            }
            return response.data;
          });
      }




    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default EnquiryService;
