import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../img/travelonify_logo.png";
import { forgotPass } from "../store/slices/auth";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import { VscEye } from "react-icons/vsc";

const ForgotPassword = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [mobileNo, setmobileNo] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [alertmobileNo, setAlertmobileNo] = useState("");
  const [alertpass, setAlertpass] = useState("");
  const [alertemail, setAlertemail] = useState("");

  const navigate = useNavigate("");
  const { loading, error, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  async function signIn(e) {
    e.preventDefault();
    dispatch(forgotPass({ mobileNo, email }))
      .unwrap()
      .then(() => {
        navigate("/");
      })
      .catch(({ message }) => {
        alert(message);
      });

    const regNum = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (regNum.test(mobileNo)) {
      setAlertmobileNo("");
    } else if (!regNum.test(mobileNo) && mobileNo === "") {
      setAlertmobileNo("Please enter your mobile number");
      e.preventDefault("");
    } else {
      setAlertmobileNo("Please enter a valid mobile number");
      e.preventDefault("");

    }

    const regemail =  /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (regemail.test(email)) {
      setAlertemail("");
    } else if (!regemail.test(email) && email === "") {
      setAlertemail("Please enter your email");
      e.preventDefault("");

    } else {
      setAlertemail("");
    }
  }

  return (
    <div>
    <section className="login-bg-image">
    <div className="bg-overlay-orange" />

    <div className="card-section">
<div className="d-flex justify-content-around align-items-center">

<img src={logo} alt="travel-logo" className="div-logo"/>

<div className="h-logn-line" />

<div className="login-form-2">
{/*error && <Alert variant="danger">{error}</Alert>*/}
<div className="d-flex justify-content-center">
<img src={logo} alt="logo" className="logo-form-2" />
</div>
<h2 className="text-center" style={{color:"black", fontWeight:"500"}}>Forgot Password ?</h2>
<br/>
<form onSubmit={signIn}>
  <input
  maxlength="10"
  onKeyPress={(event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  }}
    type="number"
    value={mobileNo}
    onChange={(e) => setmobileNo(e.target.value)}
    className="form-control text-black-ph"
    placeholder="Enter your mobile number"
    style={{ height: "50px"}}
  />
  <p className="alert-message">{alertmobileNo}</p>

  

  <div className="position-r">
    <input
      type="text"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      className="form-control text-black-ph"
      placeholder="Enter your email"
      style={{ height: "50px" }}
    />
  
  </div>
  <p className="alert-message">{alertemail}</p>

  <div className="col-md-12 d-flex justify-content-center mt-4">
    <button
      type="submit"
      className="btn btn-warning login-btn col-white"
      // onClick={save}
    >
      Submit
    </button>
  </div>

  <div className="d-flex justify-content-center align-items-center">
  <div className="line-h"/> 
  <p className="line-mid-text">or</p>
  <div className="line-h"/> 
  </div>

<p className="text-center pt-2">Return to <Link to="/" className="heightlight-black">Login</Link></p>

{/*  <hr/>
  <p className=" text-center login-text-2 pt-1 mb-3">
    Don't have an account?{" "}<br/>
    <div className="d-flex justify-content-center align-items-center">
    <Link to="/register">
    <b className="heightlight-black">Agent Registration</b>
  </Link>
  &nbsp;
  <div className="line-v"/>
  &nbsp;
  <Link to="/agencyregister">
    <b className="heightlight-black">Agency Registration</b>
  </Link>
    </div>
   
</p>

  <p className="text-center">
    <Link to="/ForgotPassword" className="heightlight-black text-center">
      Forgot password ?
    </Link>
  </p>*/}
</form>
</div>
</div>
</div>

 
  </section>
    </div>
  );
};

export default ForgotPassword;
