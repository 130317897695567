import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import { useNavigate, useParams } from "react-router-dom";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { packageDetails } from "./../store/slices/packages";
import CategoryService from "../services/category.service";
import CityService from "../services/city.service";
import { themes } from "../store/slices/theme";
import { editPackages } from "./../store/slices/packages";
import { API_PATHS } from "../utils/constants/api.constants";
import { Multiselect } from "multiselect-react-dropdown";
import Navbarside from "../component/Navbarside";
import Footer from "./footer";
function Editpackage() {
  const [message, setMessage] = useState("");
  const [options, setOptions] = useState([]);
  const navigate = useNavigate("");
  const [categoryId, setcategoryId] = useState("");
  const [cityId, setcityId] = useState("");
  const [city, setCity] = useState("");
  const [cityval, setCityval] = useState("");
  const [catName, setcatName] = useState("");
  const [packageName, setpackageName] = useState("");
  const [description, setDescription] = useState("");
  const [duration, setDuration] = useState("");
  const [ratings, setRatings] = useState("");
  const [departureLocation, setdepartureLocation] = useState("");
  const [inclusive, setInclusive] = useState("");
  const [exclusive, setExclusive] = useState("");
  const [termsCondition, settermsCondition] = useState("");
  const [importantNotes, setimportantNotes] = useState("");
  const [bookingProcedure, setbookingProcedure] = useState("");
  const [imageUrl, setimageUrl] = useState([
    {
      mediaLink: "",
    },
  ]);
  const [travelThemes, settravelThemes] = useState([
    {
      name: "",
      themeId: "",
    },
  ]);

  const [theme, setTheme] = useState([]);

  const [pricing, setPricing] = useState("");
  const [twinShareRate, settwinShareRate] = useState("");
  const [oddPersonPercent, setoddPersonPercent] = useState("");
  const [singlePaxRatePercent, setsinglePaxRatePercent] = useState("");
  const [pricePerChildrenPercent, setpricePerChildrenPercent] = useState("");
  const [minChildAge, setminChildAge] = useState("");
  const [tourIternary, settourIternary] = useState([
    { day: "", description: "", meal: "" },
  ]);
  const [hotelDescription, sethotelDescription] = useState([
    {
      destination: "",
      HotelName: "",
      address: "",
      duration: "",
    },
  ]);
  const [selected, setSelected] = useState([]);

  // alert-message
  const [alertpackageName, setAlertpackageName] = useState("");
  const [alertdescription, setAlertdescription] = useState("");
  const [alertduration, setAlertduration] = useState("");
  const [alertratings, setAlertratings] = useState("");
  const [alertdepartureLocation, setAlertdepartureLocation] = useState("");
  const [alertinclusive, setAlertinclusive] = useState("");
  const [alertexclusive, setAlertexclusive] = useState("");
  const [alerttermsCondition, setAlerttermsCondition] = useState("");
  const [alertimportantNotes, setAlertimportantNotes] = useState("");
  const [alertbookingProcedure, setAlertbookingProcedure] = useState("");
  const [alertpricing, setAlertpricing] = useState("");
  const [alerttwinShareRate, setAlerttwinShareRate] = useState("");
  const [alertoddPersonPercent, setAlertoddPersonPercent] = useState("");
  const [alertsinglePaxRatePercent, setAlertsinglePaxRatePercent] =
    useState("");
  const [alertpricePerChildrenPercent, setAlertpricePerChildrenPercent] =
    useState("");
  const [alertminChildAge, setAlertminChildAge] = useState("");
  const [alertimageUrl, setAlertimageUrl] = useState("");

  // -------------------

  const save = (e) => {
    if (packageName === "") {
      setAlertpackageName("Please enter packagename");
      e.preventDefault();
    } else {
      setAlertpackageName("");
    }

    //   if (description === "") {
    //     setAlertdescription("Please enter description");
    //     e.preventDefault();
    //   } else {
    //     setAlertdescription("");
    //   }

    //   const regtext3d = /^[0-9][0-9]?$|^100$/;
    //   if (regtext3d.test(duration)) {
    //     setAlertduration("");
    //   } else if (!regtext3d.test(duration) && duration === "") {
    //     setAlertduration("Please enter trip duration");
    //     e.preventDefault();
    //   } else {
    //     setAlertduration("");
    //   }

    const regtext3r = /^[0-9]?$|^10$/;
    if (regtext3r.test(ratings)) {
      setAlertratings("");
    } else if (!regtext3r.test(ratings) && ratings === "") {
      setAlertratings("Please enter ratings");
      e.preventDefault();
    } else {
      setAlertratings("");
    }

    if (inclusive === "") {
      setAlertinclusive("Please enter inclusive");
      e.preventDefault();
    } else {
      setAlertinclusive("");
    }

    if (exclusive === "") {
      setAlertexclusive("Please enter exclusive");
      e.preventDefault();
    } else {
      setAlertexclusive("");
    }

    if (termsCondition === "") {
      setAlerttermsCondition("Please enter terms & Condition");
      e.preventDefault();
    } else {
      setAlerttermsCondition("");
    }

    if (importantNotes === "") {
      setAlertimportantNotes("Please enter important notes");
      e.preventDefault();
    } else {
      setAlertimportantNotes("");
    }

    if (bookingProcedure === "") {
      setAlertbookingProcedure("Please enter cancellation policy");
      e.preventDefault();
    } else {
      setAlertbookingProcedure("");
    }

    if (pricing === "") {
      setAlertpricing("Please enter actual price");
      e.preventDefault();
    } else {
      setAlertpricing("");
    }

    if (twinShareRate === "") {
      setAlerttwinShareRate("Please enter twin sharing price");
      e.preventDefault();
    } else {
      setAlerttwinShareRate("");
    }

    //   const regtextodd = /^[0-9][0-9]?$|^100$/;
    //   if (regtextodd.test(oddPersonPercent)) {
    //     setAlertoddPersonPercent("");
    //   } else if (!regtextodd.test(oddPersonPercent) && oddPersonPercent === "") {
    //     setAlertoddPersonPercent("Please enter odd person percent");
    //     e.preventDefault();
    //   } else {
    //     setAlertoddPersonPercent("");
    //   }

    //   const regtextsingle = /^[0-9][0-9]?$|^100$/;
    //   if (regtextsingle.test(singlePaxRatePercent)) {
    //     setAlertsinglePaxRatePercent("");
    //   } else if (
    //     !regtextsingle.test(singlePaxRatePercent) &&
    //     singlePaxRatePercent === ""
    //   ) {
    //     setAlertsinglePaxRatePercent("Please enter single person percent");
    //     e.preventDefault();
    //   } else {
    //     setAlertsinglePaxRatePercent("");
    //   }

    //   const regtextpricechild = /^[0-9][0-9]?$|^100$/;
    //   if (regtextpricechild.test(pricePerChildrenPercent)) {
    //     setAlertpricePerChildrenPercent("");
    //   } else if (
    //     !regtextpricechild.test(pricePerChildrenPercent) &&
    //     pricePerChildrenPercent === ""
    //   ) {
    //     setAlertpricePerChildrenPercent("Please enter the child per percentage");
    //     e.preventDefault();
    //   } else {
    //     setAlertpricePerChildrenPercent("");
    //   }

    //   const regtextdep = /^[0-9][0-9]?$|^100$/;
    //   if (regtextdep.test(departureLocation)) {
    //     setAlertdepartureLocation("");
    //   } else if (
    //     !regtextdep.test(departureLocation) &&
    //     departureLocation === ""
    //   ) {
    //     setAlertdepartureLocation("Please enter the departure location");
    //     e.preventDefault();
    //   } else {
    //     setAlertdepartureLocation("");
    //   }

    //   const regtxtchildage = /^[0-9][0-9]?$|^100$/;
    //   if (regtxtchildage.test(minChildAge)) {
    //     setAlertminChildAge("");
    //   } else if (!regtxtchildage.test(minChildAge) && minChildAge === "") {
    //     setAlertminChildAge("Please enter the min child age");
    //     e.preventDefault();
    //   } else {
    //     setAlertminChildAge("");
    //   }
  };

  const clear = () => {
    navigate(-1);
  };

  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  // const [packageDetails, setPackageDetails] = useState([]);
  const { id } = useParams();
  localStorage.setItem("id", id);
  React.useEffect(() => {
    dispatch(packageDetails())
      .unwrap()
      .then((data) => {
        // setPackageDetails(data.user.data);
        if (data.user.data[0]._id == id) {
          setCityval(data.user.data[0].city);
          setcityId(data.user.data[0].cityId);
          setCity(data.user.data[0].city);
          setcategoryId(data.user.data[0].categoryId._id);
          setcatName(data.user.data[0].categoryId.categoryName);
          setDuration(data.user.data[0].duration);
          setdepartureLocation(data.user.data[0].departureLocation);
          setInclusive(data.user.data[0].inclusive);
          setExclusive(data.user.data[0].exclusive);
          settermsCondition(data.user.data[0].termsCondition);
          setimageUrl(data.user.data[0].imageUrl);
          setimportantNotes(data.user.data[0].importantNotes);
          setbookingProcedure(data.user.data[0].bookingProcedure);
          setpackageName(data.user.data[0].packageName);
          setDescription(data.user.data[0].description);
          setRatings(data.user.data[0].ratings);
          settwinShareRate(data.user.data[0].twinShareRate);
          setPricing(data.user.data[0].pricing);
          setoddPersonPercent(data.user.data[0].oddPersonPercent);
          setsinglePaxRatePercent(data.user.data[0].singlePaxRatePercent);
          setpricePerChildrenPercent(data.user.data[0].pricePerChildrenPercent);
          setminChildAge(data.user.data[0].minChildAge);
          setTheme(data.user.data[0].travelThemes);
          settravelThemes(data.user.data[0].travelThemes);
          settourIternary(data.user.data[0].tourIternary);
          sethotelDescription(data.user.data[0].hotelDescription);
          // let themeval = [];
          // for (let i = 0; i < data.user.data.travelThemes.length; i++) {
          //   themeval.push({
          //     name: data.user.data[i].travelThemes[i].name,
          //     id: data.user.data[i].travelThemes[i]._id,
          //   });
          // }

          // setTheme(themeval);
          // console.log(setTheme);
        }
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(category())
      .unwrap()
      .then((data) => {
        setCategories(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(themes())
      .unwrap()
      .then((data) => {
        // setTheme(data.user.data);
        let option = [];
        for (let i = 0; i < data.user.data.length; i++) {
          option.push({
            name: data.user.data[i].name,
            id: data.user.data[i]._id,
          });
        }

        setOptions(option);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  const category = createAsyncThunk(
    "category/get/travelCategory",
    async (thunkAPI) => {
      try {
        const data = await CategoryService.getCategory();
        return { user: data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  React.useEffect(() => {
    dispatch(citys())
      .unwrap()
      .then((data) => {
        setCities(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  const citys = createAsyncThunk("city/get/getCity", async (thunkAPI) => {
    try {
      const data = await CityService.getCity();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  });

  const handleAddForms = () => {
    let iternary = [...tourIternary];
    iternary.push({ day: "", description: "", meal: "" });
    settourIternary(iternary);
  };
  const [categories, setCategories] = useState([]);
  const [cities, setCities] = useState([]);
  const handleDeleteForms = (e) => {
    if (tourIternary.length > 1) {
      let iternary = [...tourIternary];
      iternary.pop();
      settourIternary(iternary);
    }
  };
  const handleRowChange = (e, index) => {
    const iternary = [...tourIternary];
    Object.freeze(iternary[index]);
    const objCopy = { ...iternary[index] };
    objCopy[e.currentTarget.name] = e.currentTarget.value;
    iternary[index] = objCopy;
    settourIternary(iternary);
    // console.log(tourIternary);
  };

  const handleAddForms1 = () => {
    let hotel = [...hotelDescription];
    hotel.push({
      destination: "",
      HotelName: "",
      address: "",
      capacityPerRoom: "",
    });
    sethotelDescription(hotel);
  };

  const handleDeleteForms1 = (e) => {
    if (hotelDescription.length > 1) {
      let hotel = [...hotelDescription];
      hotel.pop();
      sethotelDescription(hotel);
    }
  };
  const handleRowChange1 = (e, index) => {
    const hotel = [...hotelDescription];
    Object.freeze(hotel[index]);
    const objCopy = { ...hotel[index] };
    objCopy[e.currentTarget.name] = e.currentTarget.value;
    hotel[index] = objCopy;
    sethotelDescription(hotel);
    // console.log(hotelDescription);
  };
  // async function imageUpload(e) {
  //   e.preventDefault();
  //   var formdata = new FormData();
  //   formdata.append("file", e.target.files[0]);

  //   var requestOptions = {
  //     method: "POST",
  //     body: formdata,
  //     redirect: "follow",
  //   };
  //   let response = await fetch(API_PATHS.uploadImage, requestOptions);
  //   let data = await response.json();
  //   let imgurl = [...imageUrl];
  //   // for (let i = 0; i < data.length; i++) {
  //   imgurl.push({
  //     mediaLink: data.data.location,
  //   });

  //   setimageUrl(imgurl);
  // }

  async function imageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    const files = [];
    files.push(...e.target.files);
    files.forEach((file) => formdata.append("file", file));
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.multiUploadImage, requestOptions);
    let data = await response.json();
    let imgurl = [];
    for (let i = 0; i < data.data.length; i++) {
      imgurl.push({
        mediaLink: data.data[i],
      });
    }
    setimageUrl(imgurl);
    setMessage(data.message);
  }

  // async function imageMultipleUpload(e) {
  //   e.preventDefault();
  //   var formdata = new FormData();
  //   const files = [];
  //   files.push(...e.target.files);
  //   files.forEach((file) => formdata.append("file", file));
  //   var requestOptions = {
  //     method: "POST",
  //     body: formdata,
  //     redirect: "follow",
  //   };
  //   let response = await fetch(API_PATHS.multiUploadImage, requestOptions);
  //   let data = await response.json();
  //   setimageUrl(data.data.location);
  // }

  function onSelect(selectedList, selectedItem) {
    settravelThemes(selectedList);
  }

  function onRemove(selectedList, removedItem) {
    settravelThemes(selectedList);
  }

  async function Update(e) {
    e.preventDefault();
    let item = {
      set: {
        categoryId,
        cityId,
        city,
        packageName,
        description,
        duration,
        ratings,
        departureLocation,
        inclusive,
        exclusive,
        tourIternary,
        termsCondition,
        importantNotes,
        imageUrl,
        bookingProcedure,
        pricing,
        twinShareRate,
        oddPersonPercent,
        singlePaxRatePercent,
        pricePerChildrenPercent,
        minChildAge,
        hotelDescription,
        travelThemes,
      },
    };

    dispatch(editPackages(item))
      .unwrap()
      .then(() => {
        alert("Package updated successfully");
        navigate("/TravelPackage");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const handleCategoryChange = (e) => {
    var value = categories.filter(function (category) {
      return category.categoryName === e.target.value;
    });
    setcatName(value[0].categoryName);
    setcategoryId(value[0]._id);
    // console.log(value);
  };

  const handleCityChange = (e) => {
    var value = cities.filter(function (citys) {
      return citys.name === e.target.value;
    });
    setcityId(value[0]._id);
    setCityval(value[0].name);
    setCity(value[0].name);
    // console.log(value);
  };

  return (
    <div>
      <Navbarside />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Edit Package
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <form onSubmit={Update}>
                          <div className="row justify-content-md-center">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="basicSelect">Select Category</label>
                                <select
                                  value={catName}
                                  className="form-control"
                                  onChange={handleCategoryChange}
                                  required
                                >
                                  <option value="">Select</option>
                                  {categories.map((option) => (
                                    <option value={option.categoryId}>
                                      {option.categoryName}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="basicSelect">Select City</label>
                                <select
                                  className="form-control"
                                  value={cityval}
                                  onChange={handleCityChange}
                                  required
                                >
                                  <option value="">Select</option>
                                  {cities.map((option) => (
                                    <option value={option.cityId}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="eventInput2">Theme</label>

                                <Multiselect
                                  options={options} // Options to display in the dropdown
                                  selectedValues={theme}
                                  onSelect={onSelect} // Function will trigger on select event
                                  onRemove={onRemove} // Function will trigger on remove event
                                  displayValue="name"
                                  onChange={settravelThemes}
                                  required
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="eventInput2">Package Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="packageName"
                                  onChange={(e) =>
                                    setpackageName(e.target.value)
                                  }
                                  id="inputpackageName"
                                  value={packageName}
                                  required
                                />
                                <p className="alert-message">
                                  {alertpackageName}
                                </p>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="eventInput4">Duartion</label>
                                <input
                                  type="text"
                                  name="duration"
                                  onChange={(e) => setDuration(e.target.value)}
                                  id="inputduration"
                                  value={duration}
                                  className="form-control"
                                  required
                                />
                                <p className="alert-message">{alertduration}</p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="eventInput5">Ratings</label>
                                <input
                                  type="number"
                                  name="ratings"
                                  min="1"
                                  max="5"
                                  step=".01"
                                  onChange={(e) => setRatings(e.target.value)}
                                  id="inputratings"
                                  value={ratings}
                                  className="form-control"
                                  required
                                />
                                <p className="alert-message">{alertratings}</p>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="form-group">
                                <label for="placeTextarea">Description</label>
                                <textarea
                                  className="form-control"
                                  name="description"
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                  id="inputdescription"
                                  value={description}
                                  required
                                  rows="3"
                                ></textarea>
                                <p className="alert-message">
                                  {alertdescription}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label for="placeTextarea">Inclusive</label>
                                <textarea
                                  className="form-control"
                                  name="inclusive"
                                  onChange={(e) => setInclusive(e.target.value)}
                                  id="inputinclusive"
                                  value={inclusive}
                                  rows="3"
                                  required
                                ></textarea>
                                <p className="alert-message">
                                  {alertinclusive}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label for="placeTextarea">Exclusive</label>
                                <textarea
                                  className="form-control"
                                  name="exclusive"
                                  onChange={(e) => setExclusive(e.target.value)}
                                  id="inputexclusive"
                                  value={exclusive}
                                  rows="3"
                                  required
                                ></textarea>
                                <p className="alert-message">
                                  {alertexclusive}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label for="placeTextarea">
                                  Terms & Condition
                                </label>
                                <textarea
                                  className="form-control"
                                  name="termsCondition"
                                  onChange={(e) =>
                                    settermsCondition(e.target.value)
                                  }
                                  id="inputtermsCondition"
                                  value={termsCondition}
                                  rows="3"
                                  required
                                ></textarea>
                                <p className="alert-message">
                                  {alerttermsCondition}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label for="placeTextarea">
                                  Important Notes
                                </label>
                                <textarea
                                  className="form-control"
                                  name="importantNotes"
                                  onChange={(e) =>
                                    setimportantNotes(e.target.value)
                                  }
                                  id="inputimportNotes"
                                  value={importantNotes}
                                  required
                                  rows="3"
                                ></textarea>
                                <p className="alert-message">
                                  {alertimportantNotes}
                                </p>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="form-group">
                                <label for="placeTextarea">
                                  Cancellation Policy
                                </label>
                                <textarea
                                  className="form-control"
                                  name="bookingProcedure"
                                  onChange={(e) =>
                                    setbookingProcedure(e.target.value)
                                  }
                                  id="inputbookingProcedure"
                                  value={bookingProcedure}
                                  rows="3"
                                  required
                                ></textarea>
                                <p className="alert-message">
                                  {alertbookingProcedure}
                                </p>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="placeTextarea">Actual Price</label>
                                <input
                                  type="number"
                                  min="1"
                                  className="form-control"
                                  name="cancellationPolicy"
                                  onChange={(e) => setPricing(e.target.value)}
                                  id="inputbookingProcedure"
                                  value={pricing}
                                  rows="3"
                                  required
                                />
                                <p className="alert-message">{alertpricing}</p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="placeTextarea">
                                  Price Twin Sharing
                                </label>
                                <input
                                  type="number"
                                  min="1"
                                  className="form-control"
                                  name="twinShareRate"
                                  onChange={(e) =>
                                    settwinShareRate(e.target.value)
                                  }
                                  id="inputtwinShareRate"
                                  value={twinShareRate}
                                  required
                                />
                                <p className="alert-message">
                                  {alerttwinShareRate}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="eventInput5">
                                  Odd Person Percentage
                                </label>
                                <input
                                  type="number"
                                  min="1"
                                  name="oddPersonPercent"
                                  onChange={(e) =>
                                    setoddPersonPercent(e.target.value)
                                  }
                                  id="inputdepartureLocation"
                                  value={oddPersonPercent}
                                  className="form-control"
                                  required
                                />
                                <p className="alert-message">
                                  {alertoddPersonPercent}
                                </p>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="eventInput5">
                                  Single Person Percentage
                                </label>
                                <input
                                  type="number"
                                  min="1"
                                  name="departureLocation"
                                  onChange={(e) =>
                                    setsinglePaxRatePercent(e.target.value)
                                  }
                                  id="inputdepartureLocation"
                                  value={singlePaxRatePercent}
                                  className="form-control"
                                  required
                                />
                                <p className="alert-message">
                                  {alertsinglePaxRatePercent}
                                </p>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="eventInput5">
                                  Price Per Child Percentage
                                </label>
                                <input
                                  type="number"
                                  min="1"
                                  name="departureLocation"
                                  onChange={(e) =>
                                    setpricePerChildrenPercent(e.target.value)
                                  }
                                  id="inputdepartureLocation"
                                  value={pricePerChildrenPercent}
                                  className="form-control"
                                  required
                                />
                                <p className="alert-message">
                                  {alertpricePerChildrenPercent}
                                </p>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="eventInput5">Min Child Age</label>
                                <input
                                  type="number"
                                  min="1"
                                  name="departureLocation"
                                  onChange={(e) =>
                                    setminChildAge(e.target.value)
                                  }
                                  id="inputdepartureLocation"
                                  value={minChildAge}
                                  className="form-control"
                                  required
                                />
                                <p className="alert-message">
                                  {alertminChildAge}
                                </p>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="eventInput5">
                                  Departure Location
                                </label>
                                <input
                                  type="text"
                                  name="departureLocation"
                                  onChange={(e) =>
                                    setdepartureLocation(e.target.value)
                                  }
                                  id="inputdepartureLocation"
                                  value={departureLocation}
                                  className="form-control"
                                  required
                                />
                                <p className="alert-message">
                                  {alertdepartureLocation}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6"></div>
                          </div>
                          <br />
                          <h3>Upload Images</h3>
                          <br />
                          <div className="row justify-content-md-center">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label for="eventInput5">
                                  Upload Images (Max 10 images and resolution
                                  1920X1080)
                                </label>
                                <br />
                                <div className="row">
                                  {imageUrl.map((image, index) => (
                                    <div className="col-md-3">
                                      <img
                                        src={image.mediaLink}
                                        alt=""
                                        style={{
                                          height: "150px",
                                          width: "200px",
                                        }}
                                      />
                                    </div>
                                  ))}
                                </div>
                                <br />
                                <input
                                  type="File"
                                  name="imageUrl"
                                  multiple
                                  onChange={(e) => imageUpload(e)}
                                  id="inputimageUrl"
                                  className="form-control-file"
                                  required
                                />
                                <br></br>
                                <p>{message}</p>
                              </div>
                              <p className="alert-message">{alertimageUrl}</p>
                            </div>

                            <div className="col-md-6"></div>
                          </div>
                          <br />

                          <h3>TOUR ITERNARY</h3>
                          <br />
                          <div className="row">
                            <div className="col-md-4"></div>
                            <div className="col-md-4" align="center">
                              <button
                                type="button"
                                className="btn btn-success"
                                onClick={(e) => handleAddForms(e)}
                              >
                                <i className="fa fa-plus-circle" />
                              </button>
                              &nbsp; &nbsp;
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={(e) => handleDeleteForms(e)}
                              >
                                <i className="fa fa-minus-circle" />
                              </button>
                            </div>
                          </div>
                          <br />
                          {tourIternary.map((itiernary, index) => (
                            <div key={index} className="form-group row">
                              <div className="col-md-3">
                                <label for="eventInput5">Day</label>
                                <input
                                  onChange={(e) => handleRowChange(e, index)}
                                  className="form-control"
                                  placeholder="Day"
                                  name="day"
                                  type="text"
                                  value={itiernary.day}
                                  required
                                />
                              </div>
                              <div className="col-md-6">
                                <label for="eventInput5">Iternary</label>
                                <textarea
                                  onChange={(e) => handleRowChange(e, index)}
                                  className="form-control"
                                  placeholder="Description"
                                  name="description"
                                  type="text"
                                  value={itiernary.description}
                                  required
                                />
                              </div>
                              <div className="col-md-3">
                                <label for="eventInput5">Meal Type</label>
                                <input
                                  onChange={(e) => handleRowChange(e, index)}
                                  className="form-control"
                                  placeholder="Meal Type"
                                  name="meal"
                                  type="text"
                                  value={itiernary.meal}
                                  required
                                />
                              </div>
                            </div>
                          ))}
                          <br />
                          <h2>Hotel Details</h2>
                          <br />
                          <div className="row">
                            <div className="col-md-4"></div>
                            <div className="col-md-4" align="center">
                              <button
                                type="button"
                                className="btn btn-success"
                                onClick={(e) => handleAddForms1(e)}
                              >
                                <i className="fa fa-plus-circle" />
                                Add
                              </button>
                              &nbsp; &nbsp;
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={(e) => handleDeleteForms1(e)}
                              >
                                <i className="fa fa-minus-circle" />
                              </button>
                            </div>
                          </div>
                          <br />
                          {hotelDescription.map((hotel, index) => (
                            <div key={index} className="form-group row">
                              <div className="col-md-3">
                                <label for="eventInput5">Destination</label>
                                <input
                                  onChange={(e) => handleRowChange1(e, index)}
                                  className="form-control"
                                  placeholder="Destination"
                                  name="destination"
                                  type="text"
                                  value={hotel.destination}
                                  required
                                />
                              </div>
                              <div className="col-md-3">
                                <label for="eventInput5">Hotel Name</label>
                                <input
                                  onChange={(e) => handleRowChange1(e, index)}
                                  className="form-control"
                                  placeholder="Hotel Name"
                                  name="HotelName"
                                  type="text"
                                  value={hotel.HotelName}
                                  required
                                />
                              </div>
                              <div className="col-md-3">
                                <label for="eventInput5">Duration</label>
                                <input
                                  onChange={(e) => handleRowChange1(e, index)}
                                  className="form-control"
                                  placeholder="Duration"
                                  name="Duration"
                                  type="text"
                                  value={hotel.Duration}
                                  required
                                />
                              </div>
                              <div className="col-md-3">
                                <label for="eventInput5">
                                  Capacity Per Room
                                </label>
                                <input
                                  onChange={(e) => handleRowChange1(e, index)}
                                  className="form-control"
                                  placeholder="Capacity Per Room"
                                  name="capacityPerRoom"
                                  type="number"
                                  value={hotel.capacityPerRoom}
                                  required
                                />
                              </div>
                            </div>
                          ))}
                          {loading ? (
                            <Loader active inline />
                          ) : (
                            <>
                              <div className="form-actions center">
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                  // disabled={!message}
                                  // onClick={save}
                                >
                                  <i className="icon-note"></i> Save
                                </button>
                                &nbsp;
                                <button
                                  type="reset"
                                  className="btn btn-danger mr-1"
                                  onClick={clear}
                                >
                                  <i className="icon-trash"></i> Cancel
                                </button>
                              </div>
                            </>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Editpackage;
