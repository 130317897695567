import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { agencyDetails } from "../store/slices/agency";
import Navbarside from "../component/Navbarside";
import { useNavigate, useParams } from "react-router-dom";
import { BsArrowDownSquareFill } from "react-icons/bs";
import Footer from "./footer";

function Traveldetails() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [agencyName, setAgency] = useState("");
  const [agentName, setAgent] = useState("");
  // const [cancelledCheque, setCancelledCheque] = useState("");
  const [facilitationFeesPercent, setFacilitationFeesPercent] = useState("");
  const [gstOnEffectivePricePercent, setGstOnEffectivePricePercent] =
    useState("");
  const [gstOnFfPercent, setGstOnFfPercent] = useState("");
  const [gstOnHplCommissionPercent, setGstOnHplCommissionPercent] =
    useState("");
  const [hplCommissionPercent, setHplCommissionPercent] = useState("");
  // const [isActive, setIsActive] = useState("");
  // const [isApproved, setIsApproved] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [ownerNumber, setOwnerNumber] = useState("");
  const [reversalOnFfPercent, setReversalOnFfPercent] = useState("");
  const [webSite, setWebSite] = useState("");

  const navigate = useNavigate("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { id } = useParams();
  localStorage.setItem("id", id);

  React.useEffect(() => {
    dispatch(agencyDetails())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id === id) {
          setEmail(data.user.data[0].email);
          setName(data.user.data[0].name);
          setAgency(data.user.data[0].agencyName);
          setAgent(data.user.data[0].agentName);
          // setCancelledCheque(data.user.data[0].cancelledCheque);
          setFacilitationFeesPercent(data.user.data[0].facilitationFeesPercent);
          setGstOnEffectivePricePercent(
            data.user.data[0].gstOnEffectivePricePercent
          );
          setGstOnFfPercent(data.user.data[0].gstOnFfPercent);
          setGstOnHplCommissionPercent(
            data.user.data[0].gstOnHplCommissionPercent
          );
          setHplCommissionPercent(data.user.data[0].hplCommissionPercent);
          // setIsActive(data.user.data[0].isActive);
          // setIsApproved(data.user.data[0].isApproved);
          setMobileNo(data.user.data[0].mobileNo);
          setOwnerNumber(data.user.data[0].ownerNumber);
          setReversalOnFfPercent(data.user.data[0].reversalOnFfPercent);
          setWebSite(data.user.data[0].webSite);
        }
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-3" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning d-flex justify-content-between">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Travel Agency Details
                        </h4>
                        <div className="row">
                          <p className="down-text">
                            Cancelled Cheque{" "}
                            <a href="https://healthonify-bk.s3.ap-south-1.amazonaws.com/healthonify/1662101133455-WhatsAppImage2022-09-02at10.21.08AM.jpeg">
                              <BsArrowDownSquareFill
                                style={{ fontSize: "20px" }}
                              />
                            </a>
                          </p>
                          &nbsp; &nbsp; &nbsp;
                          <p className="down-text">
                            PAN{" "}
                            <a
                              href="https://healthonify-bk.s3.ap-south-1.amazonaws.com/healthonify/1662101133455-WhatsAppImage2022-09-02at10.21.08AM.jpeg"
                              download
                            >
                              <BsArrowDownSquareFill
                                style={{ fontSize: "20px" }}
                              />
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <div className="row justify-content-md-center">
                          <div className="col-md-6">
                            <div className="form-group">
                              <p
                                for="basicSelect"
                                className="text-edit-details"
                              >
                                Agency Name :{" "}
                                <span className="id-text">{agencyName}</span>
                              </p>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <p
                                for="basicSelect"
                                className="text-edit-details"
                              >
                                Agent Name :{" "}
                                <span className="id-text">{agentName}</span>
                              </p>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <p
                                for="basicSelect"
                                className="text-edit-details"
                              >
                                Name : <span className="id-text">{name}</span>
                              </p>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <p
                                for="basicSelect"
                                className="text-edit-details"
                              >
                                Email : <span className="id-text">{email}</span>
                              </p>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <p
                                for="placeTextarea"
                                className="text-edit-details"
                              >
                                Mobile No :{" "}
                                <span className="id-text">{mobileNo}</span>
                              </p>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <p
                                for="placeTextarea"
                                className="text-edit-details"
                              >
                                Owner Mobile No :{" "}
                                <span className="id-text"> {ownerNumber}</span>{" "}
                              </p>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <p
                                for="placeTextarea"
                                className="text-edit-details"
                              >
                                Website :{" "}
                                <span className="id-text">{webSite}</span>{" "}
                              </p>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <p
                                for="placeTextarea"
                                className="text-edit-details"
                              >
                                Effective Price Percent :{" "}
                                <span className="id-text">
                                  {gstOnEffectivePricePercent}
                                </span>
                              </p>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <p
                                for="placeTextarea"
                                className="text-edit-details"
                              >
                                Facilitation Fees Percent :{" "}
                                <span className="id-text">
                                  {facilitationFeesPercent}
                                </span>
                              </p>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <p
                                for="placeTextarea"
                                className="text-edit-details"
                              >
                                GST on Facilitation Fee Percent :{" "}
                                <span className="id-text">
                                  {gstOnFfPercent}
                                </span>
                              </p>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <p
                                for="placeTextarea"
                                className="text-edit-details"
                              >
                                GST on HPL Commission Percent :{" "}
                                <span className="id-text">
                                  {" "}
                                  {gstOnHplCommissionPercent}
                                </span>
                              </p>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <p
                                for="placeTextarea"
                                className="text-edit-details"
                              >
                                HPL Commission Percent :{" "}
                                <span className="id-text">
                                  {hplCommissionPercent}
                                </span>
                              </p>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <p
                                for="placeTextarea"
                                className="text-edit-details"
                              >
                                Effective Price Percent :{" "}
                                <span className="id-text">
                                  {gstOnEffectivePricePercent}
                                </span>
                              </p>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <p
                                for="placeTextarea"
                                className="text-edit-details"
                              >
                                Reversal on FF Percent :{" "}
                                <span className="id-text">
                                  {reversalOnFfPercent}
                                </span>
                              </p>
                            </div>
                          </div>

                          {/*<div className="col-md-6">
<div className="row">

          <div className="col-md-6 mt-4 d-flex justify-content-start">
          <p className="down-text">Cancelled Cheque  <a href="https://healthonify-bk.s3.ap-south-1.amazonaws.com/healthonify/1662101133455-WhatsAppImage2022-09-02at10.21.08AM.jpeg"><BsArrowDownSquareFill style={{fontSize:"20px"}}/></a></p>
         
     
 
          </div>
        
          <div className="col-md-3 mt-4 d-flex justify-content-start">
          <p className="down-text">PAN   <a href="https://healthonify-bk.s3.ap-south-1.amazonaws.com/healthonify/1662101133455-WhatsAppImage2022-09-02at10.21.08AM.jpeg" download><BsArrowDownSquareFill style={{fontSize:"20px"}}/></a></p>
        
          </div>

          <div className="col-md-3"/>

</div>
  </div>*/}

                          <div className="col-md-6" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
}
export default Traveldetails;
