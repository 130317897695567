import React, { Component } from "react";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <footer
      className="footer footer-static footer-bottom footer-light"
      id="footer"
    >
      <p className="clearfix text-muted text-center px-2">
        Copyright &copy; 2022 | All rights reserved &nbsp;
        <Link
          to="https://healthonify.com/"
          id="pixinventLink"
          target="_blank"
          className="text-bold-800 primary darken-2"
        >
          Healthonify Pvt Ltd
        </Link>
      </p>
    </footer>
  );
}

export default Footer;
