import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { API_PATHS } from "../utils/constants/api.constants";
import { postblog } from "../store/slices/blog";
// import { Loader } from "semantic-ui-react";
import Footer from "./footer";
import Navbarside from "../component/Navbarside";

function Addblog(e) {
  const navigate = useNavigate("");
  const dispatch = useDispatch();

  const [mediaLink, setMediaLink] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [blogTitle, setBlogTitle] = useState("");
  const [message, setMessage] = useState("");

  //   Alert-message

  const [alertmediaLink, setAlertmediaLink] = useState("");
  const [alertdescription, setAlertdescription] = useState("");
  const [alertdate, setAlertdate] = useState("");
  const [alertblogTitle, setAlertblogTitle] = useState("");

  async function upload(e) {
    e.preventDefault();
    let expertiseId = "62fcccde8db90c33d1dfb269";
    let isActive = true;
    let item = {
      mediaLink,
      description,
      date,
      blogTitle,
      expertiseId,
      isActive,
    };

    dispatch(postblog(item))
      .unwrap()
      .then(() => {
        alert("Uploaded successfully");
        navigate("/Blog");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  async function imageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setMediaLink(data.data.location);
    setMessage(data.message);
  }

  const save = (e) => {
    const regtext = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (regtext.test(blogTitle)) {
      setAlertblogTitle("");
    } else if (!regtext.test(blogTitle) && blogTitle === "") {
      setAlertblogTitle("Please enter Blog Title");
      e.preventDefault();
    } else {
      setAlertblogTitle("");
    }

    const regimage = /(gif|jpe?g|tiff?|png|webp|bmp)$/i;
    if (regimage.test(mediaLink)) {
      setAlertmediaLink("");
    } else if (!regimage.test(mediaLink) && mediaLink === "") {
      setAlertmediaLink("Please enter image link");
      e.preventDefault();
    } else {
      setAlertmediaLink("Invalid file");
      e.preventDefault();
    }

    const regtext2 = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    if (regtext2.test(description)) {
      setAlertdescription("");
    } else if (!regtext2.test(description) && description === "") {
      setAlertdescription("Please enter your description");
      e.preventDefault();
    } else {
      setAlertdescription("");
    }

    const regdate =
      /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[-]([0]?[1-9]|[1][0-2])[-]([0-9]{4}|[0-9]{2})$/;
    if (regdate.test(date)) {
      setAlertdate("");
    } else if (!regdate.test(date) && date === "") {
      setAlertdate("Please enter your blog date");
      e.preventDefault();
    } else {
      setAlertdate("");
    }
  };

  const cancel = () => {
    setMediaLink("");
    setDescription("");
    setDate("");
    setBlogTitle("");
    setAlertmediaLink("");
    setAlertdescription("");
    setAlertdate("");
    setAlertblogTitle("");
    setMessage("");
  };
  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Upload Blog
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <form className="form" onSubmit={upload}>
                          <div className="row justify-content-md-center">
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Blog Title</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Blog Title"
                                    value={blogTitle}
                                    onChange={(e) =>
                                      setBlogTitle(e.target.value)
                                    }
                                  />
                                  <p className="alert-message">
                                    {alertblogTitle}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Date</label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    Placeholder=""
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                  />
                                  <p className="alert-message">{alertdate}</p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Blog Image</label>
                                  <input
                                    type="file"
                                    className="form-control-file"
                                    Placeholder=""
                                    name="imageUrl"
                                    onChange={(e) => imageUpload(e)}
                                  />
                                  <p className="alert-message">
                                    {alertmediaLink}
                                  </p>
                                  <p
                                    style={{
                                      color: "green",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {message}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Description</label>
                                  <input
                                    type="test"
                                    className="form-control"
                                    Placeholder="Enter Description"
                                    value={description}
                                    onChange={(e) =>
                                      setDescription(e.target.value)
                                    }
                                  />
                                  <p className="alert-message">
                                    {alertdescription}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-actions center">
                            <button
                              type="submit"
                              className="btn btn-success"
                              onClick={save}
                              disabled={!message}
                            >
                              <i className="icon-note"></i> Save
                            </button>{" "}
                            &nbsp;
                            <button
                              type="reset"
                              className="btn btn-warning mr-1"
                              onClick={cancel}
                            >
                              <i className="icon-trash"></i> Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default Addblog;
