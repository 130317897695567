import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class BookingTravel {
    static travelBooking() {
        let api = '';
    let user = JSON.parse(localStorage.getItem("user"));
    let role = user.data.roles[0];
    let id = localStorage.getItem("id");
    if(role === 'ROLE_TRAVEL_ADMIN'){
      api = id ? API_PATHS.travelBooking+ '?_id=' + id :API_PATHS.travelBooking;
    }else if(role === 'ROLE_TRAVELAGENT'){
      api = id ? API_PATHS.travelBooking + '?_id=' + id + '&agentId=' + user.data.id: API_PATHS.travelBooking + '?agentId=' + user.data.id;
    }
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static travelBookingDetails() {
    let api = '';
let id = localStorage.getItem("id");
  api = API_PATHS.travelBooking+ '?_id=' + id;

return axios
  .get(api)
  .then((response) => {
    if (response.data) {
      // localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
  });
}

  static hotelBooking() {
    let api = '';
let user = JSON.parse(localStorage.getItem("user"));
let role = user.data.roles[0];
let id = localStorage.getItem("id");
if(role === 'ROLE_TRAVEL_ADMIN'){
  api = id ? API_PATHS.gethotelBooking+ '?_id=' + id :API_PATHS.gethotelBooking;
}else if(role === 'ROLE_HOTELADMIN'){
  api = id ? API_PATHS.gethotelBooking + '?_id=' + id + '&hotelId=' + user.data.id: API_PATHS.gethotelBooking + '?hotelId=' + user.data.id;
}
return axios
  .get(api)
  .then((response) => {
    if (response.data) {
      // localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
  });
}


      

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default BookingTravel;