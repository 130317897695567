import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class ClientService {
    static getClient() {
      let item = JSON.parse(localStorage.getItem("item"));
      return axios
      .post(API_PATHS.getClient,
          item
      )
      .then((response) => {
          if (response.data) {
              // localStorage.setItem("user", JSON.stringify(response.data));
          }
          return response.data;
      });
      }
    

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default ClientService;