import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postroomadd } from "../store/slices/room";
import Navbarside from "../component/Navbarside";
import { Loader } from "semantic-ui-react";
import Footer from "./footer";

function Addrooms(e) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [totalRoomCount, setTotalRoomCount] = useState("");
  const [availableRoomCount, setAvailableRoomCount] = useState("");
  const [pricePerRoom, setPricePerRoom] = useState("");
  const [personPerPersonInRoom, setPersonPerPersonInRoom] = useState("");
  const [pricePerChildPercent, setPricePerChildPercent] = useState("");
  const [hotelId, setHotelId] = useState("");
  const [tags, setTags] = useState("");
  const [amenities, setAmenities] = useState("");
  const [roomCategoryId, setRoomCategoryId] = useState("");
  const [roomTypeId, setRoomTypeId] = useState("");
  const [bedTypeId, setBedTypeId] = useState("");

  const navigate = useNavigate("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // alert message
  const [alertroomType, setAlertroomType] = useState("");
  const [alertbedType, setAlertbedType] = useState("");
  const [alertroomcategory, setAlertroomcategory] = useState("");
  const [alertamenities, setAlertamenities] = useState("");
  const [alertags, setAlertags] = useState("");
  const [alertname, setAlertname] = useState("");
  const [alertdescription, setAlertdescription] = useState("");
  const [alertavail, setAlertavail] = useState("");
  const [alertroom, setAlertroom] = useState("");
  const [alertperson, setAlertperson] = useState("");
  const [alertchild, setAlertchild] = useState("");
  const [alertotal, setAlertotal] = useState("");

  const submit = () => {
    const totalreg = /^(([0-10]+[,.]?[ ]?|[0-10]+['-]?)+)$/;
    if (totalreg.test(totalRoomCount)) {
      setAlertotal("");
    } else if (!totalreg.test(totalRoomCount) && totalRoomCount === "") {
      setAlertotal("Enter  Room Price");
    } else {
      setAlertotal("Numbers only");
    }

    const childreg = /^(([0-10]+[,.]?[ ]?|[0-10]+['-]?)+)$/;
    if (childreg.test(pricePerChildPercent)) {
      setAlertchild("");
    } else if (
      !childreg.test(pricePerChildPercent) &&
      pricePerChildPercent === ""
    ) {
      setAlertchild("Enter  Room Price");
    } else {
      setAlertchild("Numbers only");
    }

    const pricereg = /^(([0-10]+[,.]?[ ]?|[0-10]+['-]?)+)$/;
    if (pricereg.test(personPerPersonInRoom)) {
      setAlertperson("");
    } else if (
      !pricereg.test(personPerPersonInRoom) &&
      personPerPersonInRoom === ""
    ) {
      setAlertperson("Enter Price of the Room");
    } else {
      setAlertperson("Numbers only");
    }

    const roomsreg = /^(([0-10]+[,.]?[ ]?|[0-10]+['-]?)+)$/;
    if (roomsreg.test(pricePerRoom)) {
      setAlertroom("");
    } else if (!roomsreg.test(pricePerRoom) && pricePerRoom === "") {
      setAlertroom("Enter Price-per Room Count");
    } else {
      setAlertroom("Numbers only");
    }

    const availrooms = /^(([0-10]+[,.]?[ ]?|[0-10]+['-]?)+)$/;
    if (availrooms.test(availableRoomCount)) {
      setAlertavail("");
    } else if (
      !availrooms.test(availableRoomCount) &&
      availableRoomCount === ""
    ) {
      setAlertavail("Enter Available Room Count");
    } else {
      setAlertavail("Numbers only");
    }

    const desReg = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    if (desReg.test(description)) {
      setAlertdescription("");
    } else if (!desReg.test(description) && description === "") {
      setAlertdescription("Enter your Description");
    } else {
      setAlertdescription("");
    }

    const nameReg = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (nameReg.test(name)) {
      setAlertname("");
    } else if (!nameReg.test(name) && name === "") {
      setAlertname("Enter your name");
    } else {
      setAlertname("");
    }

    const tagsReg = /^(([A-Za-z0-10]+[,.]?[ ]?|[a-z0-10]+['-]?)+)$/;
    if (tagsReg.test(tags)) {
      setAlertags("");
    } else if (!tagsReg.test(tags) && tags === "") {
      setAlertags("Enter the tag's");
    } else {
      setAlertags("");
    }

    const amenityreg = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (amenityreg.test(amenities)) {
      setAlertamenities("");
    } else if (!amenityreg.test(amenities) && amenities === "") {
      setAlertamenities("Enter the Amenity's");
    } else {
      setAlertamenities("");
    }

    const categoryreg = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (categoryreg.test(roomCategoryId)) {
      setAlertroomcategory("");
    } else if (!categoryreg.test(roomCategoryId) && roomCategoryId === "") {
      setAlertroomcategory("Enter Room Category");
    } else {
      setAlertroomcategory("");
    }

    const bedreg = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (bedreg.test(bedTypeId)) {
      setAlertbedType("");
    } else if (!bedreg.test(bedTypeId) && bedTypeId === "") {
      setAlertbedType("Enter Bed type");
    } else {
      setAlertbedType("");
    }

    const roomreg = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (roomreg.test(roomTypeId)) {
      setAlertroomType("");
    } else if (!roomreg.test(roomTypeId) && roomTypeId === "") {
      setAlertroomType("Enter Room type");
    } else {
      setAlertroomType("");
    }
  };

  async function upload(e) {
    let hotelId = JSON.parse(localStorage.getItem("user")).data.id;

    e.preventDefault();
    let item = {
      name,
      description,
      totalRoomCount,
      availableRoomCount,
      pricePerRoom,
      personPerPersonInRoom,
      pricePerChildPercent,
      hotelId,
      amenities,
      tags,
      roomCategoryId,
      bedTypeId,
      roomTypeId,
    };

    dispatch(postroomadd(item))
      .unwrap()
      .then(() => {
        alert("Uploaded successfully");
        navigate("/Rooms");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }
  return (
    <div>
      <Navbarside />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Add Rooms
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <form className="form" onSubmit={upload}>
                          <div className="row ">
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    {" "}
                                    Room Type Id
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Room type "
                                    value={roomTypeId}
                                    onChange={(e) =>
                                      setRoomTypeId(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">
                                    {alertroomType}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    {" "}
                                    Bed Type Id
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Bed type"
                                    value={bedTypeId}
                                    onChange={(e) =>
                                      setBedTypeId(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">
                                    {alertbedType}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Room Category Id
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Category id"
                                    value={roomCategoryId}
                                    onChange={(e) =>
                                      setRoomCategoryId(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">
                                    {alertroomcategory}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Amenities</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Amenities"
                                    value={amenities}
                                    onChange={(e) =>
                                      setAmenities(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">
                                    {alertamenities}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Tags</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Tags "
                                    value={tags}
                                    onChange={(e) => setTags(e.target.value)}
                                  />
                                  <div className="alert-message">
                                    {alertags}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">New Room</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter New Room"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                  <div className="alert-message">
                                    {alertname}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Description</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Description"
                                    value={description}
                                    onChange={(e) =>
                                      setDescription(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">
                                    {alertdescription}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Available Room Count
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Room Count"
                                    value={availableRoomCount}
                                    onChange={(e) =>
                                      setAvailableRoomCount(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">
                                    {alertavail}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Total Room Count
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Room Count"
                                    value={totalRoomCount}
                                    onChange={(e) =>
                                      setTotalRoomCount(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">
                                    {alertotal}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    {" "}
                                    Price Per-Room
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Room Count"
                                    value={pricePerRoom}
                                    onChange={(e) =>
                                      setPricePerRoom(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">
                                    {alertroom}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    {" "}
                                    Per-Person In Room
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Room Count"
                                    value={personPerPersonInRoom}
                                    onChange={(e) =>
                                      setPersonPerPersonInRoom(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">
                                    {alertperson}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    {" "}
                                    Price Per-Child Percent
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Room Count"
                                    value={pricePerChildPercent}
                                    onChange={(e) =>
                                      setPricePerChildPercent(e.target.value)
                                    }
                                  />
                                  <div className="alert-message">
                                    {alertchild}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {loading ? (
                            <Loader active inline />
                          ) : (
                            <>
                              <div className="form-actions center">
                                <button
                                  type="submit"
                                  className="btn btn-blue"
                                  onClick={submit}
                                >
                                  <i className="icon-note"></i> Save
                                </button>
                                &nbsp;
                                <button
                                  type="reset"
                                  className="btn btn-warning mr-1"
                                >
                                  <i className="icon-trash"></i> Cancel
                                </button>
                              </div>
                            </>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default Addrooms;
