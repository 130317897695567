import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import Navbarside from "../component/Navbarside";
import { themes, enablethemes } from "../store/slices/theme";
import { Link, useNavigate, NavLink } from "react-router-dom";
import Footer from "./footer";

function ThemeList() {
  const [expertises, setexpertise] = useState([]);
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate("");

  React.useEffect(() => {
    dispatch(themes())
      .unwrap()
      .then((data) => {
        setexpertise(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  async function enableCity(id) {
    let isActive = true;
    let item = { set: { isActive } };
    localStorage.setItem("item", JSON.stringify(item));
    localStorage.setItem("id", id);

    dispatch(enablethemes())
      .unwrap()
      .then(() => {
        // alert("Uploaded succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  async function disableCity(id) {
    let isActive = false;
    let item = { set: { isActive } };
    localStorage.setItem("item", JSON.stringify(item));
    localStorage.setItem("id", id);

    dispatch(enablethemes())
      .unwrap()
      .then(() => {
        // alert("Uploaded succesfully");
        // navigate("/ThemeList");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
    console.log(item);
    console.log(id);
  }
  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title-wrap bar-success">
                          <h4 className="card-title">
                            Travel Themes &nbsp; &nbsp;
                            <NavLink
                              to="/UploadTheme"
                              className="btn btn-primary"
                            >
                              <i className="fa fa-plus"></i> Add Theme
                            </NavLink>
                          </h4>
                        </div>
                      </div>
                      <div className="card-body collapse show">
                        <div className="card-block card-dashboard table-responsive">
                          <table className="table table-striped table-bordered zero-configuration">
                            <thead>
                              <tr>
                                <th>Theme Name</th>
                                {/* <th>Tag Line</th>
                                    <th>Description</th>
  <th>Image</th> */}
                                <th>Action</th>
                                <th>Is Active</th>
                              </tr>
                            </thead>
                            {loading ? (
                              <Loader active inline />
                            ) : (
                              <>
                                <tbody>
                                  {expertises.map((themeVal, index) => (
                                    <tr key={index}>
                                      <td>{themeVal.name}</td>

                                      <td>
                                        <Link
                                          style={{
                                            height: "25px",
                                            padding: "0 10px",
                                          }}
                                          to={`/EditTheme/${
                                            themeVal._id ? themeVal._id : null
                                          }`}
                                          className="btn btn-primary"
                                        >
                                          Edit
                                        </Link>{" "}
                                      </td>
                                      <td>
                                        {themeVal.isActive === false && (
                                          <button
                                            style={{
                                              height: "25px",
                                              padding: "0 10px",
                                            }}
                                            className="btn btn-danger"
                                            onClick={() =>
                                              enableCity(themeVal._id)
                                            }
                                          >
                                            No
                                          </button>
                                        )}
                                        {themeVal.isActive === true && (
                                          <button
                                            style={{
                                              height: "25px",
                                              padding: "0 10px",
                                            }}
                                            className="btn btn-success"
                                            onClick={() =>
                                              disableCity(themeVal._id)
                                            }
                                          >
                                            Yes
                                          </button>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </body>
    </div>
  );
}
export default ThemeList;
