import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { agencyregister } from "../store/slices/auth";
import { VscEye } from "react-icons/vsc";
import logo from "../img/travelonify_logo.png";
import Footer from "./footer";


import { API_PATHS } from "../utils/constants/api.constants";

function AgencyRegister(e) {
  const [ownerNumberShown, setownerNumberShown] = useState(false);
  const [messagech, setMessagech] = useState("");
  const [messagepan, setMessagepan] = useState("");
  const [pan, setPan] = useState(" No File Chosen");
  const [cancelledCheque, setcancelledCheque] = useState(" No File Chosen");
  const [name, setname] = useState("");
  const [email, setEmail] = useState("");
  const [webSite, setwebSite] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [gst, setGst] = useState("");
  const [ownerNumber, setownerNumber] = useState("");

  async function panUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setPan(data.data.location);
    setMessagepan(data.message);
  }

  async function chequeUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setcancelledCheque(data.data.location);
    setMessagech(data.message);
  }

  //Alert Message End
  const navigate = useNavigate("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const toggleownerNumber = () => {
    setownerNumberShown(!ownerNumberShown);
  };

  const [alertfn, setAlertfn] = useState("");
  const [alertln, setAlertln] = useState("");
  const [alertmes, setAlertmes] = useState("");
  const [alertmail, setAlertmail] = useState("");
  const [alertown, setAlertown] = useState("");
  const [alertgst, setAlertgst] = useState("");
  const [alertpan, setAlertpan] = useState("");
  const [alertcheque, setAlertcheque] = useState("");

  let hplCommissionPercent = 0;
  let facilitationFeesPercent = 0;
  async function signUp(e) {
    e.preventDefault();
    let item = {
      name,
      email,
      mobileNo,
      ownerNumber,
      webSite,
      cancelledCheque,
      pan,
      gst,
      facilitationFeesPercent,
      hplCommissionPercent,
    };

    dispatch(agencyregister(item))
      .unwrap()
      .then(() => {
        alert("Request Submitted");
      })
      .catch(({ message }) => {
        // alert(message);
      });

  }



const save = (e) =>{



  const regpan = /(gif|jpe?g|tiff?|png|webp|bmp)$/i;
  if (regpan.test(pan)) {
    setAlertpan("");
  } else if (!regpan.test(pan) && pan === "") {
    setAlertpan("Please enter pan image");
    e.preventDefault();
  } else {
    setAlertpan("invalid image");
    e.preventDefault();
  }

   
  const regcheque = /(gif|jpe?g|tiff?|png|webp|bmp)$/i;
  if (regcheque.test(cancelledCheque)) {
    setAlertcheque("");
  } else if (!regcheque.test(cancelledCheque) && cancelledCheque === "") {
    setAlertcheque("Please enter cancelled cheque imag");
    e.preventDefault();
  } else {    
    setAlertcheque("invalid image");
    e.preventDefault();
  }


  const namereg = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
  if (namereg.test(name)) {
    setAlertfn("");
  } else if (!namereg.test(name) && name === "") {
    setAlertfn("Please Enter your agency name");
    e.preventDefault();

  } else {
    setAlertfn("Please enter a valid agency name");
  }

  const own = /^[0-9][0-9]?$|^100$/;
  if (own.test(ownerNumber)) {
    setAlertown("");
  } else if (!own.test(ownerNumber) && ownerNumber === "") {
    setAlertown("Please enter  owner's number");
    e.preventDefault();

  } else {
    setAlertown(
      ""
    );
  }

  const gstnum = /^[0-9][0-9]?$|^100$/;
  if (gstnum.test(gst)) {
    setAlertgst("");
  } else if (!gstnum.test(gst) && gst === "") {
    setAlertgst("Please enter GST number");
    e.preventDefault();

  } else {
    setAlertgst(
      ""
    );
  }

  const web = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
  if (web.test(webSite)) {
    setAlertln("");
  } else if (!web.test(webSite) && webSite === "") {
    setAlertln("Please enter your website");
    e.preventDefault();

  } else {
    setAlertln("Please enter a valid website");
  }

  const mobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  if (mobile.test(mobileNo)) {
    setAlertmes("");
  } else if (!mobile.test(mobileNo) && mobileNo === "") {
    setAlertmes("Please fill your mobile number");
    e.preventDefault();

  } else {
    setAlertmes("Please enter a valid mobile number");
  }



  const emailrgex = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
  if (emailrgex.test(email)) {
    setAlertmail("");
  } else if (!emailrgex.test(email) && email === "") {
    setAlertmail("Please enter your email");
    e.preventDefault();

  } else {
    setAlertmail("Please enter a valid email");
  }
}

const clear = () =>{
  setownerNumberShown("");
  setPan("");
  setname("");
  setEmail("");
  setwebSite("");
  setmobileNo("");
  setGst("");
  setownerNumber("");
  setAlertfn("");
  setAlertln("");
  setAlertmes("");
  setAlertmail("");
  setAlertown("");
  setAlertgst("");
  setAlertpan("");
  setAlertcheque("");
  setMessagech("");
  setMessagepan("");
}

  return (
    <div>
      <section id="login">
        <div className="container-fluid">
          <div className="row full-height-vh">
            <div className="col-12 d-flex align-items-center overflow-auto justify-content-center login-bg-image">
              <div className="card px-4 py-2 box-shadow-2 width-800">
              <div className="d-flex justify-content-center">
              <img src={logo} alt="logo" className="logo-form" />
        </div>
                {/* <div className="card-body">
                  <div className="card-block"> */}
                <form onSubmit={signUp}>
                  <h4 align="center" style={{color:"black", fontWeight:"500"}}>Travel Agency Registration</h4>
                  <br />
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control form-control-lg  text-black-ph"
                        name="name"
                        onChange={(e) => setname(e.target.value)}
                        id="inputname"
                        value={name}
                        placeholder="Agency Name"
                      />
                      <p className="alert-message">{alertfn}</p>
                    </div>

                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control form-control-lg text-black-ph"
                        name="webSite"
                        onChange={(e) => setwebSite(e.target.value)}
                        id="inputwebSite"
                        value={webSite}
                        placeholder="Website"
                      />
                      <p className="alert-message">{alertln}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control form-control-lg text-black-ph"
                        name="inputEmail"
                        id="inputEmail"
                        value={email}
                        placeholder="Email Address"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <p className="alert-message">{alertmail}</p>
                    </div>

                    <div className="col-md-6">
                      <input
                      maxlength="10"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                        type="text"
                        className="form-control form-control-lg text-black-ph"
                        name="inputmobileNo"
                        id="inputmobileNo"
                        placeholder="Mobile No"
                        value={mobileNo}
                        onChange={(e) => setmobileNo(e.target.value)}
                      />
                      <p className="alert-message">{alertmes}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg text-black-ph"
                        name="inputPass"
                        id="inputPass"
                        // type={ownerNumberShown ? "text" : "ownerNumber"}
                        value={ownerNumber}
                        onChange={(e) => setownerNumber(e.target.value)}
                        placeholder="Owner's Number"
                        type="number"
                      />

                      <p className="alert-message">{alertown}</p>
                    </div>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg text-black-ph"
                        name="inputPass"
                        id="inputPass"
                        // type={ownerNumberShown ? "text" : "ownerNumber"}
                        value={gst}
                        onChange={(e) => setGst(e.target.value)}
                        placeholder="GST Number"
                        type="number"
                      />

                      <p className="alert-message">{alertgst}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <span>Cancelled Cheque</span>
                      <input
                        className="form-control-file"
                        name="inputPass"
                        type="file"
                        id="inputPass"  
                        onChange={(e) => chequeUpload(e)}
                        
                      />  
                      <p style={{color:"white"}}>{messagech}</p>
                      <p className="alert-message">{alertcheque}</p>

                    </div>
                    <div className="col-md-6">
                      <span>PAN Card</span>
                      <input
                        type="file"
                        className="form-control-file"
                        name="inputPass"
                        id="inputPass"
                        onChange={(e) => panUpload(e)}
                        
                      />
                      <p style={{color:"white"}}>{messagepan}</p>

                      <p className="alert-message">{alertpan}</p>
                    </div>
                  </div>
                  <br />
                  {loading ? (
                    <Loader active inline />
                  ) : (
                    <>
                      {/* <div className="row"> */}
                      <div className=" d-flex justify-content-center text-center col-md-12">
                        <button
                          type="submit"
                          className="btn btn-primary px-4 py-2 text-uppercase white font-small-4 box-shadow-2 border-0"
                          onClick={save}
                          disabled ={!messagepan}
                        >
                          Register
                        </button>{" "}
                        &nbsp;
                        &nbsp;
                         <button
                          type="reset"
                          onClick={clear}
                          className="btn btn-danger px-4 py-2 text-uppercase white font-small-4 box-shadow-2 border-0"
                        >
                          Cancel
                        </button> 
                      </div>
                      {/* </div> */}
                    </>
                  )}
                </form>
                {/* </div>
                </div> */}
                <div className="card-footer grey darken-1">
                  <div className="text-center mb-1">
                    Already Have Account?
                    <Link to="/">
                      <b>Login</b>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AgencyRegister;
