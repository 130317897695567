import React from 'react';
import notfound from '../img/404/notf1.jpg';

const Notfoundpage = () => {
  return (
    <div>

<section className="container">

<div className='row align-items-center centered-content'>

<div className='col-lg-6 col-md-6 col-sm-12'>
<h2 className='head-404 pb-5'>Error</h2>
<p className='error-page-text'>Sorry, the page you are looking for could not be found</p>
<button type="button" className='btn btn-purple' ><a href="/" className="col-white">Go to Home</a></button>
</div>

<div className='col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end'>
<img src={notfound} className="error-img"/>
</div>
</div>

</section>
    
    </div>

  )
}

export default Notfoundpage;