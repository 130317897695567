import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Footer from "./footer";

import Navbarside from "../component/Navbarside";

import { getquestions } from "../store/slices/questionnaire";

function Questionnaire() {
  const [qna, setQna] = useState([]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getquestions())
      .unwrap()
      .then((data) => {
        setQna(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <div className="d-flex align-items-center">
                          <h4
                            className="card-title"
                            id="basic-layout-form-center"
                          >
                            Questionnaire
                          </h4>
                          &nbsp; &nbsp; &nbsp;
                          <Link
                            to="/AddQuestionnaire"
                            className="btn btn-success"
                          >
                            Add
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Questions</th>
                              <th>Question Order</th>
                              <th>Answer</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {qna.map((QuestionVal, index) => (
                              <tr key={index}>
                                <td>{QuestionVal.question}</td>

                                <td>{QuestionVal.order}</td>

                                {QuestionVal.options.map((ansVal, index) => (
                                  <p>
                                    Option {ansVal.points}.{ansVal.optionValue}
                                  </p>
                                ))}
                                <td className="position-relative">
                                  <Link
                                    to=""
                                    className="btn btn-primary position-absolute transform-center"
                                  >
                                    Edit
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default Questionnaire;
