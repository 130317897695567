import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";

import { category } from "../store/slices/category";
import { Link } from "react-router-dom";
import Footer from "./footer";
import Navbarside from "../component/Navbarside";
import Moment from "moment";
function TravelCategory() {
  const [expertises, setexpertise] = useState([]);
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(category())
      .unwrap()
      .then((data) => {
        setexpertise(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Travel Category &nbsp; &nbsp;{" "}
                          <Link
                            to="/UploadCategory"
                            className="btn btn-primary"
                          >
                            <i className="fa fa-plus"></i> Add
                          </Link>
                        </h4>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Category Name</th>
                              <th>Created By</th>
                              <th>Created On</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          {loading ? (
                            <Loader active inline />
                          ) : (
                            <>
                              <tbody>
                                {expertises.map((categoryVal, index) => (
                                  <tr key={index}>
                                    <td>{categoryVal.categoryName}</td>
                                    <td>{categoryVal.createdBy}</td>
                                    <td>
                                      {Moment(categoryVal.created_at).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </td>
                                    <td>
                                      <Link
                                        style={{
                                          height: "25px",
                                          padding: "0 10px",
                                        }}
                                        to={`/Editcategory/${
                                          categoryVal._id
                                            ? categoryVal._id
                                            : null
                                        }`}
                                        className="btn btn-primary"
                                      >
                                        Edit
                                      </Link>{" "}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default TravelCategory;
