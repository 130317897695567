import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import Navbarside from "../component/Navbarside";
import { Link } from "react-router-dom";
import Footer from "./footer";

import { room } from "../store/slices/room";

const Rooms = () => {
  const [expertises, setexpertise] = useState([]);
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(room())
      .unwrap()
      .then((data) => {
        setexpertise(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success">
                        <h4 className="card-title">
                          Rooms &nbsp; &nbsp;
                          <Link to="/Addrooms" className="btn btn-primary">
                            <i className="fa fa-plus"></i> Add
                          </Link>
                        </h4>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Room Name</th>
                              <th>Description</th>
                              <th>Available Room</th>
                              <th>Room Price</th>
                            </tr>
                          </thead>

                          {loading ? (
                            <Loader active inline />
                          ) : (
                            <>
                              <tbody>
                                {expertises.map((RoomsVal, index) => (
                                  <tr key={index}>
                                    <td>{RoomsVal.name}</td>
                                    <td>{RoomsVal.description}</td>
                                    <td>{RoomsVal.availableRoomCount}</td>
                                    <td>{RoomsVal.pricePerRoom}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Rooms;
