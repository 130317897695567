import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postroomcategorys } from "../store/slices/roomcategory";
import { Loader } from "semantic-ui-react";
import Navbarside from "../component/Navbarside";
import { API_PATHS } from "../utils/constants/api.constants";
import Footer from "./footer";

function AddRoomCategory(e) {
  const [name, setName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [alertname, setAlertname] = useState("");
  const [alertimage, setAlertimage] = useState("");

  const navigate = useNavigate("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  async function imageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setImageUrl(data.data.location);
  }

  async function upload(e) {
    let hotelAdminId = JSON.parse(localStorage.getItem("user")).data.id;
    e.preventDefault();
    let item = { name, imageUrl, hotelAdminId };

    dispatch(postroomcategorys(item))
      .unwrap()
      .then(() => {
        alert("Uploaded successfully");
        navigate("/RoomCategory");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const save = (e) => {
    const regname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (regname.test(name)) {
      setAlertname("");
    } else if (!regname.test(name) && name === "") {
      setAlertname("Please enter category name");
      e.preventDefault();
    } else {
      setAlertname("");
    }

    const regimage = /(gif|jpe?g|tiff?|png|webp|bmp)$/i;
    if (regimage.test(imageUrl)) {
      setAlertimage("");
    } else if (!regimage.test(imageUrl) && imageUrl === "") {
      setAlertimage("Please enter media link");
      e.preventDefault();
    } else {
      setAlertimage("");
    }
  };

  const cancel = () => {
    setName("");
    setImageUrl("");
    setAlertname("");
    setAlertimage("");
  };

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Upload Room Category
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <form className="form" onSubmit={upload}>
                          <div className="row justify-content-md-center">
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Category Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Category Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    // required
                                  />

                                  <p className="alert-message">{alertname}</p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Image</label>
                                  <input
                                    type="file"
                                    className="form-control-file"
                                    Placeholder="Price Tagline"
                                    name="imageUrl"
                                    onChange={(e) => imageUpload(e)}
                                    // required
                                  />
                                  <p className="alert-message">{alertimage}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {loading ? (
                            <Loader active inline />
                          ) : (
                            <>
                              <div className="form-actions center">
                                <button
                                  type="submit"
                                  className="btn btn-blue"
                                  disabled={!imageUrl}
                                  onClick={save}
                                >
                                  <i className="icon-note"></i> Save
                                </button>{" "}
                                &nbsp;
                                <button
                                  type="reset"
                                  className="btn btn-warning mr-1"
                                  onClick={cancel}
                                >
                                  <i className="icon-trash"></i> Cancel
                                </button>
                              </div>
                            </>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
}
export default AddRoomCategory;
