import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "../component/Navbarside";
import { getblog } from "../store/slices/blog";
import Footer from "./footer";

import { Link, useNavigate } from "react-router-dom";

const Blog = () => {
  const dispatch = useDispatch();
  const [blogs, setBlogs] = useState([]);

  let id = "62fcccde8db90c33d1dfb269";
  localStorage.setItem("id", id);

  React.useEffect(() => {
    dispatch(getblog())
      .unwrap()
      .then((data) => {
        setBlogs(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);
  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title-wrap bar-success">
                          <h4 className="card-title">
                            Blog &nbsp; &nbsp;
                            <Link to="/Addblog" className="btn btn-primary">
                              <i className="fa fa-plus"></i> Add
                            </Link>
                          </h4>
                        </div>
                      </div>
                      <div className="card-body collapse show">
                        <div className="card-block card-dashboard table-responsive">
                          <table className="table table-striped table-bordered zero-configuration">
                            <thead>
                              <tr>
                                <th>Blog Name</th>

                                <th>Description</th>
                                <th>Image</th>
                              </tr>
                            </thead>

                            <tbody>
                              {blogs.map((BlogVal, index) => (
                                <tr>
                                  <td>
                                    {" "}
                                    <Link
                                      to={`/BlogDetails/${
                                        BlogVal._id ? BlogVal._id : null
                                      }`}
                                    >
                                      {BlogVal.blogTitle}
                                    </Link>
                                  </td>
                                  <td>{BlogVal.description}</td>

                                  <td>
                                    <img
                                      src={BlogVal.mediaLink}
                                      alt=""
                                      style={{
                                        width: "150px",
                                        height: "100px",
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
};

export default Blog;
