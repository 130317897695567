import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";
import { API_PATHS } from "../utils/constants/api.constants";

// import { Loader } from "semantic-ui-react";
import Navbarside from "../component/Navbarside";
import { AddHotelImage } from "./../store/slices/Hotels";
import Footer from "./footer";

function AddImage(e) {
  const { id } = useParams();
  const [hotelId] = useState(id);
  const navigate = useNavigate("");
  const dispatch = useDispatch();

  const [details, setDetails] = useState([
    {
      mediaLink: "",
    },
  ]);
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [blogTitle, setBlogTitle] = useState("");
  const [message, setMessage] = useState("");

  //   Alert-message

  const [alertmediaLink, setAlertmediaLink] = useState("");

  async function upload(e) {
    e.preventDefault();
    let entity = "hotel";

    let item = {
      details,
      entity,
      hotelId,
    };

    dispatch(AddHotelImage(item))
      .unwrap()
      .then(() => {
        alert("Uploaded successfully");
        navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  async function imageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    const files = [];
    files.push(...e.target.files);
    files.forEach((file) => formdata.append("file", file));
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.multiUploadImage, requestOptions);
    let data = await response.json();
    let imgurl = [];
    for (let i = 0; i < data.data.length; i++) {
      imgurl.push({
        mediaLink: data.data[i],
      });
    }
    setDetails(imgurl);
    setMessage(data.message);
  }

  const save = (e) => {};

  const cancel = () => {
    setDescription("");
    setDate("");
    setBlogTitle("");
    setAlertmediaLink("");
  };
  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Upload Hotel Image
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <form className="form" onSubmit={upload}>
                          <div className="row justify-content-md-center">
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Upload Image
                                  </label>
                                  <input
                                    type="file"
                                    className="form-control-file"
                                    Placeholder=""
                                    name="imageUrl"
                                    onChange={(e) => imageUpload(e)}
                                  />
                                  <p className="alert-message">
                                    {alertmediaLink}
                                  </p>
                                  <p
                                    style={{
                                      color: "green",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {message}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-actions center">
                            <button
                              type="submit"
                              className="btn btn-blue"
                              onClick={save}
                              disabled={!message}
                            >
                              <i className="icon-note"></i> Save
                            </button>{" "}
                            &nbsp;
                            <button
                              type="reset"
                              className="btn btn-warning mr-1"
                              onClick={cancel}
                            >
                              <i className="icon-trash"></i> Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default AddImage;
