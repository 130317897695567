import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import Navbarside from "../component/Navbarside";
import { Booking } from "../store/slices/booking";
import { Link } from "react-router-dom";
import Moment from "moment";
import Footer from "./footer";

import { ExportToExcel } from "./ExportToExcel";
import { API_PATHS } from "../utils/constants/api.constants";
import axios from "axios";
function TravelBooking() {
  const [expertises, setexpertise] = useState([]);
  const [data, setData] = React.useState([]);
  const fileName = "TravelBooking";
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  React.useEffect(() => {
    const fetchData = () => {
      axios.get(API_PATHS.travelBooking).then((postData) => {
        const customHeadings = postData.data.data.map((item) => ({
          "Booking Number": item.bookingNumber,
          "Customer Name": item.userName,
          "Agent Name": item.agentId._id,
          Package: item.packageId,
          "Tour Start Date": item.startDate,
          "Tour End Date": item.endDate,
          "Gross Price": item.pricingDetails.grossAmount,
          Taxes: item.pricingDetails.taxes,
          Discount: item.pricingDetails.discount,
          "Net Amout": item.pricingDetails.netAmount,
          "Price For Adult": item.pricingDetails.adultsPrice,
          "Price For Children": item.pricingDetails.childrenPrice,
        }));

        setData(customHeadings);
      });
    };
    fetchData();
  }, []);
  React.useEffect(() => {
    dispatch(Booking())
      .unwrap()
      .then((data) => {
        setexpertise(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success">
                        <h4 className="card-title">
                          Booking &nbsp; &nbsp;
                          <ExportToExcel
                            apiData={data}
                            fileName={fileName}
                            style={{
                              height: "25px",
                              padding: "0 10px",
                            }}
                          />
                        </h4>
                      </div>
                    </div>

                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Booking Number</th>
                              <th>Customer Name</th>
                              <th>Price</th>
                              <th>Travel Date</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          {loading ? (
                            <Loader active inline />
                          ) : (
                            <>
                              <tbody>
                                {expertises.map((BookingVal, index) => (
                                  <tr key={index}>
                                    <td>
                                      <Link
                                        to={`/BookingDetails/${
                                          BookingVal._id ? BookingVal._id : null
                                        }`}
                                      >
                                        {BookingVal.bookingNumber}
                                      </Link>
                                    </td>
                                    <td>{BookingVal.userName}</td>
                                    <td>
                                      Rs.{" "}
                                      {BookingVal.pricingDetails.totalCharge}
                                    </td>
                                    <td>
                                      {Moment(BookingVal.startDate).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </td>
                                    <td>{BookingVal.status}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default TravelBooking;
