import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class RoomCategoryService {
    static getroomCategory() {
        let api = '';
       
        let id = JSON.parse(localStorage.getItem("user")).data.id;
        api = API_PATHS.getroomCategory + '?hotelAdminId=' + id;
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }
    
    static postroomCategory(item) {
        return axios
            .post(API_PATHS.postroomCategory,
                item
            )
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default RoomCategoryService;